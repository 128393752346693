import React from 'react';
import styles from './styles.scss';
import PropTypes from 'prop-types';
import { Table, Tbody,} from 'react-super-responsive-table';
import CartRow from '../../components/cart-row';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import get from 'lodash/get';
import { withAlert } from 'react-alert';
import { isMobileOnly, isTablet,  isBrowser} from 'react-device-detect';

class ConsumerCart extends React.Component {

    constructor(props){
        super(props);
        this.props.loadCart();
        this.state = ({
            submitting: false,
            overlay: false,
        });
        this.getCustomerNum = this.getCustomerNum.bind(this);
    }

    handleRowClick(id) {
        this.props.deleteLineItem(id);
    }

    calculateSubTotal(lineItems) {
        let total = 0;
        for (let i=lineItems.length-1; i>=0; i--){
            total += lineItems[i].price * lineItems[i].qty;
        }
        return total;
    }

    calculateTotal(orders) {
        let total = 0;
        for (let i=orders.length-1; i>=0; i--){
            total += this.calculateSubTotal(orders[i].lineItems);
        }
        return total;
    }

    onQtyChange(index, row, value, id) {
        if(value >= 0) {
            this.props.updateQty(index, row, value, id);
        }
    }

    onCommentChange(index, value) {
        this.props.updateComment(index, value);
    }

    handleSubmit(){
        this.setState({ submitting: true, overlay: true, });
        const { orders } = this.props;
        for(let i=orders.length-1; i>=0; i--){
            this.props.addComment(orders[i].id, orders[i].comment);
        }

        this.props.submitCart().then((result) => {
            if(get(result, 'type') === 'cart-submit-cart-succeeded') {
                this.setState({ submitting: false, });
                this.props.alert.show(
                    <div style={{padding: '1rem',}}>Cart submitted successfully!</div>
                    , {
                        timeout: 4000,  // custom timeout just for this one alert
                        type: 'success',
                        onClose: () => { this.props.history.push('/');
                        } 
                    });
            }
        });
    }

    getCustomerNum(distributorId, retailerId) {
        return this.props.getCustomerNum(distributorId, retailerId);
    }


    render() {
        const { orders, cart, retailerType, currentUsertype } = this.props;
        const { submitting, overlay } = this.state;
        const retailerId = get(cart, 'retailer.id');
        return (
            <section className={styles.wrapper}>
                { overlay && <div className={styles.overlay} /> }
                <Table>
                    <Tbody className='tbody'>
                        {this.props.orders.map((item, index) => (
                            <CartRow
                                {...orders[index]}
                                key={index}
                                index={index}
                                onRowClick={(id) => this.handleRowClick(id)}
                                className='trow'
                                distributor={orders[index].distributor.name}
                                distributorId={orders[index].distributor.id}
                                retailerId={retailerId}
                                customerPhone={orders[index].distributor.contactPhone}
                                lineItems={orders[index].lineItems}
                                subTotal={this.calculateSubTotal(orders[index].lineItems)}
                                onQtyChange={(row, value, id) => this.onQtyChange(index, row, value, id)}
                                onCommentChange={(value) => this.onCommentChange(index, value)}
                                getCustomerNum={this.getCustomerNum}
                                currentUsertype={currentUsertype}
                                retailerType={retailerType}
                            />
                        ))
                        }
                        
                    </Tbody>
                </Table>
                <div className="columns">
                    {isBrowser &&
                    <div className="column is-6" />
                    }   
                    <div className="column" style={{padding: '1rem',}}>
                        <div style={{padding: '1rem',}} className="is-pulled-right has-text-right">
                            <h2 
                                className="is-size-3 has-text-weight-bold is-size-3-mobile"
                            >Order Total:&nbsp;&nbsp;&nbsp;${this.calculateTotal(orders).toFixed(2)}</h2>
                            <p style={{padding: !isBrowser ? '1rem 0' : '1rem 0 1rem 9rem',}}>Note: Any applicable deposits, taxes and delivery costs will be invoiced directly by the supplier.</p>
                        </div>
                    </div>
                </div>
                <div className="columns" style={{padding: !isBrowser ? '0 12px 90px 12px' : ''}}>
                    <div className="column is-4">
                        <Link to='/'>
                            <button className="button is-fullwidth is-light">Continue Shopping</button>
                        </Link>
                    </div>
                    <div className="column"></div>
                    <div className="column is-4" style={{padding: !isBrowser ? '0 12px' : ''}}>
                        <button className={submitting ? 'button is-fullwidth is-dark is-loading' : 'button is-fullwidth is-dark'} onClick={() => this.handleSubmit()}>Submit Order Request</button>
                    </div>
                </div>
            </section>
        );
    }
}

export default withAlert(withRouter(ConsumerCart));

ConsumerCart.propTypes = { 
    alert: PropTypes.object,
    addComment: PropTypes.func,
    cart: PropTypes.object,
    deleteLineItem: PropTypes.func,
    getCustomerNum: PropTypes.func,
    history: PropTypes.object,
    loadCart: PropTypes.func,
    isLoading: PropTypes.bool,
    orders: PropTypes.array,
    updateComment: PropTypes.func,
    updateQty: PropTypes.func,
    submitCart: PropTypes.func,
};
