import {types as EventTypes} from '../events';
import {types as AuthEventTypes} from '../../authentication/events';
import basicReducer from '../../common/reducers/basic-reducer';
import constant from 'lodash/constant';

const initialState = false;

const reductionLookup = {
    [AuthEventTypes.LoggedOut]: constant(initialState),       
    [EventTypes.SignupSucceeded]: constant(initialState),
};

export default basicReducer(initialState, reductionLookup);
