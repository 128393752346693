import React from 'react';
import styles from './styles.scss';
import { Table, Thead, Tbody, Tr, Th } from 'react-super-responsive-table';
import OrderRow from '../../components/order-row';
import DropDown from '../../components/dropdown';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { withRouter } from 'react-router';
import { withAlert } from 'react-alert';
import classnames from 'classnames';
import { isMobileOnly, isTablet,  isBrowser} from 'react-device-detect';

class DistributorOrderDetails extends React.Component {

    constructor(props){
        super(props);
        this.state = { orders: [], newRepId: '', showModal: false, };
        this.toggleModal = this.toggleModal.bind(this);
        this.updateRepInfo = this.updateRepInfo.bind(this);
        this.refreshPage = this.refreshPage.bind(this);
    }

    componentDidMount() {
        const { orderId } = this.props.location.state;
        this.props.loadComments(orderId);
        this.props.getReps();
        this.refreshPage();
    }

    refreshPage(){
        const { orderId } = this.props.location.state;
        return this.props.loadOrderDetails(orderId).then((result)=> {
            if (get(result, 'type') === 'cart-load-order-details-succeeded'){
                const orders = get(result, 'payload.orders', []);
                const orderDetails = get(result, 'payload', {});
                const retailerId = get(orderDetails, 'retailer.id');
                const distributorId = get(result, 'payload.orders[0].distributor.id', '');
                this.props.getCustomerNum(distributorId, retailerId).then((result) => {
                    const customerNum = get(result, 'payload.number', 'N/A');
                    const repId = get(result, 'payload.repId', 'N/A');
                    const repName = get(result, 'payload.repName', 'N/A');
                    this.setState({
                        distributorId: distributorId,
                        retailerId: retailerId,
                        orders: orders,
                        orderDetails: orderDetails,
                        orderId: orderId,
                        customerNum: customerNum,
                        newCustomerNum: customerNum,
                        repId: repId,
                        repName: repName,
                    });
                });
                
            }
        });
        
    }

    verifyOrder(orderId) {
        this.props.addComment(orderId, this.state.commentString).then(() => {
            this.props.verifyOrder(orderId).then((result)=> {
                if (get(result, 'type') === 'cart-verify-order-succeeded'){
                    this.props.alert.show(
                        <div style={{padding: '1rem',}}>Order has been succesfully acknowledged.</div>,
                        {
                            timeout: 4000,  
                            type: 'success',
                            onClose: () => { this.props.history.push('/');
                            } 
                        });
                }
            });
        });
    }

    setComment(e){
        this.setState({
            commentString: e.target.value,
        });
    }

    buildSum(lineItems){
        let total = 0;
        for(let i=lineItems.length-1; i>=0; i--) {
            total += lineItems[i].price * lineItems[i].qty;
        }
        return total.toFixed(2);
    }

    setAccountNum(value){
        this.setState({
            newCustomerNum: value,
        });

    }

    storeRepId(value){
        this.setState({
            newRepId: value,
        });
    }

    updateRepInfo(){
        const { distributorId, retailerId, newRepId, newCustomerNum } = this.state;
        this.props.updateRepInfo(distributorId, retailerId, newRepId, newCustomerNum).then((result) => {
            if(get(result, 'type') === 'profile-update-rep-info-succeeded'){
                this.refreshPage().then(()=>{
                    this.toggleModal();
                    this.props.alert.show(
                        <div style={{padding: '1rem',}}>Info has been updated.</div>,
                        {
                            timeout: 4000,  
                            type: 'success',
                            onClose: () => {} 
                        });
                    
                });
            } else {
                this.toggleModal();
                this.props.alert.show(
                    <div style={{padding: '1rem',}}>An error occurred, info not updated.</div>,
                    {
                        timeout: 4000,  
                        type: 'success',
                        onClose: () => {} 
                    });
            }
        });
    }

    toggleModal(){
        const { showModal } = this.state;
        this.setState({
            showModal: !showModal,
        });
    }

    render() {
        const { orderDetails, orders, orderId, commentString, customerNum, newCustomerNum, repName, newRepId, showModal } = this.state;
        const { comments } = this.props;
        const submitDate = get(orderDetails, 'submitDate');
        const orderNum = orderId;
        const name = get(orderDetails, 'retailer.name');
        const address1 = get(orderDetails, 'retailer.address1');
        const address2 = get(orderDetails, 'retailer.address2');
        const city = get(orderDetails, 'retailer.city');
        const state = get(orderDetails, 'retailer.state');
        const postalCode = get(orderDetails, 'retailer.postalCode');
        const contactName = get(orderDetails, 'retailer.contactName');
        const contactPhone = get(orderDetails, 'retailer.contactPhone');
        const contactEmail = get(orderDetails, 'retailer.contactEmail');
        const lineItems = get(orders[0], 'lineItems', []);
        const status = get(orders[0], 'status', '');
        const repOptions = get(this.props, 'reps', []).map(rep => [get(rep, 'id'), get(rep, 'contactName')]);
        return (
            <div className={styles.wrapper}>
                <div className="columns" style={{position: 'relative'}}>
                    <div className="column is-7">
                        <h1 className="title">{name}</h1>
                        <p className="subtitle">{address1}</p>
                        { !isEmpty(address2)  && <p className="subtitle">{address2}</p> }
                        <p className="subtitle" style={{marginTop: '-1.5rem',}}>{city}, {state} {postalCode}</p>
                        <p className="subtitle" style={{marginTop: '-1.5rem',}}>Attn: {contactName}</p>
                        <p className="subtitle"><a href={'tel:'+contactPhone}>{contactPhone}</a></p>
                        <p className="is-size-5" style={{marginTop: '-1.5rem',}}><a href={'mailto:'+contactEmail}>{contactEmail}</a></p>
                    </div>
                    { isTablet &&
                        <div className="box" style={{backgroundColor: '#f1e2c3', width: '50%', position: 'absolute', top:10, right: 10, padding: '2rem', }}>
                            <div className="level is-mobile">
                                <h1 className="subtitle is-marginless level-item-left">Date</h1>
                                <h1 className="is-size-5 level-item-left is-marginless">{new Date(submitDate).toLocaleDateString()}</h1>
                            </div>
                            <div className="level is-mobile">
                                <h1 className="subtitle is-marginless level-item-left">Order #</h1>
                                <h1 className="is-size-5 level-item-left is-marginless">{orderNum}</h1>
                            </div>
                            <div className="level box has-background-white" style={{padding: '0.75rem'}} onClick={this.toggleModal}>
                                <span className="subtitle is-marginless level-item-left" style={{ display: 'flex', }}>
                                    <h3 style={{fontSize: '15px'}}>Customer#</h3>
                                    <span className="is-size-6 is-marginless" 
                                        style={{padding: '.2rem 1rem', marginLeft: '5px',}}>{customerNum}</span>
                                </span>
                                <span className="subtitle is-marginless level-item-right" style={{ display: 'flex', }}>
                                    <h3 style={{fontSize: '15px'}}>Rep:</h3>
                                    <span className="is-size-6 is-marginless" 
                                        style={{padding: '.2rem 1rem', marginLeft: '5px',}}>{repName}</span>
                                </span>
                            </div>
                        </div>

                    }
                    { !isTablet &&
                        <div className="column has-text-centered">
                            <div className="box" style={{backgroundColor: '#f1e2c3'}}>
                                <div className="level is-mobile">
                                    <h1 className="subtitle is-marginless level-item-left">Date</h1>
                                    <h1 className="is-size-5 level-item-left is-marginless">{new Date(submitDate).toLocaleDateString()}</h1>
                                </div>
                                <div className="level is-mobile">
                                    <h1 className="subtitle is-marginless level-item-left">Order #</h1>
                                    <h1 className="is-size-5 level-item-left is-marginless">{orderNum}</h1>
                                </div>
                                <div className="level box has-background-white" style={{padding: '0.75rem'}} onClick={this.toggleModal}>
                                    <span className="subtitle is-marginless level-item-left" style={{ display: 'flex', }}>
                                        <h3 style={{fontSize: '15px'}}>Customer#</h3>
                                        <span className="is-size-6 is-marginless" 
                                            style={{padding: '.2rem 1rem', marginLeft: '5px',}}>{customerNum}</span>
                                    </span>
                                    <span className="subtitle is-marginless level-item-right" style={{ display: 'flex', }}>
                                        <h3 style={{fontSize: '15px'}}>Rep:</h3>
                                        <span className="is-size-6 is-marginless" 
                                            style={{padding: '.2rem 1rem', marginLeft: '5px',}}>{repName}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <Table className='table' style={{border: '0.5px solid', borderRadius: 10, borderColor: '#ccc', marginTop: '4rem',}}>
                    <Thead className='thead'>
                        <Tr style={{ borderWidth: '1px', borderColor: '#333', backgroundColor: '#f1e2c3',}}>
                            <Th/>
                            <Th colSpan={2}>SKU</Th>
                            <Th colSpan={32}>Description</Th>
                            <Th /><Th />
                            <Th colSpan={2}>Packaging</Th>
                            <Th colSpan={2}>Qty</Th>
                            <Th colSpan={2}>Price</Th>
                            <Th colSpan={2}>Sub-Total</Th>
                        </Tr>
                    </Thead>
                    <Tbody className='tbody'>
                        { lineItems.map((item, index) => (
                            <OrderRow
                                key={index}
                                onRowClick={this.handleRowClick}
                                className='trow'
                                sku={item.product.sku}
                                name={item.product.name}
                                brand={item.product.brand.name}
                                category={item.product.category.name}
                                type={item.product.type.name}
                                subType=''
                                packageType={item.productVariant.qty + '/' + item.productVariant.packageTypeName + ' ' + item.productVariant.masterType}
                                price={item.price}
                                qty={item.qty} />
                        ))}
                       
                    </Tbody>
                </Table>
                <div className="columns">
                    <div className="column is-7" style={{padding: isBrowser ? '3rem 5rem 1rem 1rem' : '1rem',}}>
                        <p className="subtitle">Comments</p>
                        { comments.length>0 &&
                            comments.map((item, index) => (
                                <div className="box" key={index} style={{position: 'relative',}}>
                                    <p className={ (item.retailerId) ? 'has-text-left' : 'has-text-right'}>{(item.retailerId) ?  `${name}: ${item.commentText}` : 'you: ' + item.commentText}</p>
                                    <a className="delete is-medium" 
                                        style={(item.retailerId) ? 
                                            {position: 'absolute', right:'1rem', top: '1rem',} 
                                            : {position: 'absolute', left:'1rem', top: '1rem',}}
                                        onClick={() => {}}></a>
                                </div>
                            ))}
                        <textarea 
                            className="textarea" 
                            value={ commentString }
                            placeholder="Enter any comments to be sent to the retailer here." 
                            onChange={(e) => { this.setComment(e); }}
                            rows="2" />
                    </div>
                    { isBrowser && <div className="column" /> }
                    <div className="column is-4" style={{position: 'relative',}}>
                        <div style={{padding: '1rem',}}>
                            <div className="level">
                                <h1 className="subtitle is-marginless level-item-left">Subtotal</h1>
                                <h1 className="is-size-5 level-item-left is-marginless">${this.buildSum(lineItems)}</h1>
                            </div>
                            <div className="level">
                                <h1 className="subtitle is-marginless level-item-left">Total</h1>
                                <h1 className="is-size-5 level-item-left">${this.buildSum(lineItems)}</h1>
                            </div>
                            { isBrowser && <p style={{padding: '1rem',}}>Note: Any applicable deposits, taxes and delivery costs will be invoiced directly by the supplier.</p> }
                            {!isBrowser && <p style={{padding: '1rem', width: '50%', position: 'absolute', top: 0, right: 0,}}>Note: Any applicable deposits, taxes and delivery costs will be invoiced directly by the supplier.</p> }

                        </div>
                    </div>
                </div>
                <div className="level" style={{marginTop: isBrowser ? '3rem' : '',}}>
                    <div className="level-item-left">
                        {/*<p className="subtitle">Assign to a sales representitive</p>
                        <Dropdown options={repOptions} 
                            onChange={()=>({})}/>*/}
                    </div>
                    <div className={!isBrowser ? 'level-item' : 'level-item-right'}>
                        <button 
                            disabled={status==='verified' ? true : false}
                            className={status==='verified' ? 'button has-background-success has-text-white' : 'button'}
                            onClick={()=> this.verifyOrder(orderNum)}
                            style={{marginTop: '2rem', padding: '0 4rem',}}>{status==='verified' ? 'Acknowledged' :'Acknowledge Order'}</button>
                    </div>
                </div>
                { showModal && 
                    <div className={styles.wrap}>
                        <div className={styles.modal}>
                            <a 
                                className={classnames('delete is-medium', styles.delete)}
                                onClick={this.toggleModal} />
                            <div style={{padding: '0 1rem 1rem',}}>
                                <h1 className='title is-4' style={{paddingBottom: '1rem',}}>Reassign</h1>
                                <p className='subtitle is-6'>You may reassign this order to another rep, or edit the customer number.</p>
                                <input 
                                    value={newCustomerNum}
                                    className="input" 
                                    type="text" 
                                    placeholder="Enter customer number" 
                                    style={{marginBottom: '1.5rem', }}
                                    onChange={(e) => this.setAccountNum(e.target.value)}/>
                                { repOptions.length>0 &&
                                    <DropDown 
                                        value={newRepId}
                                        placeholder='Select Your Sales Rep'
                                        options={repOptions} 
                                        onChange={(value) => this.storeRepId(value)}/> 
                                }
                            </div>
                            <button 
                                onClick={this.updateRepInfo}
                                className='button is-fullwidth' 
                                style={{marginTop: '1.5rem', }}>Submit</button>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default withAlert(withRouter(DistributorOrderDetails));

DistributorOrderDetails.propTypes = { 
    assignRep: PropTypes.func,
    addComment: PropTypes.func,
    alert: PropTypes.object,
    comments: PropTypes.array,
    getCustomerNum: PropTypes.func,
    getReps: PropTypes.func,
    history: PropTypes.object,
    loadComments: PropTypes.func,
    loadOrderDetails: PropTypes.func,
    location: PropTypes.object,
    orderDetails: PropTypes.any,
    updateRepInfo: PropTypes.func,
    verifyOrder: PropTypes.func,

};
 