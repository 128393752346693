import React, {Component} from 'react';
import { withRouter } from 'react-router';
import styles from './styles.scss';
import TextField from '../../components/text-field';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import classnames from 'classnames';
import { withAlert } from 'react-alert';

class ResetPassword extends Component {
    constructor(){
        super();
        this.state = {
            email: '',
        };
    }

    setValue(v){
        this.setState({
            email: v,
        });
    }

    fireEmail(){
        const { email } = this.state;
        this.props.fireEmail(email).then((result) => {
            const response = get(result, 'type');
            const errorMessage = get(result, 'payload');
            response ==='profile-fire-email-failed' ? 
                this.props.alert.show(
                    <div style={{padding: '3rem',}}>{errorMessage}</div>,
                    {
                        timeout: 4000, 
                        type: 'success',
                        onClose: () => {} 
                    })
                : this.props.history.push({pathname: '/confirm-password', state: { email: email }});
        });
    }

    invalidEmail(){
        this.props.alert.show(
            <div style={{padding: '3rem',}}>Please make sure to enter a valid email address.</div>,
            {
                timeout: 4000, 
                type: 'success',
                onClose: () => {} 
            });
    }

    handleSubmit() {
        const { email } = this.state;
        const regex = new RegExp(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        regex.test(email) ? this.fireEmail() : this.invalidEmail();
    }

    render(){
        const { email } = this.state;
        return (
            <div className={styles.wrapper}>
                <div className='columns'>
                    <div className={classnames('column', 'is-half', styles.wrapper)}>
                        <h3 className='title'>Forgot Your Password?</h3>
                        <p className='is-size-5'>Enter the email address you use to login and we will send a reset code.</p>
                    </div>
                    <div className={classnames('column', 'is-half', styles.wrapper)}>
                        <TextField 
                            labelText='Enter your email'
                            onChange={(v) => this.setValue(v)}
                            value={email}/>
                        <button className="button is-fullwidth"
                            onClick={this.handleSubmit.bind(this)}>Continue</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default withAlert(withRouter(ResetPassword));

ResetPassword.propTypes = {
    alert: PropTypes.object,
    fireEmail: PropTypes.func,
    history: PropTypes.object,
    userExists: PropTypes.func,
};

