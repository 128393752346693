import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styles from './styles.scss';

export default class TextInput extends React.Component {

    constructor(props){
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.props.childChange(event);
    }


    render(){
        return(
            <div style={{marginBottom: '1rem'}}>
                <div className="is-size-6">{this.props.labelText}</div>
                { this.props.className==='has-error' && <div className="is-size-7 has-text-danger">{this.props.errorMessage}</div> }
                <input name={this.props.fieldName} type={this.props.type} style={{padding:'.5rem .5rem .5rem 1rem', marginTop: '.5rem'}} 
                    value={this.props.value} className={classnames('input', 'is-medium', 
                        (this.props.className==='has-error') ? styles.error : '')} onChange={this.handleChange} />
            </div>
        );
    }

}

TextInput.propTypes = {
    onChange: PropTypes.func,
    childChange: PropTypes.func,
    error: PropTypes.string,
    labelText: PropTypes.string,
    type: PropTypes.string,
    fieldName: PropTypes.string,
    value: PropTypes.string,
    className: PropTypes.string,
    errorMessage: PropTypes.string,
    hasError: PropTypes.bool,
};