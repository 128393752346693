import React from 'react';
import styles from './styles.scss';
import PropTypes from 'prop-types';
import DropDown from '../../components/dropdown';
import get from 'lodash/get';
import find from 'lodash/find';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-responsive-modal';
import TextField from '../../components/text-field';
import { withRouter } from 'react-router';
import { withAlert } from 'react-alert';
import Autocomplete from 'react-autocomplete';
import { isMobileOnly, isTablet,  isBrowser} from 'react-device-detect';


class ProductAdd extends React.Component {

    constructor(props){
        super(props);
        this.state = this.props.location.state;
        this.setOnPremisePrice = this.setOnPremisePrice.bind(this);
        this.setOffPremisePrice = this.setOffPremisePrice.bind(this);
        this.setPackagingQty = this.setPackagingQty.bind(this);
        this.loadPackagingTypes = this.loadPackagingTypes.bind(this);
        this.fileSelectedHandler = this.fileSelectedHandler.bind(this);
        this.addItem = this.addItem.bind(this);
        this.onOpenModal = this.onOpenModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
        this.setLinkTitle = this.setLinkTitle.bind(this);
        this.setLinkUrl = this.setLinkUrl.bind(this);
        this.addLink = this.addLink.bind(this);
        this.addUpDateProduct = this.addUpDateProduct.bind(this);
        this.state = {
            packageTypeId: '',
            packageSubtypeId: '',
            selectedFile: null,
            variants: [],
            links: [],
            packagingQty: 1,
            product: {
                brand: '',
                name: '',
                categoryId: '',
                typeId: '', 
                subTypeId: '',
                regionId: '',
                subRegionId: '',
                sku: '',
                abv: '',
                description: '',
                inStock: 1,
            },
        };
    }

    
    componentDidUpdate(prevProps) {
        if(prevProps.productLinks!==this.props.productLinks){
            this.onCloseModal();
        }
    }

    componentDidMount() {
        this.props.loadRegions();
        this.props.loadCatalogCategories();
        this.props.loadCatalogBrands();
        this.suggestProduct('');
        const { product } = this.state;
        this.props.loadPackagingTypes(0).then((result)=> {
            const arr = get(result, 'payload', []);
            this.setState({
                currentPackagingTypes: result,
                //packageSubtypeId: arr[0].id,
            });
        });
        
    }

    onOpenModal() {
        this.setState({ open: true });
    }
  
    onCloseModal() {
        this.setState({ 
            open: false, 
            newLinkTitle: '',
            newLinkUrl: '', 
        });
    }

    addItem(){
        const { currentPackagingTypes, packageSubtypeId, variants } = this.state;
        const arr = get(currentPackagingTypes, 'payload');
        const item = find(arr, {'id': parseInt(packageSubtypeId)});

        const storeItemObj = {
            packageSize: item.title || '',
            masterPackageTypeId: parseInt(this.state.packageTypeId) || 0,
            packageTypeId: parseInt(this.state.packageSubtypeId) || 1,
            packageTypeOther: '', 
            offPremisePricing: parseFloat(this.state.offPremisePrice) || 0,
            onPremisePricing: parseFloat(this.state.onPremisePrice) || 0,
            packageQty: parseInt(this.state.packagingQty) || 1,
        };
        variants.push(storeItemObj);
        this.setState({ variants });
    }

    deleteVariant(index){
        const { variants } = this.state;
        variants.splice(index, 1);
        this.setState({
            variants: variants,
        });
    }

    setPackagingId(v){
        this.setState({
            packageTypeId: v,
            packageSubtypeId: '',
        });
        this.loadPackagingTypes(v);
    }

    setSubpackagingId(v){
        this.setState({
            packageSubtypeId: v,
        });
    }

    loadPackagingTypes(e){
        this.props.loadPackagingTypes(e).then((result) => {
            //const arr = get(result, 'payload');
            this.setState({
                currentPackagingTypes: result,
                //packageSubtypeId: arr[0].id,
            });
        });
    }

    loadProductTypes(value){
        const { product } = this.state;
        product.categoryId = parseInt(value);
        product.typeId = '';
        product.subTypeId = '';
        this.props.loadCatalogCategoryProductTypes(value);
        this.setState({ 
            product,
        });
    }

    loadSubTypes(value){
        const { product } = this.state;
        product.typeId = parseInt(value);
        product.subTypeId = '';
        this.props.loadSubTypes(this.state.categoryId, value).then((result) => {
            const arr = get(result, 'payload.subTypes');
            if(arr.length === 0) { product.subTypeId = -1; }
        });
        this.setState({ 
            product,
        });
    }

    loadRegions(value){
        const { product } = this.state;
        product.subTypeId = parseInt(value);
        this.setState({ 
            product,
        });
        this.props.loadRegions();
    }

    loadSubRegions(value){
        const { product } = this.state;
        product.regionId = parseInt(value);
        this.setState({ 
            product,
        });
        this.props.loadSubRegions(value).then((result) => {
            const arr = get(result, 'payload');
            (arr.length === 0) ? product.subRegionId = -1 : product.subRegionId = arr[0].id;
        });
    }

    setSubRegion(value){
        const { product } = this.state;
        product.subRegionId = parseInt(value);
        this.setState({ 
            product,
        });
    }

    setAbv(e){
        const { product } = this.state;
        product.abv = e.target.value;
        this.setState({ 
            product,
        });
    }

    setDescription(e){
        const { product } = this.state;
        product.description = e.target.value;
        this.setState({ 
            product,
        });
    }

    setBrand(e){
        const { product } = this.state;
        product.brand = e.target.value;
        this.setState({ 
            product,
        });
    }

    setProductName(v){
        const { product } = this.state;
        product.name = v;
        this.setState({ 
            product,
        });
        this.suggestProduct(v);
    }

    setSKU(e){
        const { product } = this.state;
        product.sku = e.target.value;
        this.setState({ 
            product,
        });
    }

    setOnPremisePrice(e){
        this.setState({
            onPremisePrice: e.target.value,
        });
    }

    setOffPremisePrice(e){
        this.setState({
            offPremisePrice: e.target.value,
        });
    }

    setPackagingQty(e){
        if(e.target.value > 0) {
            this.setState({
                packagingQty: e.target.value,
            });
        }
    }

    deleteLInk(index){
        const { links } = this.state;
        links.splice(index, 1);
        this.setState({
            links: links,
        });
    }

    fileSelectedHandler(event) {
        this.setState({
            selectedFile: event.target.files[0],
            localImageFile: URL.createObjectURL(event.target.files[0]),
        });
    }

    addLink(){
        const { links, newLinkTitle, newLinkUrl} = this.state;
        links.push({title: newLinkTitle, url: newLinkUrl,});
        this.setState({
            links: links,
        });
        this.onCloseModal();
    }

    setLinkTitle(e){
        this.setState({
            newLinkTitle: e,
        });
    }

    setLinkUrl(e){
        this.setState({
            newLinkUrl: e,
        });
    }

    setStock(value){
        const { product } = this.state;
        product.inStock = parseInt(value);
        this.setState({ 
            product,
        });
    }

    addUpDateProduct(){
        
        const  { product, variants, links, selectedFile } = this.state;
        if(variants.length>0) { 
            this.props.addUpdateProduct(product).then((result) => {
                if(get(result, 'type') === 'catalog-add-update-product-succeeded') {
                    const oldProductId = get(product, 'id');
                    const productId = get(result, 'payload');
                    for(let i=variants.length-1; i>=0; i--){
                        this.props.addProductVariant(productId, variants[i]);
                    }
                    for(let j=links.length-1; j>=0; j--){
                        this.props.addProductLink(productId, links[j]);
                    }
                    if (selectedFile === null) {
                        this.props.cloneImage(oldProductId, productId).then(() => {
                            this.props.searchCatalogProducts(-1,'').then(() => {
                                this.props.history.push('/distributor-catalog');
                            });
                        });
                        
                    } else {
                        const fd = new FormData();
                        fd.append('image', this.state.selectedFile, this.state.selectedFile.name);
                        this.props.uploadImage(productId, fd).then(() => {
                            this.props.searchCatalogProducts(-1,'').then(() => {
                                this.props.history.push('/distributor-catalog');
                            });
                        });
                    }
                }
            });
        } else {
            this.props.alert.show(
                <div style={{padding: '1rem',}}>Please ensure all fields are filled out 
                    including at least one product variant.</div>
            );
        }
    }

    getMasterType(index){
        switch (index) {
            case 2:
                return 'Keg';
            case 1:
                return 'Can';
            default:
                return 'Bottle';
        }
    }

    suggestProduct(v){
        const { getProductSuggestions, productSuggestions } = this.props;
        getProductSuggestions(v);
        this.setState({
            suggestions: productSuggestions,
        });
    }

    setProduct(product){
        this.loadProductTypes(product.category.id);
        this.loadSubTypes(product.type.id);
        this.loadRegions(product.subType.id);
        this.loadSubRegions(product.region.id);
        this.setState({
            product: {
                id: product.id,
                brand: product.brand.name,
                name: product.name,
                categoryId: product.category.id,
                typeId: product.type.id,
                subTypeId: product.subType.id,
                regionId: product.region.id,
                subRegionId: product.subRegion.id,
                sku: product.sku,
                abv: product.abv,
                description: product.description,
                inStock: product.inStock,
            },
        });
    }

    render() {
        const availabilityOptions = [
            [0, 'Out of Stock'],
            [1, 'In Stock'],
        ];
        const masterPackagingTypes = [
            [0, 'Bottle'],
            [1, 'Can'],
            [2, 'Keg'],
        ];
        const categoryOptions = get(this.props, 'categories', []).map(category => [get(category, 'id'), get(category, 'title')]);
        const productTypes = get(this.props, 'productTypes', []).map(productType => [get(productType, 'id'), get(productType, 'title')]);
        const subTypes = get(this.props, 'subTypes', []).map(subType => [get(subType, 'id'), get(subType, 'title')]);
        const regionOptions = get(this.props, 'regions', []).map(region => [get(region, 'id'), get(region, 'title')]);
        const subRegionOptions = get(this.props, 'subRegions', []).map(subRegion => [get(subRegion, 'id'), get(subRegion, 'title')]);
        const packagingTypes = get(this.props, 'packagingTypes', []).map(packagingType => [get(packagingType, 'id'), get(packagingType, 'title')]);
        const { open, product, variants, links, packageTypeId, packageSubtypeId, suggestions, localImageFile } = this.state;
        const baseUrl = 'https://api.buyfluidly.com/v1/catalog/products/'; 
        return (
            <div>
                <div className={styles.wrapper}>
                    <input 
                        style={{ display: 'none', }} 
                        type="file" 
                        onChange={this.fileSelectedHandler}
                        ref={fileInput => this.fileInput = fileInput}/>
                    <div className="columns">
                        <div className="column is-one-third">
                            <div className="image is-square" style={{border: '1px solid', position: 'relative'}}>
                                {  localImageFile &&
                                    <img src={ localImageFile } 
                                        style={{width: 'auto', height:'auto', display: 'block', maxHeight: '90%', margin: 'auto',}}/>
                                }
                                {   !localImageFile &&
                                    <img src={ baseUrl + product.id + '/image'} 
                                        style={{width: 'auto', height:'auto', display: 'block', maxHeight: '90%', margin: 'auto',}}/>
                                }
                                <img src={require('../../images/icon-add-edit-photo.png')} 
                                    style={{width: '4rem', height:'4rem', position: 'absolute', zIndex: 99, marginTop: 'auto', left: '2rem', marginBottom: '2rem', }}
                                    onClick={() => this.fileInput.click()} />
                            </div>
                            <div style={{padding: !isBrowser ? '1rem 0 0 0' : '1rem 0 0 1rem'}}>
                                <p className="is-size-3 has-text-weight-semibold" style={{padding: '1rem 1rem 1rem 0'}}>Details</p>
                                <p className="is-size-4 has-text-weight-semibold">Category:</p>
                                <DropDown value={product.categoryId} 
                                    onChange={(value) => this.loadProductTypes(value)} 
                                    options={categoryOptions} 
                                    placeholder='Select A Category' />

                                <p className="is-size-4 has-text-weight-semibold">Subcategory:</p>
                                <DropDown value={product.typeId} 
                                    onChange={(value) => this.loadSubTypes(value)} 
                                    options={productTypes} 
                                    placeholder='Select A Subcategory' />

                                { subTypes.length > 1 && 
                                <div style={{marginBottom: '1rem', }}>
                                    <p className="is-size-4 has-text-weight-semibold">Type:</p>
                                    <DropDown value={product.subTypeId} 
                                        onChange={(value) => this.loadRegions(value)} 
                                        options={subTypes} 
                                        placeholder='Select a Type' />
                                </div>
                                }
                                { regionOptions.length > 1 && 
                                <div style={{marginBottom: '1rem', }}>
                                    <p className="is-size-4 has-text-weight-semibold">Region:</p>
                                    <DropDown value={product.regionId} 
                                        onChange={(value) => this.loadSubRegions(value)} 
                                        options={regionOptions} 
                                        placeholder='Select A Region' />
                                </div>
                                }
                                { subRegionOptions.length > 1 && 
                                <div style={{marginBottom: '1rem', }}>
                                    <p className="is-size-4 has-text-weight-semibold">SubRegion:</p>
                                    <DropDown value={product.subRegionId} 
                                        onChange={(value) => this.setSubRegion(value)} 
                                        options={subRegionOptions} 
                                        placeholder='Select A Region' />
                                </div>
                                }
                                <p className="is-size-4 has-text-weight-semibold">ABV:</p>
                                <input value={product.abv} 
                                    onChange={(e) => this.setAbv(e)} 
                                    className="input" 
                                    style={{padding: '1.5rem', marginBottom: '1rem'}}/>

                                <p className="is-size-4 has-text-weight-semibold">Availability:</p>
                                <DropDown 
                                    value={product.inStock} 
                                    onChange={(value) => this.setStock(value)} 
                                    options={availabilityOptions} 
                                    placeholder='Select Availability' />
                            </div>
                        </div>
                        <div className="column">
                            <p className="is-size-5">Producer / Brand</p>
                            <input style={{marginBottom: '2rem'}} 
                                onChange={(e) => this.setBrand(e)}
                                className="input is-size-4" 
                                type="text" 
                                placeholder="Enter Producer or Brand Name" 
                                value={product.brand} />
                            <p className="is-size-5">Product Name</p>
                            <Autocomplete
                                inputProps={{ placeholder: 'Enter Product Name', className: 'input is-size-3 has-text-weight-semibold' }}
                                wrapperStyle={{ position: 'relative', }}
                                getItemValue={(item) => item.name}
                                items={suggestions}
                                menuStyle={{zIndex: 99999, position: 'absolute', maxHeight: '10rem', overflowY: 'auto', top: '5rem', left: 0, border: '2px solid #ddd', borderTopWidth: 0,}}
                                renderItem={(item, isHighlighted) =>
                                    <div className='level is-marginless' key={item.id} style={{ background: isHighlighted ? '#eee' : 'white', padding: '1rem', borderBottom: '1px solid #eee', }}>
                                        <span className='level-item-left'>{item.name}</span><span className='level-item-right'>{new Date(item.createDate).toLocaleDateString()}</span>
                                    </div>
                                }
                                value={product.name}
                                onChange={(e) => this.setProductName(e.target.value)}
                                onSelect={(value) => {
                                    const product = find(suggestions, { 'name' : value });
                                    this.setProduct(product);
                                }}
                            />
                            <p className="is-size-5" style={{marginTop: '2rem'}}>SKU</p>
                            <input  style={{marginBottom: '2rem'}}
                                onChange={(e) => this.setSKU(e)}
                                className="input is-size-5" 
                                placeholder="Enter SKU" 
                                value={product.sku} />
                            <br />

                            {variants.map((item, index) => (
                                <div key={index} className="box" style={{ padding: !isBrowser ? '' : '1rem', backgroundColor: '#f1e2c3'}}>
                                    <div className="columns" style={{position: !isBrowser ? 'relative' : '',}}>
                                        <div className="column is-6">
                                            <div className="level">
                                                <div className="level-item">
                                                    <div className={!isMobileOnly ? 'image is-64x64' : 'image is-48x48'}>
                                                        {item.masterPackageTypeId !== null &&
                                                        <img src={require(`../../images/${item.masterPackageTypeId}-icon.png`)} />
                                                        }
                                                    </div>
                                                </div>
                                                <div className="level-item">
                                                    <div className="is-size-4 is-pulled-left">{item.packageQty}/{item.packageSize} {this.getMasterType(item.masterPackageTypeId)}</div>
                                                </div>
                                                <div className="level-item" />
                                            </div>
                                        </div>
                                        <div className="column is-5">
                                            <div className={!isBrowser ? 'has-text-centered' : ''}>
                                                <div>On Premise: ${item.onPremisePricing}</div>
                                                <div>Off Premise: ${item.offPremisePricing}</div>
                                            </div>
                                        </div>
                                        <div className="column is0" style={{position: !isBrowser ? 'absolute' : '', top: 0, right: 0}}>
                                            <a 
                                                className="delete is-large"
                                                onClick={() => this.deleteVariant(index)}></a> 
                        
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div className="box" style={{ padding: '2.5rem', backgroundColor: '#f1e2c3'}}>
                                <div className="columns">
                                    <div className="column is-6">
                                        <DropDown 
                                            value={packageTypeId}
                                            onChange={(v) => this.setPackagingId(v)}
                                            options={masterPackagingTypes}
                                            placeholder='Select Package Type'
                                        />
                                        <DropDown 
                                            value={packageSubtypeId}
                                            onChange={(v) => this.setSubpackagingId(v)}
                                            options={packagingTypes}
                                            placeholder='Select Package Size'
                                        />
                                    </div>
                                    <div className="column is-5">
                                        <input style={{marginBottom: '1rem', padding: '1.6rem'}} 
                                            className="input is-size-5" 
                                            type="number" 
                                            onChange={this.setOnPremisePrice}
                                            placeholder="On Premise Price" 
                                            value={this.state.onPremisePrice} 
                                            onBlur={e => e.target.value === '' ? e.target.value = this.state.onPremisePrice : null }
                                            onFocus={e => e.target.value == this.state.onPremisePrice ? e.target.value = '' : null}/>
                                        <input style={{marginBottom: '1rem', padding: '1.6rem'}} 
                                            className="input is-size-5" 
                                            type="number" 
                                            onChange={this.setOffPremisePrice}
                                            placeholder="Off Premise Price" 
                                            value={this.state.offPremisePrice} 
                                            onBlur={e => e.target.value === '' ? e.target.value = this.state.offPremisePrice : null }
                                            onFocus={e => e.target.value == this.state.offPremisePrice ? e.target.value = '' : null}/>
                                    </div>
                                </div>
                                <div className="columns">
                                    <div className={!isBrowser ? 'column is-size-6 is-6' : 'column has-text-right is-size-6 is-6'} style={{marginTop: '.9rem'}}>Packaging Qty</div>
                                    <div className="column has-text-left is-3" style={{position: !isBrowser ? 'relative' : '',}}>
                                        <input style={{marginBottom: '1rem', padding: '1.6rem', width: !isBrowser ? '7rem' : ''}} 
                                            className="input is-size-5" 
                                            onChange={this.setPackagingQty}
                                            onBlur={e => e.target.value === '' ? e.target.value = this.state.packagingQty : null }
                                            onFocus={e => e.target.value == this.state.packagingQty ? e.target.value = '' : null}
                                            type="number" 
                                            placeholder="Qty" 
                                            value={this.state.packagingQty} />
                                        {!isBrowser &&
                                            <div className='column' style={{position: 'absolute', top: 0, right: 0}}>
                                                <FontAwesomeIcon
                                                    className={styles.plusCircle} 
                                                    icon="plus-circle" 
                                                    size='3x'
                                                    onClick={this.addItem}
                                                />
                                            </div>
                                        }
                                    </div>
                                    {isBrowser &&
                                    <div className={classnames('has-text-centered column', 'is-2', styles.vertAlign)} >
                                        <FontAwesomeIcon
                                            className={styles.plusCircle} 
                                            icon="plus-circle" 
                                            size='3x'
                                            onClick={this.addItem}
                                        />
                                    </div>
                                    }
                                </div>
                            </div>
                            <br /><br />
                            <p className="is-size-4 has-text-weight-semibold">Description</p>
                            <textarea style={{marginTop: '2rem'}} 
                                className="textarea" 
                                rows="9"
                                onChange={(e) => this.setDescription(e)}
                                value={product.description} />
                            <p className="is-size-4 has-text-weight-semibold" style={{marginTop: '2rem'}}>Links</p>
                            <div>
                                {links.map((item, index) => (
                                    <div key={index} style={{margin: '1rem',}}>
                                        <a href={item.url}><p style={{float: 'left',}}>{item.title}</p></a>
                                        <a className="delete" style={{float: 'left', marginLeft: '2rem',}} 
                                            onClick={() => this.deleteLInk(item.id)}></a>
                                        <div style={{clear: 'both',}} />
                                    </div>

                                ))}
                            </div>
                            <div id="spacer" style={{height: '2rem',}} />
                            <div className='level' onClick={this.onOpenModal} style={{position: 'relative',}}>
                                <FontAwesomeIcon icon="plus-circle" size='2x' style={{position: 'absolute',}}></FontAwesomeIcon>
                                <p className="is-size-4 has-text-weight-semibold" style={{marginLeft: '3rem',}}>Add New Link</p>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="columns" style={{ padding: !isBrowser ? '0 1rem' : '0 0 0 6rem',}}>
                    <div className="column is-3">
                        <button 
                            style={{ margin: !isBrowser ? '2rem 0' : '',}}
                            className="button is-fullwidth"
                            onClick={this.addUpDateProduct}
                        >Save Changes</button>
                    </div>
                </div>
                <Modal open={open} onClose={this.onCloseModal} center>
                    <div className={styles.modal}>
                        <div className="columns">
                            <div className="column">
                                <h1>Title</h1>
                                <TextField onChange={(e) => this.setLinkTitle(e)} value={this.state.newLinkTitle}/> 
                            </div>
                            <div className="column">
                                <h1>Link</h1>
                                <TextField onChange={(e) => this.setLinkUrl(e)} value={this.state.newLinkUrl}/> 
                            </div>
                        </div>
                        <button 
                            onClick={this.addLink}
                            className="button" 
                            style={{position: 'absolute', bottom: '1rem', textAlign: 'center', left: '50%', marginLeft: '0rem',}}>Add</button>
                    </div>
                </Modal>
            </div>
        );
    }
}

ProductAdd.propTypes = { 
    alert: PropTypes.object,
    addUpdateProduct: PropTypes.func,
    addProductLink: PropTypes.func,
    addProductVariant: PropTypes.func,
    cloneImage: PropTypes.func,
    deleteProductVariant: PropTypes.func,
    deleteProductLink: PropTypes.func,
    getProductSuggestions: PropTypes.string,
    history: PropTypes.object,
    loadRegions: PropTypes.func,
    loadCatalogBrands: PropTypes.func,
    loadCatalogCategories: PropTypes.func,
    loadPackagingTypes: PropTypes.func,
    loadCatalogCategoryProductTypes: PropTypes.func,
    loadProductVariants: PropTypes.func,
    loadSubTypes: PropTypes.func,
    loadSubRegions: PropTypes.func,
    location: PropTypes.object,
    productSuggestions: PropTypes.array,
    state: PropTypes.object,
    packagingTypes: PropTypes.array,
    productLinks: PropTypes.array,
    productVariants: PropTypes.array,
    searchCatalogProducts: PropTypes.func,
    uploadImage: PropTypes.func,
};


ProductAdd.defaultProps = {
    
};

export default withRouter(withAlert(ProductAdd));
