import React from 'react';
import styles from './styles.scss';
import PropTypes from 'prop-types';
import { Table, Tbody, Tr, Td } from 'react-super-responsive-table';
import get from 'lodash/get';
import { withRouter } from 'react-router';
import { withAlert } from 'react-alert';
import DropDown from '../../components/dropdown';
import classnames from 'classnames';
import { isMobileOnly, isTablet,  isBrowser} from 'react-device-detect';
import isEmpty from 'lodash/isEmpty';

import find from 'lodash/find';
import some from 'lodash/some';

let timer;
class ProductDetails extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            editProduct: false,
            productId: -1,
            selectedFile: null,
            quantities: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
            show: false,
            message: '',
            checked: false,
            showModal: false,
            repId: '',
        };
        this.addToCart = this.addToCart.bind(this);
        this.onOpenModal = this.onOpenModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
        this.toggleSelected = this.toggleSelected.bind(this);
    }

    onOpenModal() {
        this.setState({ open: true });
    }
  
    onCloseModal() {
        this.setState({ 
            open: false 
            
        });
    }

    componentDidMount() {
        this.props.loadProductDetails(this.props.location.state.productId).then((result) => {
            const distributorId = get(result, 'payload.distributor.id');
            this.props.getRepsForDist(distributorId).then((result) => {
                const repArray = get(result, 'payload', []);
                const repOptions = repArray.map(rep => [get(rep, 'id'), get(rep, 'contactName')]);
                this.setState({ repOptions: repOptions });

            });
        });
        this.props.loadProductLinks(this.props.location.state.productId);
        this.props.loadProductVariants(this.props.location.state.productId);
        this.props.loadPackagingTypes(0);
        this.props.getProfile();
    }

    handleChange(e, index){
        const quantities = [ ...this.state.quantities ];
        if(parseInt(e.target.value) >= 0){
            quantities[index] = parseInt(e.target.value);
            this.setState({quantities}); 
        }
    }

    storeRepId(value){
        this.setState({
            repId: value,
        });
    }    

    setAccountNum(value){
        this.setState({
            accountNumber: value,
        });
    }
    
    toggleSelected() {
        this.setState({
            checked: !this.state.checked,
        });
    }

    toggleModal(){
        const { showModal } = this.state;
        this.setState({
            showModal: !showModal,
        });
    }


    updateRepInfo(){
        const distributorId = get(this.props, 'productDetails.distributor.id', -1);
        const { repId, accountNumber } = this.state;
        const { retailerId } = this.props;
        this.props.updateRepInfo(distributorId, retailerId, repId, accountNumber).then((result) => {
            if(get(result, 'type') === 'profile-update-rep-info-succeeded'){
                this.toggleModal();
                this.props.alert.show(
                    <div style={{padding: '1rem',}}>Info has been updated.</div>,
                    {
                        timeout: 4000,  
                        type: 'success',
                        onClose: () => {} 
                    });
                    
            } else {
                this.toggleModal();
                this.props.alert.show(
                    <div style={{padding: '1rem',}}>An error occurred, info not updated.</div>,
                    {
                        timeout: 4000,  
                        type: 'success',
                        onClose: () => {} 
                    });
            }
        });
    }

    addToCart(e, index, productId, productVariantId, price) {
        const { cart, companyId} = this.props;
        const sku = get(this.props, 'productDetails.sku', 'N/A');
        const distributorId = get(this.props, 'productDetails.distributor.id', -1);
        const currentOrder = find(cart.orders, ['distributor.id', distributorId]) || [];
        const findSku = find(currentOrder.lineItems, ['product.sku', sku]) || {};
        const isProductInCart = isEmpty(findSku) ? false : some(currentOrder.lineItems, ['productVariant.id', productVariantId]) || false;

        if(!isProductInCart) {
            this.props.hasAccount(distributorId, companyId).then((result) => {
                if (get(result, 'type') === 'profile-has-account-failed'){
                    this.toggleModal();
                } else {
                    const qty = this.state.quantities[index];
                    if (qty>0) {
                        this.props.addLineItem(productId, productVariantId, price, qty).then(() => {
                            this.setState({
                                show: true,
                                message: `${qty} Item(s) added to cart.`,
                                quantities: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0], 
                            });
                            this.hideSuccess();
                        }); 
                    } else {
                        this.props.alert.show(
                            <div style={{padding: '3rem',}}>The quantity must be higher than 0 to add the item.</div>,
                            {
                                timeout: 4000,  
                                type: 'success',
                                onClose: () => {} 
                            });
                    }

                }
            });
        } else if (isMobileOnly)  {
            this.props.alert.show(
                <div style={{padding: '1rem', width:'100%', fontSize: '85%'}}>This item is already in your cart. You can edit the quantity from there. Would you like to do that now?</div>,
                {
                    timeout: 0,  
                    type: 'success',
                    dualAlert: true,
                    actionText: 'Yes',
                    actionTouched: () => {
                        this.props.history.push('/consumer-cart');
                        this.props.alert.alerts[0].close();
                    },
                    onClose: () => {} 
                });
        }
        else if (!isMobileOnly)  {
            this.props.alert.show(
                <div style={{padding: '3rem',}}>This item is already in your cart. You can edit the quantity from there. Would you like to do that now?</div>,
                {
                    timeout: 0,  
                    type: 'success',
                    dualAlert: true,
                    actionText: 'Yes',
                    actionTouched: () => {
                        this.props.history.push('/consumer-cart');
                        this.props.alert.alerts[0].close();
                    },
                    onClose: () => {} 
                });
        }
    }
    hideSuccess() {
        window.clearTimeout(timer);
        timer = window.setTimeout(() => {
            this.setState({
                show: false,
            });
        }, 2000);
    }

    submitModal(){
        !this.state.checked ? this.updateRepInfo()
            : this.updateRepInfo(); 
    }

    render()  
    {  
        const brand = get(this.props, 'productDetails.brand.name', '');
        const name = get(this.props, 'productDetails.name', '');
        const category = get(this.props, 'productDetails.category.name', '');
        const type = get(this.props, 'productDetails.type.name', '');
        const subType = get(this.props, 'productDetails.subType.name', '');
        const region = get(this.props, 'productDetails.region.name', '');
        const subRegion = get(this.props, 'productDetails.subRegion.name', '');
        const supplier = get(this.props, 'productDetails.distributor.name', 'N/A');
        const sku = get(this.props, 'productDetails.sku', 'N/A');
        const description = get(this.props, 'productDetails.description', 'N/A');
        const abv = get(this.props, 'productDetails.abv', 'N/A');
        const inStock = get(this.props, 'productDetails.inStock', false);
        let crumbs = category;
        if (type !== ''){ crumbs+=' / ' + type; }
        if (subType !== ''){ crumbs+=' / ' + subType; }
        if (region !== ''){ crumbs+=' / ' + region; }
        if (subRegion !== ''){ crumbs+=' / ' + subRegion; }
        const baseUrl = 'https://api.buyfluidly.com/v1/catalog/products/';
        let cartMessage = null;
        if( this.state.show) {
            cartMessage  = (
                <div  className="notification is-success">
                    {this.state.message} 
                </div> 
            );
        }
        const { retailerType } = this.props;
        const { showModal, accountNumber, repId, repOptions } = this.state;
        const distributorName = get(this.props, 'productDetails.distributor.name', -1);
        return (
            <div>
                <div className={styles.wrapper}>
                    <div className={classnames(styles.subheader, 'is-hidden-mobile')}>
                        <p className="is-size-5">{ crumbs }</p>
                    </div>
                    <div className="columns">
                        <div className="column is-one-third is-hidden-mobile">
                            <figure className="image is-square" style={{border: '2px solid', backgroundColor: '#fafafa'}}>
                                <img src={ baseUrl + this.props.location.state.productId + '/image'} 
                                    onError={(e) => {e.target.src = require('../../images/no-image-available.png');}}
                                    style={{width: 'auto', height:'auto', display: 'block', maxHeight: '100%', margin: 'auto'}}/>
                            </figure>
                            <p className="is-size-4 has-text-weight-semibold is-hidden-mobile" style={{marginTop: '4rem'}}>Details</p>
                            <div className="columns is-hidden-mobile">
                                <div className="column is-one-half">
                                    <Table style={{marginTop: '1rem'}}>
                                        <Tbody>
                                            <Tr>
                                                <Td>Type:</Td><Td>{category}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>Category:</Td><Td>{type}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>Subcategory:</Td><Td>{subType}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>Region:</Td><Td>{region}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>Subregion:</Td><Td>{subRegion}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>ABV:</Td><Td>{abv}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>Availability:</Td>
                                                <Td 
                                                    className={inStock ? 'has-text-success' : 'has-text-failure'}>
                                                    {inStock ? 'In Stock' : 'Out of Stock'}
                                                </Td>
                                            </Tr>
                                        </Tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                        <div className="column">
                            <div className={isMobileOnly ? 'level' : 'level is-mobile'}>
                                <div className='level-item-left'>
                                    <p className="subtitle">{ brand }</p>
                                    <p className="title">{ name }</p>
                                    <p className="is-size-5">Supplied By: {supplier}</p>
                                    <p className="is-size-5">SKU: {sku}</p>
                                </div>
                                { !isBrowser &&
                                    <figure className="image is-square level-item" style={{border: '2px solid', backgroundColor: '#fafafa'}}>
                                        <img src={ baseUrl + this.props.location.state.productId + '/image'} style={{width: 'auto', height:'auto', display: 'block', maxHeight: '100%', margin: 'auto',}}/>
                                    </figure> 
                                }
                                <div className='level-item-right'>
                                    {cartMessage}
                                </div>
                            </div>
                            {this.props.productVariants.map((item, index) => (
                                <div key={index} className="box" style={{ padding: !isBrowser ? '' : '1.5rem', backgroundColor: '#f1e2c3'}}>
                                    <div className="columns is-mobile">
                                        <div className={ !isBrowser ? 'image-is-32x32 column is-2' : 'image-is-64x64 column' }>
                                            {item.masterTypeId !== null &&
                                            <img src={require(`../../images/${item.masterTypeId}-icon.png`)} />
                                            }
                                        </div>
                                        <div className="column is-size-6 is-2 is-size-7-mobile">{item.qty}/{item.packageTypeName + ' ' + item.masterType}</div>
                                        <div className={ !isBrowser ? 'column is-2' : 'column is-5' }>
                                            <div className="is-size-6 is-size-7-mobile">
                                            ${ retailerType === '0' ? parseFloat(item.onPremisePrice).toFixed(2) : parseFloat(item.offPremisePrice).toFixed(2)}
                                            </div>
                                        </div>
                                        <div className="column is-2">
                                            <input 
                                                className="input" 
                                                type="number" 
                                                onBlur={e => e.target.value === '' ? e.target.value = this.state.quantities[index] : null }
                                                onFocus={e => e.target.value == this.state.quantities[index] ? e.target.value = '' : null}
                                                value={this.state.quantities[index]} 
                                                onChange={(e) => this.handleChange(e, index)}/>
                                        </div>
                                        <div className="column is-2 has-text-weight-bold">
                                            <a 
                                                className="button is-size-6 is-size-7-mobile" 
                                                style={{padding: '0 2rem 0 2rem'}}
                                                onClick={(e) => this.addToCart(e, index, this.props.location.state.productId, item.id, retailerType === '0' ? parseFloat(item.onPremisePrice).toFixed(2) : parseFloat(item.offPremisePrice).toFixed(2))}
                                            >Add</a>  
                                        </div>
                                    </div>
                                </div>
                            ))}
                            
                            <p className="is-size-7">*Pricing is subject to change and will be confirmed by supplier on order acceptance.</p>
                            <p className="is-size-4 has-text-weight-semibold" style={{marginTop: !isBrowser ? '1rem' : '4rem'}}>Description</p>
                            <div style={{marginTop: '1rem'}}>
                                <p className="is-size-6">&quot;{description}&quot;</p>
                                <br /><br />
                            </div>
                            { this.props.productLinks.length > 0 && 
                            <p className="is-size-4 has-text-weight-semibold" style={{marginTop: !isBrowser ? '' : '2rem'}}>Links</p>
                            }
                            <div style={{marginTop: !isBrowser ? '.5rem' : '1rem'}}>
                                {this.props.productLinks.map((item, index) => (
                                    <a key={index} 
                                        href={item.url} 
                                        target='_blank' 
                                        rel='noopener noreferrer'>
                                        <p className="is-size-6">{item.title}</p>
                                    </a>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                { showModal && 
                    <div className={styles.wrap}>
                        <div className={styles.modal}>
                            <a 
                                className={classnames('delete is-medium', styles.delete)}
                                onClick={() => { this.toggleModal(); }} />
                            <div style={{padding: '0 1rem 1rem',}}>
                                <h1 className='title is-4' style={{paddingBottom: '1rem',}}>Customer Number</h1>
                                <p className='subtitle is-6'>You are adding a product to your cart from a supplier you have not previously ordered from.</p>
                                <p className='subtitle is-6'>Please supply your customer number or request a new account with this supplier 
                                before you continue placing your order request.</p>
                                <input 
                                    value={accountNumber}
                                    className="input" 
                                    type="text" 
                                    placeholder="Enter customer number" 
                                    style={{marginBottom: '1rem', }}
                                    onChange={(e) => this.setAccountNum(e.target.value)}/>
                                { repOptions.length > 0 &&
                                <DropDown 
                                    value={repId}
                                    placeholder='Select Your Sales Rep'
                                    options={repOptions} 
                                    style={{marginBottom: '1rem', }} 
                                    onChange={(value) => this.storeRepId(value)}/> 
                                }
                                <p className='is-size-5 has-text-weight-bold has-text-centered'>OR</p>
                                <div className='columns is-mobile'>
                                    <div className='column is-1'/>
                                    <div className='column is-2'>
                                        <label className={styles.container}>
                                            <input type="checkbox" onClick={() => this.toggleSelected()}/>
                                            <span className={styles.checkmark}></span>
                                        </label>
                                    </div>
                                    {!isMobileOnly && 
                                    <div className='column'>I do not have an account with {distributorName} yet. Please contact me to create a new account.</div>
                                    }
                                    {isMobileOnly && 
                                    <div className='column is-size-7'>I do not have an account with {distributorName} yet. Please contact me to create a new account.</div>
                                    }
                                </div>
                            </div>
                            <button 
                                onClick={() => { this.submitModal(); }}
                                className='button is-fullwidth' 
                                style={{marginTop: '1.5rem', }}>Submit</button>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

ProductDetails.propTypes = {  
    alert: PropTypes.object,
    addLineItem: PropTypes.func,
    cart: PropTypes.object,
    getRepsForDist: PropTypes.func.isRequired,
    getProfile: PropTypes.func,
    hasAccount: PropTypes.func.isRequired,
    history: PropTypes.object,
    loadCart: PropTypes.func.isRequired,
    loadPackagingTypes: PropTypes.func.isRequired,
    loadProductDetails: PropTypes.func.isRequired,
    loadProductLinks: PropTypes.func.isRequired,
    loadProductVariants: PropTypes.func.isRequired,
    location: PropTypes.object,
    packagingTypes: PropTypes.array,
    productId: PropTypes.number,
    companyId: PropTypes.number,
    state: PropTypes.object,
    category: PropTypes.object,
    productLinks: PropTypes.array,
    productVariants: PropTypes.array,
    retailerType: PropTypes.string,
    retailerId: PropTypes.number,
    submitAccountInfo: PropTypes.func.isRequired,
    updateRepInfo: PropTypes.func,
};

export default withAlert(withRouter(ProductDetails));
