import {combineReducers} from 'redux';

import brands from './brands';
import categories from './categories';
import addProductLink from './add-product-link';
import deleteProductLink from './delete-product-link';
import deleteProduct from './delete-product';
import productTypes from './product-types';
import subTypes from './sub-types';
import regions from './regions';
import subRegions from './sub-regions';
import productDetails from './product-details';
import productLinks from './product-links';
import productVariants from './product-variants';
import packagingTypes from './packaging-types';
import products from './products';
import distributors from './distributors';
import uploadImage from './update-product-details';
import productsActivePage from './products-active-page';
import productsShowingNew from './products-showing-new';
import isSearching from './is-searching';
import productSuggestions from './product-suggestions';

export default combineReducers({
    addProductLink,
    brands,
    categories,
    deleteProduct,
    deleteProductLink,
    distributors,
    isSearching,
    productsActivePage,
    productSuggestions,
    productTypes,
    regions,
    subRegions,
    subTypes,
    productDetails,
    productLinks,
    productVariants,
    packagingTypes,
    products,
    productsShowingNew,
    uploadImage,
});
