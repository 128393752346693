import { connect } from 'react-redux';
import Component from './component';
import Commands from 'state/commands';

function mapStateToProps() {
    return {};
}


function mapDispatchToProps(dispatch) {
    return {   
        resetPassword: (email, verificationCode, password) => dispatch(Commands.Profile.ResetPassword(email, verificationCode, password)),     
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);