import React from 'react';
import { Tr, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import PropTypes from 'prop-types';
import styles from './styles.scss';
import classnames from 'classnames';

class TableRow extends React.Component {

    constructor(){
        super();
        this.onRowClick = this.onRowClick.bind(this);
    }

    onRowClick() {
        this.props.onRowClick(this.props.productId);
    }

    render() {
        const baseUrl = 'https://api.buyfluidly.com/v1/catalog/products/';
        const { availablePackages, inStock, productId, subtitle1, subtitle2, subtitle3, retailerType, title, currentUsertype} = this.props;
        return (
            <Tr onClick={() => this.onRowClick()} style={{borderBottom: '.5px solid #eee'}} className='columns'>
                <Td className='is-hidden-mobile column is-2'>
                    <div className="image" style={{padding: '.3rem',}}>
                        <img 
                            src={baseUrl + productId + '/image'} 
                            onError={(e) => {e.target.src = require('../../images/no-image-available.png');}}
                            style={{width: 'auto', height:'auto', display: 'block', maxHeight: '5rem', margin: 'auto',}}/>
                    </div>
                </Td>
                <Td className='is-hidden-mobile column is-5'>
                    <figure style={{padding: '0',}}>
                        <h1 className='is-size-6 has-text-weight-bold'>{title}</h1>
                        <h3 className='is-size-6'>{subtitle1}</h3>
                        <h3 className='is-size-6'>{subtitle2}</h3>
                        <h3 className='is-size-6'>{subtitle3}</h3>
                    </figure>
                </Td>
                <div className='column is-hidden-mobile'>
                    <Td className='columns is-multiline'>
                        {availablePackages.map((item, index) => (
                            <div key={index} style={{padding:'1rem 1.5rem 1rem 0'}} className='column is-6'>
                                <div style={{position: 'relative',}} className=' has-text-weight-semibold'>
                                    <div style={{paddingLeft: '25px',}}>{item.qty + '/' + item.packageTypeName + ' ' + item.masterType}</div>
                                    <div className='image is-24x24' style={{top: '0', left: '0', position: 'absolute',}}>
                                        {item.masterTypeId !== null &&
                                        <img src={require(`../../images/${item.masterTypeId}-icon.png`)} />
                                        }
                                    </div>
                                </div>
                                <div style={{padding: '0 0 0 25px', fontSize: '12px'}}>
                                    { currentUsertype === '1' && retailerType==='0' && '$' + parseFloat(item.onPremisePrice).toFixed(2)}
                                    { currentUsertype === '1' && retailerType==='1' && '$' + parseFloat(item.offPremisePrice).toFixed(2)}
                                    { currentUsertype === '2' && 'On Premise: $' + parseFloat(item.onPremisePrice).toFixed(2) }
                                    <br/>
                                    { currentUsertype === '2' && 'Off Premise: $' + parseFloat(item.offPremisePrice).toFixed(2) }
                                </div>
                            </div>
                        ))}
                        { false && 
                            <div style={{padding:'.5rem 1rem 0 0'}} className={classnames('has-text-right', 'is-2', (inStock ? styles.inStock : styles.outOfStock))}>
                                {inStock ? 'In Stock' : 'Out of Stock'}
                            </div>
                        }
                    </Td>
                </div>

                { /* mobile layout, hide on tablet and desktop */}
                <div className='is-hidden-tablet is-hidden-desktop' style={{display: 'flex',}}>
                    
                    <div className="image is-36x36" style={{width: '15%', padding: '12px', }}>
                        <img 
                            src={baseUrl + productId + '/image'} 
                            onError={(e) => {e.target.src = require('../../images/no-image-available.png');}}
                            style={{width: 'auto', height:'auto', display: 'block', maxHeight: '6rem', margin: 'auto',}}/>
                    </div>
                    <div style={{width: '50%',}}>
                        <figure style={{padding: '.5rem',}}>
                            <h1 className='is-size-7 has-text-weight-bold'>{title}</h1>
                            <h3 className='is-size-7'>{subtitle1}</h3>
                            <h3 className='is-size-7'>{subtitle2}</h3>
                            <h3 className='is-size-7'>{subtitle3}</h3>
                        </figure>
                    </div>
              
                    <div style={{width: '35%',}}>
                        <figure>
                            {availablePackages.map((item, index) => (
                                <div key={index} style={{padding:'1rem 1.5rem 1rem 0'}} className='column is-6'>
                                    <div style={{position: 'relative',}} className=' has-text-weight-semibold'>
                                        <div style={{paddingLeft: '25px',}}>{item.qty + '/' + item.packageTypeName + ' ' + item.masterType}</div>
                                        <div className='image is-24x24' style={{top: '0', left: '0', position: 'absolute',}}>
                                            {item.masterTypeId !== null &&
                                            <img src={require(`../../images/${item.masterTypeId}-icon.png`)} />
                                            }
                                        </div>
                                    </div>
                                    <div style={{padding: '0 0 0 25px', fontSize: '12px'}}>
                                        { currentUsertype === '1' && retailerType==='0' && '$' + parseFloat(item.onPremisePrice).toFixed(2)}
                                        { currentUsertype === '1' && retailerType==='1' && '$' + parseFloat(item.offPremisePrice).toFixed(2)}
                                        { currentUsertype === '2' && 'On Premise: $' + parseFloat(item.onPremisePrice).toFixed(2) }
                                        <br/>
                                        { currentUsertype === '2' && 'Off Premise: $' + parseFloat(item.offPremisePrice).toFixed(2) }
                                    </div>
                                </div>
                            ))}
                        </figure>
                    </div>
                </div>
            </Tr>

        );
    }
}
export default TableRow;

TableRow.propTypes = {  
    availablePackages: PropTypes.array.isRequired,
    currentUsertype: PropTypes.string,
    productType: PropTypes.string,
    productId: PropTypes.number,
    picUrl: PropTypes.string,
    title: PropTypes.string,
    subtitle1: PropTypes.string,
    subtitle2: PropTypes.string,
    subtitle3: PropTypes.string,
    itemPrice: PropTypes.number,
    packagePrice: PropTypes.number,
    inStock: PropTypes.bool,
    onRowClick: PropTypes.func,  
    retailerType: PropTypes.string,            
};

TableRow.defaultProps = {  
    productType: 'Wine',
    title: 'Wine, Beer or Spirit',
    subtitle1: 'no data is available on this product',
    subtitle2: '',
    subtitle3: '',
    itemPrice: 0,
    packagePrice: 0,
    inStock: false,                   
};
