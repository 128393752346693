import {types as AuthEventTypes} from '../../authentication/events';
import {types as EventTypes} from '../events';
import basicReducer from '../../common/reducers/basic-reducer';

import constant from 'lodash/constant';

const initialState = [];

const reductionLookup = {
    [AuthEventTypes.LoggedOut]: constant(initialState),
    [EventTypes.LoadCommentsStarted]: constant(initialState),
    [EventTypes.LoadCommentsFailed]: constant(initialState),
    [EventTypes.LoadCommentsSucceeded]: (_, result) => result,

    [EventTypes.DeleteCommentFailed]: constant(initialState),
    [EventTypes.DeleteCommentSucceeded]: (_, result) => result,

    [EventTypes.AddCommentFailed]: constant(initialState),
    [EventTypes.AddCommentSucceeded]: (_, result) => result,
};

export default basicReducer(initialState, reductionLookup);