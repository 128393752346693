exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-pages-distributor-order-history-styles--wrapper {\n  padding: 1rem 2rem; }\n  @media (min-width: 320px) and (max-width: 480px) {\n    .src-pages-distributor-order-history-styles--wrapper {\n      padding: .5rem; } }\n", ""]);

// exports
exports.locals = {
	"wrapper": "src-pages-distributor-order-history-styles--wrapper"
};