import selectorsCommon from 'state/common/selectors';

import get from 'lodash/get';

export default selectorsCommon.bindToReducer('catalog', {
    isSearching: () => (state) => get(state, 'isSearching', false),
    productsActivePage: () => (state) => get(state, 'productsActivePage', 1),
    addProductVariant: () => (state) => get(state),
    deleteProductVariant: () => (state) => get(state),
    catalogBrands: () => (state) => get(state, 'brands', []),
    catalogCategories: () => (state) => get(state, 'categories', []),
    catalogRegions: () => (state) => get(state, 'regions', []),
    addProductLink: () => (state) => get(state),
    deleteProductLink: () => (state) => get(state),
    distributors: () => (state) => get(state, 'distributors', []),
    productTypesCategoryId: () => (state) => get(state, 'productTypes.categoryId', ''),
    productTypes: () => (state) => get(state, 'productTypes.types', []),
    subTypes: () => (state) => get(state, 'subTypes.types', []),
    subRegions: () => (state) => get(state, 'subRegions', []),
    productDetails: () => (state) => get(state, 'productDetails', {}),
    productLinks: () => (state) => get(state, 'productLinks', {}),
    productSuggestions: () => (state) => get(state, 'productSuggestions', {}),
    productVariants: () => (state) => get(state, 'productVariants', {}),
    packagingTypes: () => (state) => get(state, 'packagingTypes', []),
    products: () => (state) => get(state, 'products', []),
    productsShowingNew: () => (state) => get(state, 'productsShowingNew', 0),
    uploadImage: () => (state) => get(state),
});
 