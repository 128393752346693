import {combineReducers} from 'redux';
import retailersList from './retailers-list';
import distributorsList from './distributors-list';
import isImpersonating from './is-impersonating';
import impersonationString from './impersonation-string';
import impersonationName from './impersonation-name';

export default combineReducers({
    retailersList,
    distributorsList,
    isImpersonating,
    impersonationName,
    impersonationString,
});
