import React from 'react';
import styles from './styles.scss';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { isMobileOnly, isTablet,  isBrowser} from 'react-device-detect';

class ConsumerOrderHistory extends React.Component {

    constructor(){
        super();
        this.state = {
            orderSelected: false,
            orderId: -1,
        };
        this.onRowClick = this.onRowClick.bind(this);
    }

    componentDidMount() {
        this.props.loadOrderHeaders();
    }

    onRowClick(orderId, distributorId) {
        this.props.history.push({ pathname: '/consumer-order-details', state: { orderId: orderId, distributorId:  distributorId}, });
    }

    try(func, fallbackValue) {
        try {
            const value = func();
            return (value === null || value === undefined) ? fallbackValue : value;
        } catch (e) {
            return fallbackValue;
        }
    }

    render() {
        const { orderHeaders } = this.props;
        return (
            <div className={styles.wrapper}>
                <div className='level'>
                    <div className='level-item-left'>
                        <div className='is-size-3 is-size-4-mobile' style={{margin: isMobileOnly ? '12px 0 0 12px' : ''}}>Order History</div>
                    </div>
                </div>
                <Table className='table is-striped' style={{border: '0.5px solid', borderRadius: 10, borderColor: '#ccc'}}>
                    <Thead>
                        <Tr style={{ borderWidth: '1px', borderColor: '#333', backgroundColor: '#f1e2c3',}}>
                            <Th style={{padding: '1rem 0 0 1rem',}}>Date</Th>
                            <Th style={{padding: '1rem',}} colSpan={2}>Supplier</Th>
                            <Th style={{padding: '1rem',}}>Amount</Th>
                            <Th style={{padding: '1rem',}}>Status</Th>
                            <Th style={{padding: '1rem',}}>Order #</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        { orderHeaders.map((item, index) => (
                            <Tr key={index} onClick={() => this.onRowClick(item.orderId, item.distributorId)}>
                                <Td>{new Date(this.try(() => item.submitDate, 0)).toLocaleDateString()}</Td>
                                <Td colSpan={2}>{this.try(() => item.distributor.name, 0)}</Td>
                                <Td>${(this.try(() => item.total, 0)).toFixed(2)}</Td>
                                <Td className={this.try(() => item.status==='verified') ? 'has-text-success' : 'has-text-danger'}>{this.try(() => item.status==='verified') ? 'Acknowledged' : 'Open'}</Td>
                                <Td><a>{this.try(() => item.orderId, 0)}</a></Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
                {/*<div className='level'>
                    <div className='level-item-left' />
                    <div className='level-item-right'>
                        <a>Next 50&gt;</a>
                    </div>
                </div>*/}
            </div>
        );
    }
}

export default withRouter(ConsumerOrderHistory);

ConsumerOrderHistory.propTypes = {
    history: PropTypes.object,
    orderHeaders: PropTypes.array,
    loadOrderHeaders: PropTypes.func,
};
 