import React from 'react';
import Dropdown from '../../components/dropdown';
import TextField from '../../components/text-field';
import styles from './styles.scss';
import PropTypes from 'prop-types';
import 'rc-slider/assets/index.css';
import get from 'lodash/get';
//import { isMobileOnly, isTablet,  isBrowser} from 'react-device-detect';

export default class Filters extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            productName: '',
            brandId: -1,
            categoryId: -1,
            distId: -1,
            typeId: -1,
            subTypeId: -1,
            regionId: -1,
            subRegionId: -1,
            packagingSubtypeId: -1,
            minPrice: 0,
            maxPrice: 500,
        };
    }

    componentDidMount() {
        this.initialState = this.state;
    }

    reset() {
        this.setState(this.initialState, () => {
            this.handleSearch();
        });
    }

    handleSliderChange(value) {
        this.setState({ minPrice: value[0], maxPrice: value[1],});
    }

    loadProductTypes(id) {
        let { categoryId, typeId } = this.state;
        categoryId = parseInt(id);
        typeId = -1;
        this.props.loadProductTypes(id).then(() => {
            this.setState({ categoryId, typeId });
            this.loadSubTypes(-1);
        });
    }
    
    loadSubTypes(id) {
        this.setState({ typeId: parseInt(id), });
        const { categoryId, } = this.state;
        this.props.loadSubTypes(categoryId, id);
    }

    loadSubRegions(id) {
        this.setState({ regionId: parseInt(id), });
        this.props.loadSubRegions(id);
    }

    loadPackagingTypes(id) {
        this.setState({ packagingTypeId: parseInt(id), });
        this.props.loadPackagingTypes(id);
    }

    store(prop, value){
        this.setState({ [prop]: parseInt(value), });
    }

    storeString(prop, value){
        this.setState({ [prop]: value, });
    }

    handleSearch() {
        const { 
            distId,
            productName,
            brandId,
            categoryId,
            typeId,
            subTypeId,
            regionId,
            subRegionId,
            packagingSubtypeId,
            minPrice = 0
        } = this.state;

        const maxPrice = get(this.state, 'maxPrice', 500);
        this.props.onSearch(distId, productName, brandId, categoryId, typeId, subTypeId, regionId, subRegionId, packagingSubtypeId, minPrice, maxPrice >= 500 ? 999999999 : maxPrice );
    }
        
    render () {
        const Slider = require('rc-slider');
        const createSliderWithTooltip = Slider.createSliderWithTooltip;
        const Range = createSliderWithTooltip(Slider.Range);
        const categoryOptions = get(this.props, 'categories', []).map(category => [get(category, 'id'), get(category, 'title')]);
        categoryOptions.unshift([-1, 'Any']);
        const productTypeOptions = get(this.props, 'productTypes', []).map(productType => 
            [get(productType, 'id'), get(productType, 'title'), get(productType, 'categoryId')]);
        productTypeOptions.unshift([-1, 'Any']);
        const subTypesOptions = get(this.props, 'subTypes', []).map(subType => [get(subType, 'id'), get(subType, 'title')]);
        subTypesOptions.unshift([-1, 'Any']);
        const regionsOptions = get(this.props, 'regions', []).map(region => [get(region, 'id'), get(region, 'title')]);
        regionsOptions.unshift([-1, 'Any']);
        const subRegionsOptions = get(this.props, 'subRegions', []).map(subRegion => [get(subRegion, 'id'), get(subRegion, 'title')]);
        subRegionsOptions.unshift([-1, 'Any']);
        const brandsOptions = get(this.props, 'brands', []).map(brand => [get(brand, 'id'), get(brand, 'title')]);
        brandsOptions.unshift([-1, 'Any']);
        const masterPackagingTypes = ['Bottle(s)','Can(s)','Keg'];
        const packagingOptions = get(this.props, 'packagingTypes', []).map(packagingType => 
            [get(packagingType, 'id'), get(packagingType, 'title') + ' ' + masterPackagingTypes[get(packagingType, 'masterType')]]);
        packagingOptions.unshift([-1, 'Any']);
        const distOptions = get(this.props, 'distributors', []).map(dist => 
            [get(dist, 'id'), get(dist, 'name')]);
        distOptions.unshift([-1, 'Any']);
        const { categoryId, distId, typeId, subTypeId, regionId, subRegionId, packagingSubtypeId, 
            brandId, productName, minPrice, maxPrice} = this.state;
        const { hideDistFilter } = this.props;
        return(
            <div className={styles.wrapper}>
                { !hideDistFilter && distOptions.length > 0 &&
                <div style={{ marginBottom: '1rem', }}>
                    <p className="is-size-6 has-text-weight-semibold">Distributor:</p>
                    <Dropdown
                        id="Distributors" 
                        onChange={(value) => this.store('distId', value)}
                        value={distId}
                        options={distOptions}/>
                </div>
                }
                <p className="is-size-6 has-text-weight-semibold">Category:</p>
                <Dropdown
                    id="Category" 
                    onChange={(value) => this.loadProductTypes(value)} 
                    value={categoryId}
                    options={categoryOptions}/>
      
                <div>
                    <p className="is-size-6 has-text-weight-semibold">Sub Category:</p>
                    <Dropdown 
                        id="SubCategory" 
                        onChange={(value) => this.loadSubTypes(value)} 
                        value={typeId}
                        options={productTypeOptions}/>
                    <p className="is-size-6 has-text-weight-semibold">Type:</p>
                    <Dropdown 
                        id="Type" 
                        onChange={(value) => this.store('subTypeId', value)} 
                        value={subTypeId}
                        options={subTypesOptions}/>
                    <p className="is-size-6 has-text-weight-semibold">Region:</p>
                    <Dropdown 
                        id="Regions" 
                        onChange={(value) => this.loadSubRegions(value)}
                        value={regionId} 
                        options={regionsOptions}/>
                    <p className="is-size-6 has-text-weight-semibold">Sub Region:</p>
                    <Dropdown 
                        id="subRegions" 
                        onChange={(value) => this.store('subRegionId', value)} 
                        value={subRegionId}
                        options={subRegionsOptions}/>
                
                    <p className="is-size-6 has-text-weight-semibold">Package Type:</p>
                    <Dropdown 
                        id="packagingSizes" 
                        onChange={(value) => this.store('packagingSubtypeId', value)}
                        value={packagingSubtypeId} 
                        options={packagingOptions}/>
                </div>
            
                <br/>
                <p className="is-size-6 has-text-weight-semibold">Brand:</p>
                <Dropdown 
                    id="brands" 
                    onChange={(value) => this.store('brandId', value)}
                    value={brandId} 
                    options={brandsOptions}/>
                <p className="is-size-6 has-text-weight-semibold">Keyword:</p>
                <TextField
                    fieldName="product" 
                    type="text" 
                    value={productName} 
                    onChange={(value) => this.storeString('productName', value)}
                />
                <div style={{ padding: '.5rem',}}>
                    <div className='is-size-7'>Search products between ${minPrice} and ${maxPrice}&gt;</div>
                    <Range 
                        onAfterChange={(value) => this.handleSliderChange(value)}
                        min={0}
                        max={500}
                        defaultValue={[minPrice, maxPrice]}
                        railStyle={{ backgroundColor: 'white',}}
                        trackStyle={[{ backgroundColor: '#c7b299', }]}
                        handleStyle={[{ border: '.5px solid', borderColor: '#ccc', borderRadius: 0,}]} 
                        activeDotStyle={{ border: 0, borderRadius: 0,}} />
                </div>
                <div style={{ padding: '1rem .5rem 1rem .5rem',}}>
                    <button className="button is-fullwidth" onClick={() => this.handleSearch()}>Search</button>
                </div>
                <div style={{ padding: '1rem .5rem 0 .5rem', textAlign: 'center'}}>
                    <a onClick={() => this.reset()}>clear</a>
                </div>
            </div>
        );
    }
}

Filters.propTypes = {
    categories: PropTypes.array,
    hideDistFilter: PropTypes.bool,
    productTypes: PropTypes.array,
    regions: PropTypes.array,
    subRegions: PropTypes.array,
    subTypes: PropTypes.array,
    loadProductTypes: PropTypes.func,
    loadSubTypes: PropTypes.func,
    loadRegions: PropTypes.func,
    loadSubRegions: PropTypes.func,
    loadPackagingTypes: PropTypes.func,
    packagingTypes: PropTypes.array,
    brands: PropTypes.array,
    onSearch: PropTypes.func,
};