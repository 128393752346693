import React from 'react';
import styles from './styles.scss';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { Redirect } from 'react-router';
import TextField from '../../components/text-field';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import get from 'lodash/get';
import { isMobileOnly, isTablet,  isBrowser} from 'react-device-detect';

class DistributorDashboard extends React.Component {

    constructor(){
        super();
        this.state = {
            orderSelected: false,
            orderId: -1,
            search: false,
            searchString: '',
        };
        this.onRowClick = this.onRowClick.bind(this);
    }

    componentDidMount() {
        this.props.loadOrderHeaders().then((result) => {
            if (get(result, 'payload') === 'Unauthorized.'){
                this.props.onLogout().then(() => this.props.history.push('/'));
            }
        });
    }

    onRowClick(orderId) {
        this.setState({
            orderSelected: true,
            orderId: orderId,
        });
    }

    setSearchString(string){
        let { searchString } = this.state;
        searchString = string;
        this.setState({
            searchString: searchString,
        });
    }

    search() {
        this.setState({
            search: true,
        });
    }


    enterPressed(event) {
        const code = event.keyCode || event.which;
        if(code === 13) { 
            this.setState({
                search: true,
            });
        } 
    }

    render() {
        const { orderHeaders } = this.props;
        const { orderSelected, orderId, search, searchString, } = this.state;
        return (
            <div className={styles.wrapper}>
                {
                    orderSelected && <Redirect to={{ pathname: '/distributor-order-details', state: { orderId: orderId }, }}/>
                }
                {
                    search && <Redirect to={{ pathname: '/distributor-catalog', state: { searchString: searchString }, }}/>
                }
                <div className='columns'>
                    <div className='column is-8'>
                        <div className='level'>
                            <div className='level-item-left'>
                                <div className={isMobileOnly ? 'is-size-5' : 'is-size-3'}>Recent Order Requests</div>
                            </div>
                            
                        </div>
                        <Table className='table is-striped' style={{border: '0.5px solid', borderRadius: 10, borderColor: '#ccc'}}>
                            <Thead>
                                <Tr style={{ borderWidth: '1px', borderColor: '#333', backgroundColor: '#f1e2c3',}}>
                                    <Th style={{padding: '1rem 0 0 1rem',}}>Date</Th>
                                    <Th style={{padding: '1rem',}} colSpan={2}>Customer</Th>
                                    <Th style={{padding: '1rem',}}>Amount</Th>
                                    <Th style={{padding: '1rem',}}>Status</Th>
                                    <Th style={{padding: '1rem',}}>Order #</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                { orderHeaders.length>0 &&
                                    orderHeaders.slice(0, 10).map((item, index) => (
                                        <Tr key={index} onClick={() => this.onRowClick(item.orderId)}>
                                            <Td>{ new Date(item.submitDate).toLocaleDateString()}</Td>
                                            
                                            <Td colSpan={2}>{item.retailer!= null ? item.retailer.name : 'N/A'}</Td>
                                            <Td>${item.total!= null ? item.total.toFixed(2) : '0'}</Td>
                                        
                                            <Td
                                                className={item.status === 'open' ? 'has-text-danger' : 'has-text-success'}>
                                                {item.status === 'open' ? 'Open' : 'Acknowledged'}
                                            </Td>
                                            <Td><a>{item.orderId}</a></Td>
                                        </Tr>
                                    
                                    ))}
                            </Tbody>
                        </Table>
                        <div className='level'>
                            <div className='level-item-left'>
                                <Link to='/distributor-order-history'>view/search all orders &gt;</Link>
                            </div>
                        </div>
                    </div>
                    <div className="column">
                        <div className='is-size-3'>My Catalog</div>
                        <div className={styles.searchWrapper}>

                            <div className='is-size-5'>Search for a product</div>
                            <TextField 
                                onChange={(value) => this.setSearchString(value)}
                                value={ searchString }
                                press={this.enterPressed.bind(this)}/>
                            <button type="text" className="button is-fullwidth" style={{margin: '2rem 0', }} onClick={() => this.search()}>Search</button>
                            <div className='is-size-5 has-text-centered'>Or</div>
                            <Link to='/product-add'>
                                <button className="button is-fullwidth" style={{margin: '2rem 0', }}>Add a new product</button>
                            </Link>
                            <Link to='/distributor-catalog'>
                                <p>View my catalog</p>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(DistributorDashboard);

DistributorDashboard.propTypes = {
    history: PropTypes.object,
    loadOrderHeaders: PropTypes.func,
    onLogout: PropTypes.func,
    orderHeaders: PropTypes.array,
};
 