import { connect } from 'react-redux';

import Component from './component';


function mapStateToProps(state) {
    return {

    };
}


export default connect(mapStateToProps)(Component);