exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-pages-distributor-dashboard-styles--wrapper {\n  padding: 1rem 2rem; }\n  @media (min-width: 320px) and (max-width: 480px) {\n    .src-pages-distributor-dashboard-styles--wrapper {\n      padding: .5rem; } }\n\n.src-pages-distributor-dashboard-styles--searchWrapper {\n  padding: 2rem;\n  background-color: #f1e2c3;\n  border-radius: 10px;\n  border: 1px solid #ccc;\n  margin-top: 1.5rem; }\n", ""]);

// exports
exports.locals = {
	"wrapper": "src-pages-distributor-dashboard-styles--wrapper",
	"searchWrapper": "src-pages-distributor-dashboard-styles--searchWrapper"
};