import {buildEvents, noParameters, namedParams, singleParameter} from '../../common/events';

const definition = {

    GetTerritoriesFailed: singleParameter,
    GetTerritoriesSucceeded: singleParameter,

    GetProfileFailed: singleParameter,
    GetProfileSucceeded: singleParameter,
    
    SubmitAccountInfoFailed: singleParameter,
    SubmitAccountInfoSucceeded: singleParameter,

    StatesLoading: noParameters,
    LoadStatesFailed: singleParameter,
    LoadStatesSucceeded: singleParameter,

    CountiesLoading: noParameters,
    LoadCountiesFailed: singleParameter,
    LoadCountiesSucceeded: singleParameter,

    SaveProfileStarted: noParameters,
    SaveProfileSucceeded: singleParameter,
    SaveProfileFailed: noParameters,

    FieldUpdated: namedParams('name', 'value'),
    ValidationFailed: singleParameter,
    ResetValidation: noParameters,
    ResetValidationField: singleParameter,

    GetRepsLoading: noParameters,
    GetRepsFailed: singleParameter,
    GetRepsSucceeded: singleParameter,

    GetRepsForDistLoading: noParameters,
    GetRepsForDistFailed: singleParameter,
    GetRepsForDistSucceeded: singleParameter,

    GetCustomerNumFailed: singleParameter,
    GetCustomerNumSucceeded: singleParameter,

    DeleteRepFailed: singleParameter,
    DeleteRepSucceeded: singleParameter,

    UpdateRepInfoFailed: singleParameter,
    UpdateRepInfoSucceeded: singleParameter,

    HasAccountFailed: singleParameter,
    HasAccountSucceeded: singleParameter,

    PostRepFailed: singleParameter,
    PostRepSucceeded: singleParameter,

    PostTerritoriesFailed: singleParameter,
    PostTerritoriesSucceeded: singleParameter,

    FireEmailFailed: singleParameter,
    FireEmailSucceeded: singleParameter,

    ResetPasswordFailed: singleParameter,
    ResetPasswordSucceeded: singleParameter,
};

const {types, events} = buildEvents('profile', definition);

export {types};

export default events;