import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles.scss';
import Checkbox from '../../components/checkbox';
import DropDown from '../../components/dropdown';
import TextField from '../../components/text-field';
import {createPropTypesObject, getFieldProperties} from '../../utils/component';
import get from 'lodash/get';
import { withRouter } from 'react-router';
import { isMobileOnly, isTablet,  isBrowser} from 'react-device-detect';


const typesOfBusiness = [
    [0, 'On Premise: A Restaurant or Bar'],
    [1, 'Off Premise: A Package Store']

];

class ConsumerProfile extends React.Component {

    constructor(props){
        super(props);
        this.state = { isEditing: false, };
    }

    componentDidMount(){
        if(this.props.location.state != null) {
            this.setState({
                isEditing: true,
            });
            this.props.getProfile();
        }
    }

    componentWillAmount(){
        this.setState({
            isEditing: false,
        });
    }


    render() {
        const { save } = this.props;
        const { isEditing } = this.state;
        const fields = get(this.props, 'fields', {});
        const address = getFieldProperties(fields, 'address');
        const address2 = getFieldProperties(fields, 'address2');
        const agreed = getFieldProperties(fields, 'agreed');
        const businessType = getFieldProperties(fields, 'businessType');
        const company = getFieldProperties(fields, 'companyName');
        const counties = getFieldProperties(fields, 'counties');
        const city = getFieldProperties(fields, 'city');
        const ein = getFieldProperties(fields, 'ein');
        const fullName = getFieldProperties(fields, 'fullName');
        const phone = getFieldProperties(fields, 'phone');
        const website = getFieldProperties(fields, 'website');
        const zip = getFieldProperties(fields, 'zip');   
        const state = getFieldProperties(fields, 'state');
        const stateOptions = get(this.props, 'statesList', []).map(state => [get(state, 'value'), get(state, 'label')]);
        const countyOptions = get(this.props, 'counties', []).map(county => [get(county, 'name'), get(county, 'name')]);

        return (
            <div>
                <section className='section profile-form' style={{padding: isBrowser ? '3rem' : '1rem'}}>
                    
                    <div className='columns'>
                        <div className='column is-two-thirds'>
                            <div className='is-size-3 title'>Complete Your Profile</div>
                            <div className='is-size-4 title'>Business Information</div>
                            <DropDown
                                {...businessType}
                                options={typesOfBusiness}
                                placeholder='Select business type'
                            />
                            <TextField {...company} labelText='Company Name:'/>
                            <TextField {...address} labelText='Street Address:'/>
                            <TextField {...address2} labelText='Address 2: (optional)'/>
                            <div className='columns' style={{marginBottom: 0}}>
                                <div className='column is-6'>
                                    <TextField {...city} labelText='City:'/>
                                </div>
                                <div className='column is-4'>
                                    <div className='is-size-6' style={{marginBottom: '.5rem'}}>State:</div>
                                    <DropDown 
                                        {...state}
                                        options={stateOptions}
                                        placeholder='Select' />
                                </div>
                                <div className='column is-2'>
                                    <TextField {...zip} labelText='Zipcode:'/>
                                </div>
                            </div>
                            <DropDown
                                {...counties}
                                options={countyOptions}
                                placeholder='County Name'
                            />
                            <TextField {...website} labelText='Website URL: (Optional)'/>
                            <TextField {...ein} labelText='EIN: (Optional)'/>
                            <div className='is-size-4 title'>Contact Information</div>
                            <TextField {...fullName} labelText='Full Name:'/>
                            <TextField {...phone} labelText='Contact Phone Number:'/>
                            { /* <TextField {...email} labelText='Contact Email Address:'/> */ }
                            { !isEditing && 
                            <label className={styles.container}>
                                <Checkbox {...agreed}/>
                                <span 
                                    onClick={()=> window.open('/terms-and-conditions', '_blank')}
                                    className={'is-size-6 is-size-7-mobile' + (agreed.error ? 'has-text-danger' : '')}
                                    style={{padding: '1rem 0 1rem .4rem'}}>
                                    <a>I agree to BuyFluidly&#39;s terms and conditions</a>
                                    <span style={{marginLeft: '2rem', color: 'red',}} className='is-size-7'>{agreed.error && agreed.error}</span>
                                </span>
                                <span className={styles.checkmark}></span>
                            </label>
                            }
                            <div className='columns'>
                                <div className='column is-9' style={{marginTop: '1rem'}}>
                                    <input className='button is-fullwidth'
                                        type='submit'
                                        value='Continue'
                                        onClick={() => save().then(result => {
                                            if(get(result, 'type') === 'profile-save-profile-succeeded') {
                                                this.props.history.push('/consumer-dashboard');
                                            }
                                        })}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='column'></div>
                    </div>
                </section>
            </div>
        );
    }
}

ConsumerProfile.propTypes = {
    fields: PropTypes.shape({
        ...createPropTypesObject([
            'address',
            'address2',
            'agreed',
            'businessType',
            'city',
            'company',
            'counties',
            'ein',
            'fullName',
            'phone',
            'state',
            'website',
            'zip',
            'email',
            'county',
        ], PropTypes.shape({
            error: PropTypes.string,
            value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.bool,
            ]),
            onChange: PropTypes.func.isRequired,
        }))
    }),
    getProfile: PropTypes.func,
    profile: PropTypes.object,
    location: PropTypes.object,
    isSaved: PropTypes.bool.isRequired,
    save: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
};

export default withRouter(ConsumerProfile);