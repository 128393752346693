exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-components-dropdown-styles--select {\n  font-size: 1rem;\n  border: 1px solid #ebebeb;\n  background-color: transparent;\n  padding: 0.85rem;\n  width: 100%;\n  -webkit-appearance: none;\n  -moz-appearance: none;\n  appearance: none;\n  /* remove default arrow */\n  margin: auto; }\n  .src-components-dropdown-styles--select.src-components-dropdown-styles--hasError {\n    border-color: #d9534f; }\n\n.src-components-dropdown-styles--icon {\n  position: absolute;\n  top: 40%;\n  right: 1rem;\n  pointer-events: none; }\n", ""]);

// exports
exports.locals = {
	"select": "src-components-dropdown-styles--select",
	"hasError": "src-components-dropdown-styles--hasError",
	"icon": "src-components-dropdown-styles--icon"
};