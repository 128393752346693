exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-pages-signup-styles--wrapper {\n  padding: 2rem 4rem 2rem 4rem; }\n\n@media (min-width: 320px) and (max-width: 767px) {\n  .src-pages-signup-styles--wrapper {\n    padding: 1.5rem; } }\n\n.src-pages-signup-styles--button {\n  margin-top: 2rem; }\n\n.src-pages-signup-styles--subtext {\n  margin-top: 2rem !important; }\n\n.src-pages-signup-styles--has-error {\n  background-color: #ff0000; }\n", ""]);

// exports
exports.locals = {
	"wrapper": "src-pages-signup-styles--wrapper",
	"button": "src-pages-signup-styles--button",
	"subtext": "src-pages-signup-styles--subtext",
	"has-error": "src-pages-signup-styles--has-error"
};