import React, { Component } from 'react';
import { withRouter } from 'react-router';




class ProfileDetails extends Component {
   constructor(props){
       super(props)
       this.state = {
           companyName: 'Jerrys Liquor',
           business: ' Distributor Brewery',
           address: '1000 S wacker',
           city: 'Chicago',
           state: 'Illinois',
           zipcode: '60628',
           county: 'Cook',
           territories: 'Cook County, Kane County, Lake County',
           email: 'jbony@icstars.org'


       }
   }

setCompanyName(e) {
  this.setState({
      companyName: e.target.value
  })
}

setBusinessType(e) {
  this.setState({
      business: e.target.value
  })
}

setAddress(e) {
    this.setState({
        address: e.target.value
    })
  }

  setCity(e) {
    this.setState({
        city: e.target.value
    })
  }

  changeState(e) {
    this.setState({
        state: e.target.value
    })
  }

  setZipCode(e) {
    this.setState({
        zipcode: e.target.value
    })
  }

  setCounty(e) {
    this.setState({
        county: e.target.value
    })
  }

  setTerritories(e) {
    this.setState({
        territories: e.target.value
    })
  }


  setEmail(e) {
    this.setState({
        email: e.target.value
    })
  }

  


render(){


    
return (
<div style={{marginLeft: '40px', marginRight: '40px', marginTop: '30px'}} >
          <div className="columns ">
              <div className="column is-half ">
            <form>
                <label>Company Name:</label>
                <input  className="input is-medium" 
                value={this.state.companyName} 
                type="text"
                style={{marginTop: '1rem'}} 
                onChange={(e) =>this.setCompanyName(e)}
             />
                 <p style={{marginTop: '10px'}}>Business Type:</p>
                <input className="input is-medium" type="text"
                value={this.state.business}
                style={{marginTop: '1rem'}} 
                  onChange={(e) => this.setBusinessType(e)}
                />

                
                <p style={{marginTop: '10px'}} >Address:</p>
                <input className="input  is-medium" type="text"
                value={this.state.address}
                style={{marginTop: '1rem'}} 
                onChange={(e) => this.setAddress(e)}
                />
                <p style={{marginTop: '10px'}} >City:</p>
                <input className="input is-medium" type="text"
                value={this.state.city}
                style={{marginTop: '1rem'}} 
                onChange={(e) => this.setCity(e)}
                />

                <p style={{marginTop: '10px'}} >State: </p>
                <input className="input is-medium" type="text"
                value={this.state.state}
                style={{marginTop: '1rem'}} 
                onChange={(e) => this.changeState(e)}
                />

                <p style={{marginTop: '10px'}} >Zip Code: </p>
                <input className="input is-medium" type="text"
                value={this.state.zipcode}
                style={{marginTop: '1rem'}} 
                onChange={(e) => this.setZipCode(e)}
                />

                <p style={{marginTop: '10px'}} > County: </p>
                <input className="input is-medium" type="text"
                value={this.state.county}
                style={{marginTop: '1rem'}} 
                onChange={(e) => this.setCounty(e)}
                />

                <p style={{marginTop: '10px'}} > Territories: </p>
                <input className="input is-medium" type="text"
                value={this.state.territories}
                style={{marginTop: '1rem'}}
                onChange={(e) => this.setTerritories(e)} 
                />
        

                <p style={{marginTop: '10px'}} > Email: </p>
                <input className="input is-medium" type="text"
                value={this.state.email}
                style={{marginTop: '1rem'}} 
                onChange={(e) => this.setEmail(e)}
                />

            <input type="submit" className="button is-fullwidth"
            style={{marginTop: '3rem'}} 
             value="Save Changes"/>
            </form>
            </div>

            <div className="column is-half" style={{ marginLeft: '45px', marginTop: '25px'}}>
              <h2 className="title" >Distributor</h2>
              <h2 className="title" >Jerry's Liqour</h2>
              <p className="sub-title">jbony@icstars.org</p>
              <img src={require('../../images/distrib-increase.png')} />
            </div>
            </div>

           
</div>

// </div>

)
}
}

export default withRouter(ProfileDetails);