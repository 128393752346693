import { connect } from 'react-redux';

import Component from './component';
import Commands from 'state/commands';
import fromState from 'state/selectors';

function mapStateToProps(state) {
    return {
        regions: fromState.Catalog.catalogRegions()(state),
        categories: fromState.Catalog.catalogCategories()(state),
        productTypes: fromState.Catalog.productTypes()(state),
        subTypes: fromState.Catalog.subTypes()(state),
        subRegions: fromState.Catalog.subRegions()(state),
        packagingTypes: fromState.Catalog.packagingTypes()(state),
        productDetails: fromState.Catalog.productDetails()(state),
        productLinks: fromState.Catalog.productLinks()(state),
        productVariants: fromState.Catalog.productVariants()(state),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        editProduct: (productObj) => dispatch(Commands.Catalog.EditProduct(productObj)),
        updateProduct: (productObj, productId) => dispatch(Commands.Catalog.UpdateProduct(productObj, productId)),
        addProductVariant: (productId, storeItemObj) => dispatch(Commands.Catalog.AddProductVariant(productId, storeItemObj)),
        deleteProduct: (productId) => dispatch(Commands.Catalog.DeleteProduct(productId)),
        deleteProductVariant: (productId, storeItemId) => dispatch(Commands.Catalog.DeleteProductVariant(productId, storeItemId)),
        addProductLink: (productId, linkObj) => dispatch(Commands.Catalog.AddProductLink(productId, linkObj)),
        deleteProductLink: (productId, linkId) => dispatch(Commands.Catalog.DeleteProductLink(productId, linkId)),
        loadRegions: () => dispatch(Commands.Catalog.LoadRegions()),
        loadCatalogCategories: () => dispatch(Commands.Catalog.LoadCatalogCategories()),
        loadCatalogCategoryProductTypes: (categoryId) => dispatch(Commands.Catalog.LoadCatalogCategoryProductTypes(categoryId)),
        loadProductDetails: (productId) => dispatch(Commands.Catalog.LoadProductDetails(productId)),
        loadProductLinks: (productId) => dispatch(Commands.Catalog.LoadProductLinks(productId)), 
        loadProductVariants: (productId) => dispatch(Commands.Catalog.LoadProductVariants(productId)), 
        loadSubTypes: (categoryId, typeId) => dispatch(Commands.Catalog.LoadSubTypes(categoryId, typeId)), 
        loadSubRegions: (id) => dispatch(Commands.Catalog.LoadSubRegions(id)), 
        loadPackagingTypes: (id) => dispatch(Commands.Catalog.LoadPackagingTypes(id)),   
        uploadImage: (productId, file) => dispatch(Commands.Catalog.UploadImage(productId, file)),     
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
