import { connect } from 'react-redux';

import Component from './component';
import fromState from 'state/selectors';

function mapStateToProps(state) {
    return {
        usertype: fromState.Authentication.currentUsertype()(state),
        company_id: fromState.Authentication.companyId()(state),
        profile: fromState.Profile.profile()(state),
    };
}

export default connect(mapStateToProps)(Component);