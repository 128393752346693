import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles.scss';
import Checkbox from '../../components/checkbox';
import DropDown from '../../components/dropdown';
import TextField from '../../components/text-field';
import {createPropTypesObject, getFieldProperties} from '../../utils/component';
import get from 'lodash/get';
import { withRouter } from 'react-router';
import { isMobileOnly, isTablet,  isBrowser} from 'react-device-detect';


const typesOfBusiness = [
    [0, 'We are a distributor.'],
    [1, 'We are a brewery and produce products.'],
    [2, 'We are a distillery and produce products.'],
    [3, 'We are a winery and produce products.'],
    [4, 'Other']
];


class DistributorProfile extends React.Component {

    constructor(props){
        super(props); 
        this.state = {
            selectedOptions: null,
            statesList: [],
            counties: [],
            isAddingRep: false,
            isEditing: false,
        };   
        this.handleChange = this.handleChange.bind(this);
        this.buildArray = this.buildArray.bind(this);
        this.toggleRep = this.toggleRep.bind(this);
        this.newRep = this.newRep.bind(this);
        this.postRep = this.postRep.bind(this);
    }

    componentDidMount(){
        if(this.props.location.state != null) {
            this.setState({
                isEditing: true,
            });
            this.props.getProfile();
        }
    }

    componentWillAmount(){
        this.setState({
            isEditing: false,
        });
    }

    handleChange(selectedOptions){
        this.setState({ selectedOptions: selectedOptions, },
            () => {this.buildArray(); });
    }

    newRep(repObject){
        this.setState({
            repObject: repObject,
        });
    }

    buildArray(){
        const statesArray=[];
        const states = this.state.selectedOptions;
        for(let i=states.length-1; i >= 0; i--) {
            statesArray.push(states[i].value);
        }
        this.setState({
            statesList: statesArray,
        }, () => { this.loadCounties(statesArray);});
    }

    loadCounties(states) {
        const arr = Array.prototype.slice.call(states);
        this.props.loadCounties(arr).then(() =>  {
            this.setState({ counties: this.props.counties } );
        });
    }

    toggleRep(){
        if(!this.state.isAddingRep) {
            this.setState({
                isAddingRep: true,
            });
        } else {
            this.addRep();
        }
    }

    addRep(){
        this.setState({
            isAddingRep: false,
        });
    }

    postRep(){
        this.props.postRep(this.state.repObject);
    }

    render() {
        const { save, } = this.props;
        const { isEditing, } = this.state;
        const fields = get(this.props, 'fields', {});
        const agreed = getFieldProperties(fields, 'agreed');
        const businessType = getFieldProperties(fields, 'businessType');
        const companyName = getFieldProperties(fields, 'companyName');
        const address = getFieldProperties(fields, 'address');
        const address2 = getFieldProperties(fields, 'address2');
        const city = getFieldProperties(fields, 'city');
        const state = getFieldProperties(fields, 'state');
        const zip = getFieldProperties(fields, 'zip');
        const website = getFieldProperties(fields, 'website');
        const ein = getFieldProperties(fields, 'ein');
        const fullName = getFieldProperties(fields, 'fullName');
        const phone = getFieldProperties(fields, 'phone');
        const counties = getFieldProperties(fields, 'counties');
        const countyOptions = get(this.props, 'counties', []).map(county => [get(county, 'name'), get(county, 'name')]);
        const stateOptions = get(this.props, 'statesList', []).map(state => [get(state, 'value'), get(state, 'label')]);
        return (
            <div>
                <section className='section profile-form' style={{padding: isBrowser ? '3rem' : '1rem'}}>
                    <div className='columns'>
                        <div className='column is-two-thirds'>
                            <div className='is-size-3 title'>Complete Your Profile</div>
                            <div className='is-size-4 title'>Business Information</div>
                            <DropDown
                                {...businessType}
                                options={typesOfBusiness}
                                placeholder='Select business type'
                            />
                            <TextField {...companyName} labelText='Company Name:'/>
                            <TextField {...address} labelText='Street Address:'/>
                            <TextField {...address2} labelText='Address 2:'/>
                            <div className='columns' style={{marginBottom: 0}}>
                                <div className='column is-7'>
                                    <TextField {...city} labelText='City:'/>
                                </div>
                                <div className='column is-2'>
                                    <div className='is-size-6' style={{marginBottom: '.5rem'}}>State:</div>
                                    <DropDown 
                                        
                                        {...state}
                                        options={stateOptions}
                                        placeholder='Select' />
                                </div>
                                <div className='column is-3'>
                                    <TextField {...zip} labelText='Zipcode:'/>
                                </div>
                            </div>
                            <DropDown
                                {...counties}
                                options={countyOptions}
                                placeholder='County Name'
                            />
                            <TextField {...website} labelText='Website URL:'/>
                            <TextField {...ein} labelText='EIN: (Optional)'/>
                            <div className={styles.vertSpacer}/>
                            <div className='is-size-4 title'>Contact Information</div>
                            <TextField {...fullName} labelText='Full Name:'/>
                            { /* <TextField {...email} labelText='Contact Email Address:'/> */}
                            <TextField {...phone} labelText='Contact Phone Number:'/>
                            <label className={styles.container}>
                                <Checkbox {...agreed}/>
                                <span 
                                    onClick={()=> window.open('/terms-and-conditions', '_blank')}
                                    className={'is-size-6 is-size-7-mobile' + (agreed.error ? 'has-text-danger' : '')}
                                    style={{padding: '1rem 0 1rem .4rem',}}>
                                    <a>I agree to BuyFluidly&#39;s terms and conditions</a>
                                    <span style={{marginLeft: '2rem', color: 'red',}} className='is-size-7'>{agreed.error && agreed.error}</span>
                                </span>
                                <span className={styles.checkmark}></span>

                            </label>
                            <div className='columns'>
                                <div className='column is-9' style={{marginTop: '1rem'}}>
                                    <input className='button is-fullwidth'
                                        type='submit'
                                        value='Continue'
                                        onClick={() => {

                                            save().then(result => {
                                                if(get(result, 'type') === 'profile-save-profile-succeeded') {
                                                    this.props.history.push(
                                                        { pathname: '/distributor-profile-reps', state: { isEditing: isEditing }, });
                                                }
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='column' />
                    </div>
                </section>
            </div>
        );
    }

}

DistributorProfile.propTypes = {
    fields: PropTypes.shape({
        ...createPropTypesObject([
            'address',
            'agreed',
            'businessType',
            'companyName',
            'city',
            'state',
            'zip',
            'website',
            'ein',
            'fullName',
            'phone',
            'state',
            'county',
            'email',
        ], PropTypes.shape({
            error: PropTypes.string,
            value: PropTypes.string,
            onChange: PropTypes.func.isRequired,
        }))
    }),
    location: PropTypes.object,
    isSaved: PropTypes.bool,
    save: PropTypes.func,
    handleChange: PropTypes.func,
    loadCounties: PropTypes.func,
    postRep: PropTypes.func,
    statesList: PropTypes.array,
    counties: PropTypes.array,
    isAddingRep: PropTypes.bool,
    history: PropTypes.object.isRequired,
};

export default withRouter(DistributorProfile);

