import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styles from './styles.scss';

function TextField({onChange, value, hasError, labelText, error, name, press}) {
    
    return (
        <div style={{marginBottom: '1rem'}}>
            <span className='is-size-6'>{labelText} { hasError && <span className="is-size-7 has-text-danger">{error}</span> }</span>
            <input name={name} type={'text'} style={{padding:'.5rem .5rem .5rem 1rem', marginTop: '.5rem'}} 
                value={value} className={classnames('input', 'is-medium', 
                    (hasError) ? styles.error : '')} onChange={v => onChange(v.target.value)}
                onKeyPress={press}/>
        </div>
    );
}

TextField.propTypes = {
    onChange: PropTypes.func,
    childChange: PropTypes.func,
    error: PropTypes.string,
    labelText: PropTypes.string,
    press: PropTypes.func,
    type: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    className: PropTypes.string,
    hasError: PropTypes.bool,
};

export default TextField;