import {types as EventTypes} from '../events';
import basicReducer from '../../common/reducers/basic-reducer';

import constant from 'lodash/constant';

const initialState = '';

const reductionLookup = {
    [EventTypes.LoggedOut]: constant(initialState),
    [EventTypes.AuthenticationRequested]: constant(initialState),
    [EventTypes.AuthenticationFailed]: constant(initialState),
    [EventTypes.AuthenticationSucceeded]: (_, {username}) => username,
};

export default basicReducer(initialState, reductionLookup);
