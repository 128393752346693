import React from 'react';
import TextInput from '../../components/text-input';
import PropTypes from 'prop-types';

export default class AddRepForm extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            contactName: '',
            contactPhone: '',
            contactEmail: '',
            errorMessage: '',
        };
        this.errorClass = this.errorClass.bind(this);
    }


    handleUserInput (e) {
        const emailRegex = new RegExp(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        const name = e.target.name;
        const value = e.target.value;
        this.setState({[name]: value,}, () => {
            this.props.addRep({ 
                contactName: this.state.contactName, 
                contactPhone: this.state.contactPhone,
                contactEmail: this.state.contactEmail,});
        });
        if(name === 'contactEmail') {
            this.setState({ errorMessage: emailRegex.test(value) ? '' : 'A valid email address is required', });
        }
    }

    errorClass() {
        return( this.state.errorMessage.length === 0 ? '' : 'has-error');
    }


    render() {

        return (
            <div style={{marginTop: '3rem',}}>
                <div className='is-size-4 title'>Add Rep Contact Info</div>
                <TextInput labelText='Full Name' fieldName="contactName" childChange={(event) => this.handleUserInput(event)}/>
                <TextInput labelText='Contact Phone Number' fieldName="contactPhone" childChange={(event) => this.handleUserInput(event)}/>
                <TextInput 
                    labelText='Email' 
                    fieldName="contactEmail" 
                    childChange={(event) => this.handleUserInput(event)}
                    className={`${this.errorClass()}`}  
                    errorMessage={this.state.errorMessage}/>
            </div>
        );
    }
}

AddRepForm.propTypes = {
    addRep: PropTypes.func,
};