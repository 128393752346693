import {types as EventTypes} from '../events';
import basicReducer from '../../common/reducers/basic-reducer';

import constant from 'lodash/constant';

const initialState = '';

const reductionLookup = {
    [EventTypes.LoggedOut]: constant(initialState),
    [EventTypes.RefreshTokenTimoutIdCreated]: (_, id) => id,
};

export default basicReducer(initialState, reductionLookup);
