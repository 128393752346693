import {types as EventTypes} from '../events';
import basicReducer from '../../common/reducers/basic-reducer';
import {types as AuthEventTypes} from '../../authentication/events';
import constant from 'lodash/constant';

const initialState = [];

const reductionLookup = {
    [AuthEventTypes.LoggedOut]: constant(initialState),
    [EventTypes.SubTypesLoading]: constant(initialState),
    [EventTypes.LoadSubTypesFailed]: constant(initialState),
    [EventTypes.LoadSubTypesSucceeded]: (_, {categoryId, typeId, subTypes}) => ({categoryId, typeId, types: subTypes}),
};

export default basicReducer(initialState, reductionLookup);
