import Events from '../events';
import fromState from 'state/selectors';
import get from 'lodash/get';
import some from 'lodash/some';
import inRange from 'lodash/inRange';

const CloneImage = (productId, toProductID) => (dispatch, getState, api) => {
    const userToken = fromState.Authentication.currentToken()(getState());
    const impersonationString = fromState.Admin.impersonationString()(getState());           
    return api.cloneImage(userToken, productId, toProductID, impersonationString).then(result => {

        const cloneImageResult = get(result, 'with');
        if (cloneImageResult === 401) {
            return dispatch(Events.CloneImageFailed('Unauthorized.'));
        } else if (cloneImageResult === 404) {
            return dispatch(Events.CloneImageFailed('Error cloning image'));
        } else if (cloneImageResult === 500) {
            return dispatch(Events.CloneImageFailed('An unknown error occurred when attempting to clone image.'));
        }

        return dispatch(Events.CloneImageSucceeded(cloneImageResult));
    }, 
    error => dispatch(Events.CloneImageFailed(error)));
};

const UploadImage = (productId, file) => (dispatch, getState, api) => {
    dispatch(Events.UploadImageStarted());

    const userToken = fromState.Authentication.currentToken()(getState());
    return api.uploadImage(userToken, productId, file).then(result => {
        const uploadImageResult = get(result, 'with');
        if (uploadImageResult === 401) {
            return dispatch(Events.UploadImageFailed('Unauthorized.'));
        } else if (uploadImageResult === 404) {
            return dispatch(Events.UploadImageFailed('Not Found.'));
        } else if (uploadImageResult === 500) {
            return dispatch(Events.UploadImageFailed('An unknown error occurred when uploading image.'));
        }
        
        return dispatch(Events.UploadImageSucceeded(uploadImageResult));
    }, 
    error => dispatch(Events.UploadImageFailed(error)));
};

const GetProductSuggestions = (searchString) => (dispatch, getState, api) => {
    
    const userToken = fromState.Authentication.currentToken()(getState());
    const impersonationString = fromState.Admin.impersonationString()(getState());    
    return api.getProductSuggestions(userToken, searchString, impersonationString).then(result => {
        const productSuggestionsResult = get(result, 'with', []);
        if (productSuggestionsResult === 401) {
            return dispatch(Events.GetProductSuggestionsFailed('Unauthorized.'));
        } else if (productSuggestionsResult === 404) {
            return dispatch(Events.GetProductSuggestionsFailed('Suggestions Not Found.'));
        } else if (productSuggestionsResult === 500) {
            return dispatch(Events.GetProductSuggestionsFailed('An unknown error occurred when attempting to get product suggestions.'));
        }
        return dispatch(Events.GetProductSuggestionsSucceeded(productSuggestionsResult));
    }, 
    error => dispatch(Events.GetProductSuggestionsFailed(error)));
};

const LoadProductVariants = (productId) => (dispatch, getState, api) => {
    dispatch(Events.ProductVariantsLoading());

    const userToken = fromState.Authentication.currentToken()(getState());
    const impersonationString = fromState.Admin.impersonationString()(getState());       
    return api.getProductVariants(userToken, productId, impersonationString).then(result => {
        const productVariantsResult = get(result, 'with');
        if (productVariantsResult === 401) {
            return dispatch(Events.LoadProductVariantsFailed('Unauthorized.'));
        } else if (productVariantsResult === 404) {
            return dispatch(Events.LoadProductVariantsFailed('Product Variants Not Found.'));
        } else if (productVariantsResult === 500) {
            return dispatch(Events.LoadProductVariantsFailed('An unknown error occurred when attempting to get product variants.'));
        }
        return dispatch(Events.LoadProductVariantsSucceeded(productVariantsResult));
    }, 
    error => dispatch(Events.LoadProductVariantsFailed(error)));
};

const UpdateProduct = (productObj, productId) => (dispatch, getState, api) => {
    dispatch(Events.UpdateProductStarted());
    
    const userToken = fromState.Authentication.currentToken()(getState());
    const impersonationString = fromState.Admin.impersonationString()(getState());           
    return api.updateProduct(userToken, productObj, productId, impersonationString).then(result => {

        const productEditResult = get(result, 'with');
        if (productEditResult === 401) {
            return dispatch(Events.UpdateProductFailed('Unauthorized.'));
        } else if (productEditResult === 404) {
            return dispatch(Events.UpdateProductFailed('Product Not Found.'));
        } else if (productEditResult === 500) {
            return dispatch(Events.UpdateProductFailed('An unknown error occurred when attempting to edit product.'));
        }

        return dispatch(Events.UpdateProductSucceeded(productEditResult));
    }, 
    error => dispatch(Events.UpdateProductFailed(error)));
};

const DeleteProduct = (productId) => (dispatch, getState, api) => {
    dispatch(Events.DeleteProductStarted());

    const userToken = fromState.Authentication.currentToken()(getState());
    const impersonationString = fromState.Admin.impersonationString()(getState());        
    return api.deleteProduct(userToken, productId, impersonationString).then(result => {

        const deleteProductResult = get(result, 'statusCode');
        if (deleteProductResult === 401) {
            return dispatch(Events.DeleteProductFailed('Unauthorized.'));
        } else if (deleteProductResult === 404) {
            return dispatch(Events.DeleteProductFailed('Product Not Found.'));
        } else if (deleteProductResult === 500) {
            return dispatch(Events.DeleteProductFailed('An unknown error occurred when attempting to delete product.'));
        }
        dispatch(SearchCatalogProducts(-1, ''));
        return dispatch(Events.DeleteProductSucceeded(deleteProductResult));
    }, 
    error => dispatch(Events.DeleteProductFailed(error)));
};

const AddUpdateProduct = (productObj) => (dispatch, getState, api) => {
    // doing this to remove nulls or undefined values...
    const product = JSON.stringify(productObj);
    const newObj = JSON.parse(product);
    dispatch(Events.AddUpdateProductStarted());

    const userToken = fromState.Authentication.currentToken()(getState());
    const impersonationString = fromState.Admin.impersonationString()(getState());     
    return api.addUpdateProduct(userToken, newObj, impersonationString).then(result => {
        const addUpdateProductResult = get(result, 'with');
        if (addUpdateProductResult === 401) {
            return dispatch(Events.AddUpdateProductFailed('Unauthorized.'));
        } else if (addUpdateProductResult === 404) {
            return dispatch(Events.AddUpdateProductFailed('Product Not Found.'));
        } else if (addUpdateProductResult === 500) {
            return dispatch(Events.AddUpdateProductFailed('An unknown error occurred when attempting to add product.'));
        }
        dispatch(SearchCatalogProducts(-1, ''));
        return dispatch(Events.AddUpdateProductSucceeded(addUpdateProductResult));
    }, 
    error => dispatch(Events.AddUpdateProductFailed(error)));
};

const AddProductVariant = (productId, storeItemObj) => (dispatch, getState, api) => {
    dispatch(Events.AddProductVariantStarted());
    const userToken = fromState.Authentication.currentToken()(getState()); 
    const impersonationString = fromState.Admin.impersonationString()(getState());        
    return api.addProductVariant(userToken, productId, storeItemObj, impersonationString).then(() => {
        return dispatch(LoadProductVariants(productId));
    }, 
    error => dispatch(Events.AddProductVariantFailed(error)));
};

const DeleteProductVariant = (productId, storeItemId) => (dispatch, getState, api) => {
    dispatch(Events.DeleteProductVariantStarted());

    const userToken = fromState.Authentication.currentToken()(getState()); 
    const impersonationString = fromState.Admin.impersonationString()(getState());     
    return api.deleteProductVariant(userToken, productId, storeItemId, impersonationString).then(() => {
        return dispatch(AddProductVariant(productId));
    }, 
    error => dispatch(Events.DeleteProductVariantFailed(error)));
};

const LoadProductDetails = (productId) => (dispatch, getState, api) => {
    dispatch(Events.ProductDetailsLoading());

    const userToken = fromState.Authentication.currentToken()(getState());    
    const impersonationString = fromState.Admin.impersonationString()(getState());     
    return api.getProductDetails(userToken, productId, impersonationString).then(result => {
        const productDetailsResult = get(result, 'with');
        
        if (productDetailsResult === 401) {
            return dispatch(Events.LoadProductDetailsFailed('Unauthorized.'));
        } else if (productDetailsResult === 404) {
            return dispatch(Events.LoadProductDetailsFailed('Categories Not Found.'));
        } else if (productDetailsResult === 500) {
            return dispatch(Events.LoadProductDetailsFailed('An unknown error occurred when attempting to get product details.'));
        }
        
        return dispatch(Events.LoadProductDetailsSucceeded(productDetailsResult));
    }, 
    error => dispatch(Events.LoadProductDetailsFailed(error)));
};

const AddProductLink = (productId, linkObj) => (dispatch, getState, api) => {
    dispatch(Events.ProductLinkAdding());

    const userToken = fromState.Authentication.currentToken()(getState());    
    const impersonationString = fromState.Admin.impersonationString()(getState());     
    return api.addProductLink(userToken, productId, linkObj, impersonationString).then(() => {
        return dispatch(LoadProductLinks(productId));
    }, 
    error => dispatch(Events.AddProductLinkFailed(error)));
};

const DeleteProductLink = (productId, linkId) => (dispatch, getState, api) => {
    dispatch(Events.ProductLinkDeleting());

    const userToken = fromState.Authentication.currentToken()(getState());    
    const impersonationString = fromState.Admin.impersonationString()(getState());   
    return api.deleteProductLink(userToken, productId, linkId, impersonationString).then(() => {
        return dispatch(LoadProductLinks(productId));
    }, 
    error => dispatch(Events.DeleteProductLinkFailed(error)));
};

const LoadProductLinks = (productId) => (dispatch, getState, api) => {
    dispatch(Events.ProductLinksLoading());

    const userToken = fromState.Authentication.currentToken()(getState());    
    const impersonationString = fromState.Admin.impersonationString()(getState()); 
    return api.getProductLinks(userToken, productId, impersonationString).then(result => {
        const productLinksResult = get(result, 'with');
        
        if (productLinksResult === 401) {
            return dispatch(Events.LoadProductLinksFailed('Unauthorized.'));
        } else if (productLinksResult === 404) {
            return dispatch(Events.LoadProductLinksFailed('Categories Not Found.'));
        } else if (productLinksResult === 500) {
            return dispatch(Events.LoadProductLinksFailed('An unknown error occurred when attempting to get product links.'));
        }
        
        return dispatch(Events.LoadProductLinksSucceeded(productLinksResult));
    }, 
    error => dispatch(Events.LoadProductLinksFailed(error)));
};

const LoadDistributors = () => (dispatch, getState, api) => {

    const userToken = fromState.Authentication.currentToken()(getState());    
    const impersonationString = fromState.Admin.impersonationString()(getState()); 
    return api.loadDistributors(userToken, impersonationString).then(result => {
        const distributorsResult = get(result, 'with');
        
        if (distributorsResult === 401) {
            return dispatch(Events.LoadDistributorsFailed('Unauthorized.'));
        } else if (distributorsResult === 404) {
            return dispatch(Events.LoadDistributorsFailed('Distributors Not Found.'));
        } else if (distributorsResult === 500) {
            return dispatch(Events.LoadDistributorsFailed('An unknown error occurred when attempting to get distributors.'));
        }
        
        return dispatch(Events.LoadDistributorsSucceeded(distributorsResult));
    }, 
    error => dispatch(Events.LoadDistributorsFailed(error)));
};

const LoadCatalogBrands = () => (dispatch, getState, api) => {
    dispatch(Events.CatalogBrandsLoading());

    const userToken = fromState.Authentication.currentToken()(getState());    
    const impersonationString = fromState.Admin.impersonationString()(getState()); 
    return api.getCatalogBrands(userToken, impersonationString).then(result => {
        const catalogBrandsResult = get(result, 'with');
        
        for (let i = catalogBrandsResult.length - 1; i >= 0; i--) {
            catalogBrandsResult[i].title = catalogBrandsResult[i]['name'];
            delete catalogBrandsResult[i].name;
        }

        if (catalogBrandsResult === 401) {
            return dispatch(Events.LoadCatalogBrandsFailed('Unauthorized.'));
        } else if (catalogBrandsResult === 404) {
            return dispatch(Events.LoadCatalogBrandsFailed('Brands Not Found.'));
        } else if (catalogBrandsResult === 500) {
            return dispatch(Events.LoadCatalogBrandsFailed('An unknown error occurred when attempting to get brands.'));
        }
        
        return dispatch(Events.LoadCatalogBrandsSucceeded(catalogBrandsResult));
    }, 
    error => dispatch(Events.LoadCatalogBrandsFailed(error)));
};

const SearchCatalogProducts = (distId, name, brandId, categoryId, typeId, subTypeId, regionId, subRegionId, packagingTypeId, minPrice = 0, maxPrice = 999999999) => (dispatch, getState, api) => {
    dispatch(Events.CatalogProductsLoading());
    const userToken = fromState.Authentication.currentToken()(getState());    
    const impersonationString = fromState.Admin.impersonationString()(getState()); 
    const showNew = fromState.Catalog.productsShowingNew()(getState()); 
    return api.searchCatalogProducts(userToken, distId, name, brandId, categoryId, typeId, subTypeId, regionId, subRegionId, packagingTypeId, minPrice, maxPrice, showNew, impersonationString).then(result => {
        const catalogProductsResult = get(result, 'with', []);
        
        if (catalogProductsResult === 401) {
            return dispatch(Events.SearchCatalogProductsFailed('Unauthorized.'));
        } else if (catalogProductsResult === 404) {
            return dispatch(Events.SearchCatalogProductsFailed('Products Not Found.'));
        } else if (catalogProductsResult === 500) {
            return dispatch(Events.SearchCatalogProductsFailed('An unknown error occurred when attempting to get products.'));
        }

        const retailerType = fromState.Authentication.retailerType()(getState()); 
        const userType = fromState.Authentication.currentUsertype()(getState());   
        // userType 1 = retailer, 2 = distributor
        //retailerType 0 = onPremise, 1 = offPremise
        if(userType === '1' && catalogProductsResult != null) {
            const filteredArray = retailerType ==='0' ? 
                catalogProductsResult.filter(element => some(element.availablePackages, item => inRange(item.onPremisePrice, minPrice, maxPrice))) :
                catalogProductsResult.filter(element => some(element.availablePackages, item => inRange(item.offPremisePrice, minPrice, maxPrice)));
            return dispatch(Events.SearchCatalogProductsSucceeded(filteredArray));
        } else {
            return dispatch(Events.SearchCatalogProductsSucceeded(catalogProductsResult));
        }
    }, 
    error => dispatch(Events.SearchCatalogProductsFailed(error)));
};

const LoadCatalogCategories = () => (dispatch, getState, api) => {
    dispatch(Events.CatalogCategoriesLoading());

    const userToken = fromState.Authentication.currentToken()(getState());    
    const impersonationString = fromState.Admin.impersonationString()(getState()); 
    return api.getCatalogCategories(userToken, impersonationString).then(result => {
        const catalogCategoriesResult = get(result, 'with');
        
        for (let i = catalogCategoriesResult.length - 1; i >= 0; i--) {
            catalogCategoriesResult[i].title = catalogCategoriesResult[i]['name'];
            delete catalogCategoriesResult[i].name;
        }

        if (catalogCategoriesResult === 401) {
            return dispatch(Events.LoadCatalogCategoriesFailed('Unauthorized.'));
        } else if (catalogCategoriesResult === 404) {
            return dispatch(Events.LoadCatalogCategoriesFailed('Categories Not Found.'));
        } else if (catalogCategoriesResult === 500) {
            return dispatch(Events.LoadCatalogCategoriesFailed('An unknown error occurred when attempting to get catalog categories.'));
        }
        return dispatch(Events.LoadCatalogCategoriesSucceeded(catalogCategoriesResult));
    }, 
    error => dispatch(Events.LoadCatalogCategoriesFailed(error)));
};

const LoadCatalogCategoryProductTypes = (categoryId) => (dispatch, getState, api) => {
    dispatch(Events.CatalogCategoryProductTypesLoading());

    const userToken = fromState.Authentication.currentToken()(getState());
    const impersonationString = fromState.Admin.impersonationString()(getState()); 
    return api.getProductTypesForCatalogCategory(userToken, categoryId, impersonationString).then(result => {
        const productTypesResult = get(result, 'with');

        for (let i = productTypesResult.length - 1; i >= 0; i--) {
            productTypesResult[i].title = productTypesResult[i]['name'];
            delete productTypesResult[i].name;
        }
        
        if (productTypesResult === 401) {
            return dispatch(Events.LoadCatalogCategoryProductTypesFailed('Unauthorized.'));
        } else if (productTypesResult === 404) {
            return dispatch(Events.LoadCatalogCategoryProductTypesFailed('Product Types Not Found.'));
        } else if (productTypesResult === 500) {
            return dispatch(Events.LoadCatalogCategoryProductTypesFailed('An unknown error occurred when attempting to get catalog categories.'));
        }

        return dispatch(Events.LoadCatalogCategoryProductTypesSucceeded(categoryId, productTypesResult));
    }, 
    error => dispatch(Events.LoadCatalogCategoryProductTypesFailed(error)));
};

const LoadSubTypes = (typeId, categoryId) => (dispatch, getState, api) => {
    dispatch(Events.SubTypesLoading());

    const userToken = fromState.Authentication.currentToken()(getState());
    const impersonationString = fromState.Admin.impersonationString()(getState());
    return api.getSubTypes(userToken, typeId, categoryId, impersonationString).then(result => {
        const productSubtypesResult = get(result, 'with');

        for (let i = productSubtypesResult.length - 1; i >= 0; i--) {
            productSubtypesResult[i].title = productSubtypesResult[i]['name'];
            delete productSubtypesResult[i].name;
        }
        
        if (productSubtypesResult === 401) {
            return dispatch(Events.LoadSubTypesFailed('Unauthorized.'));
        } else if (productSubtypesResult === 404) {
            return dispatch(Events.LoadSubTypesFailed('Product Types Not Found.'));
        } else if (productSubtypesResult === 500) {
            return dispatch(Events.LoadSubTypesFailed('An unknown error occurred when attempting to get catalog categories.'));
        }

        return dispatch(Events.LoadSubTypesSucceeded(typeId, categoryId, productSubtypesResult));
    }, 
    error => dispatch(Events.LoadSubTypesFailed(error)));
};

const LoadRegions = () => (dispatch, getState, api) => {
    dispatch(Events.RegionsLoading());

    const userToken = fromState.Authentication.currentToken()(getState());    
    const impersonationString = fromState.Admin.impersonationString()(getState());
    return api.getRegions(userToken, impersonationString).then(result => {
        const regionsResult = get(result, 'with');
        
        for (let i = regionsResult.length - 1; i >= 0; i--) {
            regionsResult[i].title = regionsResult[i]['name'];
            delete regionsResult[i].name;
        }

        if (regionsResult === 401) {
            return dispatch(Events.LoadRegionsFailed('Unauthorized.'));
        } else if (regionsResult === 404) {
            return dispatch(Events.LoadRegionsFailed('Categories Not Found.'));
        } else if (regionsResult === 500) {
            return dispatch(Events.LoadRegionsFailed('An unknown error occurred when attempting to get regions'));
        }
        return dispatch(Events.LoadRegionsSucceeded(regionsResult));
    }, 
    error => dispatch(Events.LoadRegionsFailed(error)));
};

const LoadSubRegions = (id) => (dispatch, getState, api) => {
    dispatch(Events.SubRegionsLoading());

    const userToken = fromState.Authentication.currentToken()(getState());
    const impersonationString = fromState.Admin.impersonationString()(getState());
    return api.getSubRegions(userToken, id, impersonationString).then(result => {
        const subregionsResult = get(result, 'with');

        for (let i = subregionsResult.length - 1; i >= 0; i--) {
            subregionsResult[i].title = subregionsResult[i]['name'];
            delete subregionsResult[i].name;
        }
        
        if (subregionsResult === 401) {
            return dispatch(Events.LoadSubRegionsFailed('Unauthorized.'));
        } else if (subregionsResult === 404) {
            return dispatch(Events.LoadSubRegionsFailed('Product Types Not Found.'));
        } else if (subregionsResult === 500) {
            return dispatch(Events.LoadSubRegionsFailed('An unknown error occurred when attempting to get sub regions.'));
        }

        return dispatch(Events.LoadSubRegionsSucceeded(subregionsResult));
    }, 
    error => dispatch(Events.LoadSubRegionsFailed(error)));
};

const LoadPackagingTypes = (id) => (dispatch, getState, api) => {
    dispatch(Events.PackagingTypesLoading());

    const userToken = fromState.Authentication.currentToken()(getState());    
    const impersonationString = fromState.Admin.impersonationString()(getState());
    return api.getPackagingTypes(userToken, id, impersonationString).then(result => {
        const packagingTypesResult = get(result, 'with');
        
        for (let i = packagingTypesResult.length - 1; i >= 0; i--) {
            packagingTypesResult[i].title = packagingTypesResult[i]['name'];
            delete packagingTypesResult[i].name;
        }

        if (packagingTypesResult === 401) {
            return dispatch(Events.LoadPackagingTypesFailed('Unauthorized.'));
        } else if (packagingTypesResult === 404) {
            return dispatch(Events.LoadPackagingTypesFailed('Packaging Types Not Found.'));
        } else if (packagingTypesResult === 500) {
            return dispatch(Events.LoadPackagingTypesFailed('An unknown error occurred when attempting to get packaging types.'));
        }
        
        return dispatch(Events.LoadPackagingTypesSucceeded(packagingTypesResult));
    }, 
    error => dispatch(Events.LoadPackagingTypesFailed(error)));
};

const storeActivePage = (value) => (dispatch) => {
    return dispatch(Events.StoreActivePageSucceeded(value));
};

const storeShowingNew = (value) => (dispatch) => {
    return dispatch(Events.StoreShowingNewSucceeded(value));
};

export default {
    AddUpdateProduct,
    AddProductLink,
    AddProductVariant,
    CloneImage,
    DeleteProduct,
    DeleteProductLink,
    DeleteProductVariant,
    GetProductSuggestions,
    LoadCatalogCategories,
    LoadCatalogCategoryProductTypes,
    LoadDistributors,
    LoadSubTypes,
    LoadRegions,
    LoadSubRegions,
    LoadProductDetails,
    LoadProductLinks,
    LoadPackagingTypes,
    LoadCatalogBrands,
    LoadProductVariants,
    SearchCatalogProducts,
    UpdateProduct,
    UploadImage,
    StoreActivePage: storeActivePage,
    StoreShowingNew: storeShowingNew,
};
