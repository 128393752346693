import {types as EventTypes} from '../events';
import basicReducer from '../../common/reducers/basic-reducer';

import constant from 'lodash/constant';
import get from 'lodash/get';

const initialState = {
    currentToken: '',
    refreshToken: '',
    expiresIn: 0,
};

const authenticationSucceeded = (_, {result}) => ({
    currentToken: get(result,'access_token', ''),
    refreshToken: get(result,'refresh_token', ''),
    expiresIn: get(result,'expires_in', 0)
});

const tokenRefreshed = (_, result) => ({
    currentToken: get(result,'access_token', ''),
    refreshToken: get(result,'refresh_token', ''),
    expiresIn: get(result,'expires_in', 0)
});

const reductionLookup = {
    [EventTypes.LoggedOut]: constant(initialState),
    
    [EventTypes.AuthenticationRequested]: constant(initialState),
    [EventTypes.AuthenticationFailed]: constant(initialState),
    [EventTypes.AuthenticationSucceeded]: authenticationSucceeded,
    
    [EventTypes.TokenRefreshSucceeded]: tokenRefreshed,
    [EventTypes.TokenRefreshFailed]: constant(initialState),
};

export default basicReducer(initialState, reductionLookup);
