import React, { Component } from 'react';
import { withRouter } from 'react-router';


class AboutUs extends Component {
    render(){

        const heading = {
            textAlign: 'center',
            fontSize: '35px',
            fontWeight: 'bold',
            marginTop: '30px'
        };

        const style1 = {
            textAlign: 'center',
            fontSize: '20px',
            padding: '2rem',
        };


        return (
            <div style={style1}>
                <h1 style={heading}>Beverage Supply Simplified!</h1>
                <p>Our goal at BuyFluidly is to simplify the beverage buying process for Retailers, Bars & Restaurants.  
                    We <br/> realized that there is a tremendous lack of visibility for the inventory available from Distributors and Producers,<br/> 
                    especially in the ever-changing beer & wine market.  Buyers want to know their options and have information <br/> such as descriptions, 
                    ratings and availability. <br/> We know how important it is to be carrying the perfect products to WOW your customers.
                </p>
                <br/>
                <h3 style={{fontWeight: 'bold'}}>Our Platform brings you the visibility that you need to buy fluidly.</h3> 
                <br/> 
                <br/>
                <h3 style={{fontSize: '30px', fontWeight: 'bold'}}>BuyFluidly.com </h3>
                <h3 style={{fontWeight: 'bold'}}>
                      Bringing visibility of distributor & producer product availability to <br/>
                      Restaurants, Bars & Retailers.
                </h3>
            </div>
        );
    }
}

export default withRouter(AboutUs);