exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-pages-consumer-order-details-styles--wrapper {\n  padding: 5rem 4rem; }\n  @media (min-width: 481px) and (max-width: 1023px) {\n    .src-pages-consumer-order-details-styles--wrapper {\n      padding: 2rem; } }\n  @media (min-width: 320px) and (max-width: 480px) {\n    .src-pages-consumer-order-details-styles--wrapper {\n      padding: 12px; } }\n", ""]);

// exports
exports.locals = {
	"wrapper": "src-pages-consumer-order-details-styles--wrapper"
};