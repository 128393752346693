import {compose, createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import reducers from 'state/reducers';
import AuthCommands from 'state/authentication/commands';
import {types as AuthEventTypes} from 'state/authentication/events';

import get from 'lodash/get';

const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const actionState = store => next => action => next({ ...action, getState: store.getState });

const refreshTokenListener = store => next => action => {
    if (get(action, 'type') === AuthEventTypes.RefreshTokenTimeoutTriggered) store.dispatch(AuthCommands.RefreshToken());
    return next(action);
};

const persistConfig = {
    key: 'root',
    storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export default (api) => {
    const store = createStore(
        persistedReducer,
        composeEnhancers(
            applyMiddleware(thunk.withExtraArgument(api), refreshTokenListener, actionState)
        )
    );
    const persistor = persistStore(store);

    return {store, persistor};
};
