import {combineReducers} from 'redux';
import admin from './admin/reducers';
import authentication from './authentication/reducers';
import cart from './cart/reducers';
import catalog from './catalog/reducers';
import profile from './profile/reducers';

export default combineReducers({
    admin,
    authentication,
    cart,
    catalog,
    profile,
});
