import Events from '../events';
import AuthEvents from '../../authentication/events';
import fromState from 'state/selectors';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { hasValue } from '../../../utils/field-validation';
import CatalogCommands from '../../catalog/commands';

const GetProfile = () => (dispatch, getState, api) => {
    
    const userToken = fromState.Authentication.currentToken()(getState());    
    const impersonationString = fromState.Admin.impersonationString()(getState());            
    return api.getProfile(userToken, impersonationString).then(result => {
        const getProfileResult = get(result, 'with');

        if (getProfileResult === 401) {
            return dispatch(Events.GetProfileFailed('Unauthorized.'));
        } else if (getProfileResult === 404) {
            return dispatch(Events.GetProfileFailed('Profile Not Found.'));
        } else if (getProfileResult === 500) {
            return dispatch(Events.GetProfileFailed('An unknown error occurred when attempting to get profile.'));
        }

        const state = get(getProfileResult, 'state');
        dispatch(LoadStates()).then(() => {
            dispatch(LoadCounties([state]));
            return dispatch(Events.GetProfileSucceeded(getProfileResult));
        });
    }, 
    error => dispatch(Events.GetProfileFailed(error)));
};

const UpdateRepInfo = (distributorId, retailerId, newRepId, newCustomerNum) => (dispatch, getState, api) => {
    
    const userToken = fromState.Authentication.currentToken()(getState());    
    const impersonationString = fromState.Admin.impersonationString()(getState());  
    return api.updateRepInfo(userToken, distributorId, retailerId, newRepId, newCustomerNum, impersonationString).then(result => {
        const updateRepResult = get(result, 'with');

        if (updateRepResult === 401) {
            return dispatch(Events.UpdateRepInfoFailed('Unauthorized.'));
        } else if (updateRepResult === 404) {
            return dispatch(Events.UpdateRepInfoFailed('404 Not Found.'));
        } else if (updateRepResult === 500) {
            return dispatch(Events.UpdateRepInfoFailed('An unknown error occurred when attempting to update info.'));
        }
        return dispatch(Events.UpdateRepInfoSucceeded(updateRepResult));
    }, 
    error => dispatch(Events.UpdateRepInfoFailed(error)));
};

const ResetPassword = (email, verificationCode, password) => (dispatch, getState, api) => {
    return api.resetPassword(email, verificationCode, password).then(result => {
        const resetResult = get(result, 'with');

        if (resetResult === 401) {
            return dispatch(Events.ResetPasswordFailed('Unauthorized.'));
        } else if (resetResult === 404) {
            return dispatch(Events.ResetPasswordFailed('No account found'));
        } else if (resetResult === 500) {
            return dispatch(Events.ResetPasswordFailed('An unknown error occurred when attempting to reset'));
        }
        return dispatch(Events.ResetPasswordSucceeded(resetResult));
    }, 
    error => dispatch(Events.ResetPasswordFailed(error)));
};

const FireEmail = (email) => (dispatch, getState, api) => {
    return api.fireEmail(email).then(result => {
        const existsResult = get(result, 'with');

        if (existsResult === 401) {
            return dispatch(Events.FireEmailFailed('Unauthorized.'));
        } else if (existsResult === 404) {
            return dispatch(Events.FireEmailFailed('No account found'));
        } else if (existsResult === 500) {
            return dispatch(Events.FireEmailFailed('An unknown error occurred when attempting to get account'));
        }
        return dispatch(Events.FireEmailSucceeded(existsResult));
    }, 
    error => dispatch(Events.FireEmailFailed(error)));
};

const HasAccount = (distributorId, retailerId) => (dispatch, getState, api) => {
    const userToken = fromState.Authentication.currentToken()(getState());    
    const impersonationString = fromState.Admin.impersonationString()(getState()); 
    return api.hasAccount(userToken, distributorId, retailerId, impersonationString).then(result => {
        const accountResult = get(result, 'with');

        if (accountResult === 401) {
            return dispatch(Events.HasAccountFailed('Unauthorized.'));
        } else if (accountResult === 404) {
            return dispatch(Events.HasAccountFailed('No account found'));
        } else if (accountResult === 500) {
            return dispatch(Events.HasAccountFailed('An unknown error occurred when attempting to get account'));
        }
        return dispatch(Events.HasAccountSucceeded(accountResult));
    }, 
    error => dispatch(Events.HasAccountFailed(error)));
};

const SubmitAccountInfo = (distributorId, retailerId, repId, accountNumber) => (dispatch, getState, api) => {
    const userToken = fromState.Authentication.currentToken()(getState());    
    const impersonationString = fromState.Admin.impersonationString()(getState()); 
    return api.submitAccountInfo(distributorId, retailerId, repId, accountNumber, userToken, impersonationString).then(result => {
        const submitAccountResult = get(result, 'with');

        if (submitAccountResult === 401) {
            return dispatch(Events.SubmitAccountInfoFailed('Unauthorized.'));
        } else if (submitAccountResult === 404) {
            return dispatch(Events.SubmitAccountInfoFailed('submit account info not found'));
        } else if (submitAccountResult === 500) {
            return dispatch(Events.SubmitAccountInfoFailed('An unknown error occurred when attempting to submit info'));
        }
        return dispatch(Events.SubmitAccountInfoSucceeded(submitAccountResult));
    }, 
    error => dispatch(Events.SubmitAccountInfoFailed(error)));
};

const LoadStates = () => (dispatch, getState, api) => {
    dispatch(Events.StatesLoading());

    const userToken = fromState.Authentication.currentToken()(getState());
    const impersonationString = fromState.Admin.impersonationString()(getState());     
    const statesResult = [];
        
    return api.getStates(userToken, impersonationString).then(result => {
        const statesResultTemp = get(result, 'with');
        for (let i = statesResultTemp.length - 1; i >= 0; i--) {
            statesResult.push({'value': statesResultTemp[i], 'label': statesResultTemp[i]});
        }

        if (statesResultTemp === 401) {
            return dispatch(Events.LoadStatesFailed('Unauthorized.'));
        } else if (statesResultTemp === 404) {
            return dispatch(Events.LoadStatesFailed('States Not Found.'));
        } else if (statesResultTemp === 500) {
            return dispatch(Events.LoadStatesFailed('An unknown error occurred when attempting to get states list.'));
        }
        return dispatch(Events.LoadStatesSucceeded(statesResult.reverse()));
    }, 
    error => dispatch(Events.LoadStatesFailed(error)));
};

const LoadCounties = (states) => (dispatch, getState, api) => {
    dispatch(Events.CountiesLoading());

    const userToken = fromState.Authentication.currentToken()(getState());    
    const impersonationString = fromState.Admin.impersonationString()(getState());     
    return api.getCounties(states, userToken, impersonationString).then(result => {
        const countiesResult = get(result, 'with');
        if (countiesResult === 401) {
            return dispatch(Events.LoadCountiesFailed('Unauthorized.'));
        } else if (countiesResult === 404) {
            return dispatch(Events.LoadCountiesFailed('Counties Not Found.'));
        } else if (countiesResult === 500) {
            return dispatch(Events.LoadCountiesFailed('An unknown error occurred when attempting to get counties list.'));
        }
        return dispatch(Events.LoadCountiesSucceeded(countiesResult));
    }, 
    error => dispatch(Events.LoadCountiesFailed(error)));
};

const GetReps = () => (dispatch, getState, api) => {
    const userToken = fromState.Authentication.currentToken()(getState());
    const impersonationString = fromState.Admin.impersonationString()(getState());         
    return api.getReps(userToken, impersonationString).then(result => {
        const getRepsResult = get(result, 'with');
        if (getRepsResult === 401) {
            return dispatch(Events.GetRepsFailed('Unauthorized.'));
        } else if (getRepsResult === 404) {
            return dispatch(Events.GetRepsFailed('Reps Not Found.'));
        } else if (getRepsResult === 500) {
            return dispatch(Events.GetRepsFailed('An unknown error occurred when attempting to get reps list.'));
        }
        return dispatch(Events.GetRepsSucceeded(getRepsResult));
    }, 
    error => dispatch(Events.GetRepsFailed(error)));
};

const PostRep = (repObject) => (dispatch, getState, api) => {
    
    const userToken = fromState.Authentication.currentToken()(getState());
    const impersonationString = fromState.Admin.impersonationString()(getState());        
    return api.postRep(repObject, userToken, impersonationString).then(result => {
        const postRepResult = get(result, 'with');
        if (postRepResult === 401) {
            return dispatch(Events.PostRepFailed('Unauthorized.'));
        } else if (postRepResult === 404) {
            return dispatch(Events.PostRepFailed('PostRep Not Found.'));
        } else if (postRepResult === 500) {
            return dispatch(Events.PostRepFailed('An unknown error occurred when attempting to post rep.'));
        }
        return dispatch(Events.PostRepSucceeded(postRepResult));
    }, 
    error => dispatch(Events.PostRepFailed(error)));
};

const GetTerritories = () => (dispatch, getState, api) => {
    const userToken = fromState.Authentication.currentToken()(getState()); 
    const impersonationString = fromState.Admin.impersonationString()(getState());        
    return api.getTerritories(userToken, impersonationString).then(result => {
        const getTerritoriesResult = get(result, 'with');
        if (getTerritoriesResult === 401) {
            return dispatch(Events.GetTerritoriesFailed('Unauthorized.'));
        } else if (getTerritoriesResult === 404) {
            return dispatch(Events.GetTerritoriesFailed('Territories Not Found.'));
        } else if (getTerritoriesResult === 500) {
            return dispatch(Events.GetTerritoriesFailed('An unknown error occurred when attempting to get territories list.'));
        }
        return dispatch(Events.GetTerritoriesSucceeded(getTerritoriesResult));
    }, 
    error => dispatch(Events.GetTerritoriesFailed(error)));
};

const PostTerritories = (arr) => (dispatch, getState, api) => {
    const userToken = fromState.Authentication.currentToken()(getState());
    const impersonationString = fromState.Admin.impersonationString()(getState());      
    return api.postTerritories(arr, userToken, impersonationString).then(result => {
    
        const postTerritoriesResult = get(result, 'statusCode');
        if (postTerritoriesResult === 401) {
            return dispatch(Events.PostTerritoriesFailed('Unauthorized.'));
        } else if (postTerritoriesResult === 404) {
            return dispatch(Events.PostTerritoriesFailed('PostTerritories Not Found.'));
        } else if (postTerritoriesResult === 500) {
            return dispatch(Events.PostTerritoriesFailed('An unknown error occurred when attempting to post territories.'));
        }
        return dispatch(Events.PostTerritoriesSucceeded());
    }, 
    error => dispatch(Events.PostTerritoriesFailed(error)));
};

const DeleteRep = (id) => (dispatch, getState, api) => {
    
    const userToken = fromState.Authentication.currentToken()(getState());
    const impersonationString = fromState.Admin.impersonationString()(getState());          
    return api.deleteRep(id, userToken, impersonationString).then(result => {
        const deleteRepResult = get(result, 'with');
        if (deleteRepResult === 401) {
            return dispatch(Events.DeleteRepFailed('Unauthorized.'));
        } else if (deleteRepResult === 404) {
            return dispatch(Events.DeleteRepFailed('DeleteRep Not Found.'));
        } else if (deleteRepResult === 500) {
            return dispatch(Events.DeleteRepFailed('An unknown error occurred when attempting to delete rep.'));
        }
        return dispatch(GetReps());
    }, 
    error => dispatch(Events.DeleteRepFailed(error)));
};

const GetRepsForDist = (distributorId) => (dispatch, getState, api) => {
    dispatch(Events.GetRepsForDistLoading());
    const userToken = fromState.Authentication.currentToken()(getState()); 
    const impersonationString = fromState.Admin.impersonationString()(getState());      
    return api.getRepsForDist(distributorId, userToken, impersonationString).then(result => {
        const repResult = get(result, 'with');
        if (repResult === 401) {
            return dispatch(Events.GetRepsForDistFailed('Unauthorized.'));
        } else if (repResult === 404) {
            return dispatch(Events.GetRepsForDistFailed('Reps Not Found.'));
        } else if (repResult === 500) {
            return dispatch(Events.GetRepsForDistFailed('An unknown error occurred when attempting to get reps.'));
        }
        return dispatch(Events.GetRepsForDistSucceeded(repResult));
    }, 
    error => dispatch(Events.GetRepsForDistFailed(error)));
};

const GetCustomerNum = (distributorId, retailerId) => (dispatch, getState, api) => {
    const userToken = fromState.Authentication.currentToken()(getState());    
    const impersonationString = fromState.Admin.impersonationString()(getState());      
    return api.getCustomerNum(distributorId, retailerId, userToken, impersonationString).then(result => {
        const repResult = get(result, 'with');
        if (repResult === 401) {
            return dispatch(Events.GetCustomerNumFailed('Unauthorized.'));
        } else if (repResult === 404) {
            return dispatch(Events.GetCustomerNumFailed('Reps Not Found.'));
        } else if (repResult === 500) {
            return dispatch(Events.GetCustomerNumFailed('An unknown error occurred when attempting to get reps.'));
        }
        return dispatch(Events.GetCustomerNumSucceeded(repResult));
    }, 
    error => dispatch(Events.GetCustomerNumFailed(error)));
};

const requiredFields = [
    ['businessType', 'Business Type'],
    ['address', 'Street Address'],
    ['companyName', 'Company Name'],
    ['city', 'City'],
    ['fullName', 'Full Name'],
    ['phone', 'Phone'],
    ['state', 'State'],
    ['zip', 'Zip Code'],
    //['ein', 'EIN'],
    ['counties', 'County'],
];

const validate = state => {
    const ret = {};
    const zip = new RegExp(/^\d{5}(?:[-\s]\d{4})?$/i);
    const ein = new RegExp(/\d{9}/i);
    if(!fromState.Profile.fieldValue('agreed')(state)) ret.agreed = 'You must agree to the terms to proceed.';
    if(!zip.test(fromState.Profile.fieldValue('zip')(state))) ret.zip = 'Zip is not valid';

    if(fromState.Profile.fieldValue('ein')(state).length!=0 && (!ein.test(fromState.Profile.fieldValue('ein')(state)) 
       || fromState.Profile.fieldValue('ein')(state).length!=9)) ret.ein = 'EIN must be 9 digits, only numbers';
   
    requiredFields.forEach((value) => {
        const key = value[0];
        if(!hasValue(fromState.Profile.fieldValue(key)(state))) ret[key] = 'is required';
    });
    return ret;
};

const Save = () => (dispatch, getState, api) => {
    dispatch(Events.SaveProfileStarted());

    //Validate Input
    const state = getState();
    const validationErrors = validate(state);
    if(!isEmpty(validationErrors)) return Promise.all([
        dispatch(Events.ValidationFailed(validationErrors)),
        dispatch(Events.SaveProfileFailed()),
    ]);
    
    const payload = {
        companySubType: fromState.Profile.fieldValue('businessType')(state),
        companyName: fromState.Profile.fieldValue('companyName')(state),
        address1: fromState.Profile.fieldValue('address')(state),
        address2: fromState.Profile.fieldValue('address2')(state),
        city: fromState.Profile.fieldValue('city')(state),
        state: fromState.Profile.fieldValue('state')(state),
        zipcode: fromState.Profile.fieldValue('zip')(state),
        county: fromState.Profile.fieldValue('counties')(state),
        ein: fromState.Profile.fieldValue('ein')(state),
        website: fromState.Profile.fieldValue('website')(state),
        contactName: fromState.Profile.fieldValue('fullName')(state),
        contactPhone: fromState.Profile.fieldValue('phone')(state),
        contactEmail: fromState.Authentication.currentUsername()(state),    
    };

    const impersonationString = fromState.Admin.impersonationString()(getState());
    const userToken = fromState.Authentication.currentToken()(state); 
    if (impersonationString !== '') {
        delete payload.contactEmail;
    } 
    return api.saveProfile(payload, userToken, impersonationString).then(result => {
        const resultCode = get(result, 'statusCode', 500);

        if (resultCode === 401) {
            return dispatch(AuthEvents.AuthenticationFailed('Invalid username or password.'));
        }
        else if (resultCode === 404) {
            return dispatch(Events.SaveProfileFailed('404 Not Found. Profile not saved.'));
        } else if (resultCode === 500) {
            return dispatch(Events.SaveProfileFailed('An error occurred when attempting to save profile.'));
        }

        dispatch(AuthEvents.SetRetailerType(payload.companySubType));
        dispatch(CatalogCommands.SearchCatalogProducts(-1, ''));
        return dispatch(Events.SaveProfileSucceeded(get(result, 'with', '')));
        
    });
};

const updateField = (name, value) => (dispatch, getState) => {
    dispatch(Events.FieldUpdated(name, value));
    const state = getState();
    if(fromState.Profile.hasAttemptedToSave()(state)) {
        dispatch(Events.ResetValidationField(name));
        const validationErrors = validate(state);
        if(!isEmpty(validationErrors)) dispatch(Events.ValidationFailed(validationErrors));        
    }
    if(name === 'state') dispatch(LoadCounties([value]));
};

export default {
    DeleteRep,
    FireEmail,
    HasAccount,
    LoadStates,
    LoadCounties,
    Save,
    GetProfile,
    GetReps,
    GetRepsForDist,
    GetTerritories,
    GetCustomerNum,
    PostRep,
    PostTerritories,
    ResetPassword,
    SubmitAccountInfo,
    UpdateRepInfo,
    UpdateField: updateField,

};