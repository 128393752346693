import React from 'react';
import styles from './styles.scss';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import sortBy from 'lodash/sortBy';

export default class MultiCheckbox extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            selectedCounties: [],
            theseCounties: [],
        };
    }

    remove(arr) {
        let what, a = arguments, L = a.length, ax;
        while (L > 1 && arr.length) {
            what = a[--L];
            while ((ax= arr.indexOf(what)) !== -1) {
                arr.splice(ax, 1);
            }
        }
        return arr;
    }


    toggleSelected(index) {
        const { selectedCounties } = this.state;
        const temp = this.state.theseCounties[index];
        temp.selected = !temp.selected;
        this.setState({
            [index]: temp,
        });
        temp.selected ? selectedCounties.push(temp.id) : this.remove(selectedCounties, temp.id);
        this.props.onChange(selectedCounties);
    }

   
    UNSAFE_componentWillReceiveProps(){
        const allCounties = this.props.list;
        const theseCounties = [];
        for (let i=allCounties.length-1; i>=0; i--){
            if(allCounties[i].state===this.props.labelText){
                theseCounties.push(allCounties[i]);
            }
        }
        const sorted = sortBy(theseCounties, obj => obj.name);
        this.setState({ theseCounties: sorted});
    }

    render(){
        return(
            <div className={styles.wrapper}>
                <div className={classnames('is-size-5', styles.label)}>{this.props.labelText}</div>
                <div className="columns is-multiline">
                    {this.state.theseCounties.map((item, index) => (
                        <div className="column is-2" key={index}>
                            <label className={styles.container}>
                                <input type="checkbox" onClick={() => this.toggleSelected(index)}/>
                                <span className="is-size-7" 
                                    style={{padding: '1rem 0 1rem 0'}}>{item.name}</span>
                                <span className={styles.checkmark}></span>
                            </label>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

MultiCheckbox.propTypes = {
    onChange: PropTypes.func,
    toggleSelected: PropTypes.func,
    labelText: PropTypes.string,
    list: PropTypes.array,
};