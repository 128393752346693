import {types as EventTypes} from '../events';
import basicReducer from '../../common/reducers/basic-reducer';
import {types as AuthEventTypes} from '../../authentication/events';

import constant from 'lodash/constant';
import unset from 'lodash/unset';

const initialState = {};

const reductionLookup = {
    [AuthEventTypes.LoggedOut]: constant(initialState),
    [EventTypes.ValidationFailed]: (state, errors = {}) => errors,
    [EventTypes.ResetValidation]: constant(initialState),
    [EventTypes.ResetValidationField]: (state, field) => unset(state, field),
};

export default basicReducer(initialState, reductionLookup);
