import Events from '../events';
import CartCommands from '../../cart/commands';
import fromState from 'state/selectors';
import get from 'lodash/get';


const GetRetailers = () => (dispatch, getState, api) => {

    const userToken = fromState.Authentication.currentToken()(getState());    
    return api.getRetailers(userToken).then(result => {
        const getRetailersResult = get(result, 'with');
        if (getRetailersResult === 401) {
            return dispatch(Events.GetRetailersFailed('Unauthorized.'));
        } else if (getRetailersResult === 404) {
            return dispatch(Events.GetRetailersFailed('Comments Not Found.'));
        } else if (getRetailersResult === 500) {
            return dispatch(Events.GetRetailersFailed('An unknown error occurred when attempting to get comments.'));
        }
        return dispatch(Events.GetRetailersSucceeded(getRetailersResult));
    }, 
    error => dispatch(Events.GetRetailersFailed(error)));
};

const GetDistributors = () => (dispatch, getState, api) => {

    const userToken = fromState.Authentication.currentToken()(getState());    
    return api.getDistributors(userToken).then(result => {
        const getDistributorsResult = get(result, 'with');
        if (getDistributorsResult === 401) {
            return dispatch(Events.GetDistributorsFailed('Unauthorized.'));
        } else if (getDistributorsResult === 404) {
            return dispatch(Events.GetDistributorsFailed('Comments Not Found.'));
        } else if (getDistributorsResult === 500) {
            return dispatch(Events.GetDistributorsFailed('An unknown error occurred when attempting to get comments.'));
        }
        return dispatch(Events.GetDistributorsSucceeded(getDistributorsResult));
    }, 
    error => dispatch(Events.GetDistributorsFailed(error)));
};

const StartImpersonating = (companyType, companyId, companyName) => (dispatch) => {
    dispatch(Events.StartImpersonating(true));
    dispatch(CartCommands.LoadCart());
    dispatch(CartCommands.LoadOrderHeaders());
    return dispatch(Events.Impersonating(`${companyType}|${companyId}`, companyName, companyId.toString(), companyType.toString()));
};

export default {
    GetRetailers,
    GetDistributors,
    StartImpersonating,
};