import React from 'react';
import Filters from '../../components/filters';
import styles from './styles.scss';
import PropTypes from 'prop-types';
import { Table, Thead, Tbody, Tr, Th } from 'react-super-responsive-table';
import TableRow from '../../components/table-row';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import Pagination from 'react-js-pagination';
import get from 'lodash/get';
import { isMobileOnly, isTablet,  isBrowser} from 'react-device-detect';
import { BeatLoader } from 'react-spinners';

class DistributorCatalog extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            addProduct: false,
            productSelected: false,
            productId: -1,
            activePage: 1,
        };
        this.loadCatalogCategoryProductTypes = this.loadCatalogCategoryProductTypes.bind(this);
        this.loadSubTypes = this.loadSubTypes.bind(this);
        this.loadSubRegions = this.loadSubRegions.bind(this);
        this.loadPackagingTypes = this.loadPackagingTypes.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.searchCatalogProducts = this.searchCatalogProducts.bind(this);
        this.toggleMobileFilters = this.toggleMobileFilters.bind(this);
    }

    componentDidMount() {
        this.setState({
            searchString: this.props.location.state == null ? '' : this.props.location.state.searchString,
        });
        this.props.loadRegions();
        this.props.loadCatalogCategories();
        this.props.loadPackagingTypes(-1);
        {isBrowser &&
        this.props.loadCatalogBrands().then(() => {
            this.props.searchCatalogProducts( -1, this.state.searchString || '' ).then((result) => {
                if (get(result, 'payload') === 'Unauthorized.'){
                    this.props.onLogout().then(() => this.props.history.push('/'));
                }
            });
        });
        }
    }

    loadCatalogCategoryProductTypes(id) {
        return this.props.loadCatalogCategoryProductTypes(id);
    }

    loadSubTypes(categoryId, typeId) {
        this.props.loadSubTypes(categoryId, typeId);
    }

    loadSubRegions(id) {
        this.props.loadSubRegions(id);
    }

    loadPackagingTypes(id){
        this.props.loadPackagingTypes(id);
    }

    handleRowClick(productId) {
        this.props.history.push({ pathname: '/product-edit', state: { productId: productId,}, });
    }

    handleClick(){
        this.setState({
            addProduct: true,
        });
    }

    handlePageChange(pageNumber) {
        this.props.setActivePage(pageNumber);
    }

    searchCatalogProducts(distId, productName, brandId, categoryId, typeId, subTypeId, regionId, subRegionId, packageTypeId, priceLow, priceHigh) {
        this.handlePageChange(1);
        this.props.searchCatalogProducts(distId, productName, brandId, categoryId, typeId, subTypeId, regionId, subRegionId, packageTypeId, priceLow, priceHigh);
    }

    toggleMobileFilters(){
        this.props.history.push('/mobile-filters');
    }

    clearSearch(){
        this.setState({
            searchString: '',
        });
        this.searchCatalogProducts();
    }

    render() {
        const { searchString, } = this.state;
        const { activePage, products, currentUsertype, retailerType, isSearching } = this.props;
        const itemsPerPage = 8;
        const range = Math.ceil(products.length/itemsPerPage);
        const items = products.slice((activePage-1)*itemsPerPage, (activePage-1)*itemsPerPage+itemsPerPage);
        return (

            <div>
                <section className={styles.wrapper}>
                    <div className='columns'>
                        <div className='column is-3'>
                            <span className='level is-marginless'>
                                <div 
                                    style={{position: 'relative',}}
                                    className='title level-item-left'>Product Catalog
                                    { !isBrowser &&
                                <div 
                                    onClick={this.toggleMobileFilters}
                                    className='image is-32x32' 
                                    style={{position: 'absolute', top: '10px', right: '10px',}}>
                                    <img src={require('../../images/icon-filters.png')} />
                                </div>
                                    }
                                </div>
                                <Link to='/product-add'>
                                    <div className='level-item-right button'>+ Product</div>
                                </Link>
                            </span>
                            { isBrowser &&
                            <Filters 
                                hideDistFilter={true}
                                onSearch={this.searchCatalogProducts}
                                brands={this.props.brands}
                                categories={this.props.categories}
                                regions={this.props.regions} 
                                subRegions={this.props.subRegions}
                                productTypes={this.props.productTypes}
                                subTypes={this.props.subTypes}  
                                packagingTypes={this.props.packagingTypes}
                                loadPackagingTypes={this.loadPackagingTypes}
                                loadProductTypes={this.loadCatalogCategoryProductTypes}
                                loadSubTypes={this.loadSubTypes}
                                loadSubRegions={this.loadSubRegions} />
                            }
                        </div>
                        
                        <div className='column'>
                            { isBrowser &&
                        <div className="level is-marginless" style={{paddingTop: '15px'}}>
                            <div className='subtitle level-item-left' onClick={() => this.clearSearch()}>
                                {searchString !=='' ? 
                                    <div> {searchString}
                                        <a style={{ margin: '5px'}} className='delete is-small'/> 
                                    </div> : 'My Products'}
                    
                            </div>
                            <div className="pagination level-item-right">
                                <Pagination
                                    activeLinkClass='is-current'
                                    linkClassPrev='pagination-previous'
                                    linkClassNext='pagination-next'
                                    innerClass='pagination-list'
                                    linkClass='pagination-link'
                                    activePage={activePage}
                                    itemsCountPerPage={itemsPerPage}
                                    totalItemsCount={products.length}
                                    pageRangeDisplayed={ range < 6 ? range : 5 }
                                    onChange={this.handlePageChange}
                                />
                            </div>
                        </div>
                            }
    
                            <Table className='table is-marginless' 
                                style={{border: !isBrowser ? '' : '0.5px solid', borderRadius: 10, borderColor: '#ccc'}}>
                                <Thead className='thead'>
                                    <Tr style={{ borderWidth: '1px', borderColor: '#333', backgroundColor: '#f1e2c3',}}>
                                        <Th style={{padding: '1rem 0 0 2rem',}} colSpan={5}>Product</Th>
                                        <Th style={{padding: '1rem',}}>Price</Th>
                                    </Tr>
                                </Thead>
                            </Table>
                            <div style={{border: !isBrowser || isSearching ? '' : '1px solid #eee'}}>
                                <Table>
                                    <Tbody className='tbody'>
                                        { isSearching &&
                                        <div className='sweet-loading' style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '35%',}}>
                                            <BeatLoader
                                                sizeUnit={'px'}
                                                size={20}
                                                color={'#29393e'}
                                                loading={true}
                                            />
                                        </div> 
                                        }
                                        { !isSearching && items.length === 0 &&
                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center',}}>
                                            <h3 className='subtitle' style={{marginTop: '20%', padding: '0 3rem 20rem 3rem', color: '#bbb'}}>No results match your search critera. Please alter your search and try again...</h3>
                                        </div> 
                                        }
                                        {items.map((item, index) => (
                                            <TableRow
                                                key={index}
                                                availablePackages={item.availablePackages}
                                                onRowClick={this.handleRowClick}
                                                className='trow'
                                                productType={item.category.name} 
                                                productId={item.id}
                                                picUrl='https://images.onlinelabels.com/Images/Predesign/00000002/1247/Countryside-Vinyard-Wine-Label.png'
                                                title={item.brand.name} 
                                                subtitle1={item.name} 
                                                subtitle2={item.category.name + ' ' + item.type.name} 
                                                subtitle3={item.subType.name}
                                                currentUsertype={currentUsertype}
                                                retailerType={retailerType}
                                                inStock={(item.inStock===1)} />
                                        ))}
                                        
                                    </Tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default withRouter(DistributorCatalog);

DistributorCatalog.propTypes = {
    activePage: PropTypes.string,
    isSearching: PropTypes.bool,
    location: PropTypes.object,
    loadRegions: PropTypes.func,
    loadCatalogCategories: PropTypes.func,
    loadCatalogCategoryProductTypes: PropTypes.func,
    loadSubTypes: PropTypes.func,
    loadSubRegions: PropTypes.func,
    loadPackagingTypes: PropTypes.func,
    loadCatalogBrands: PropTypes.func,
    categories: PropTypes.array,
    onLogout: PropTypes.func,
    regions: PropTypes.array,
    productTypes: PropTypes.array,
    products: PropTypes.array,
    searchCatalogProducts: PropTypes.func,
    setActivePage: PropTypes.func,
    subTypes: PropTypes.array,
    subRegions: PropTypes.array,
    currentUsertype: PropTypes.string,
    retailerType: PropTypes.string,
    packagingTypes: PropTypes.array,
    id: PropTypes.number,
    brands: PropTypes.array,
    history: PropTypes.object,
};

DistributorCatalog.defaultProps = {
    products: [],
};
