import Admin from './admin/commands';
import Authentication from './authentication/commands';
import Cart from './cart/commands';
import Catalog from './catalog/commands';
import Profile from './profile/commands';

export default {
	Admin,
    Authentication,
    Cart,
    Catalog,
    Profile,
};
