import request from './common/request';
import ReactGA from 'react-ga';
const usersUrl = 'https://api.buyfluidly.com/v1/users/me';
const signupUrl = `${usersUrl}/signup`;
const loginUrl = `${usersUrl}/login`;
const logoutUrl = `${usersUrl}/logout`;
const refreshTokenUrl = `${usersUrl}/refreshToken`;

export default {
    signupUser: (username, password, companyType) => {
        ReactGA.event({
            category: 'User',
            action: 'Created an Account'
        });
        return request({
            method: 'POST',
            url: signupUrl,
            payload: {username, password, companyType},
        });
    },
    loginUser: (username, password) => {
        ReactGA.event({
            category: 'User',
            action: 'Logged In'
        });
        return request({
            method: 'POST',
            url: loginUrl,
            payload: {username, password},
        });
    },
    logoutUser: (userToken) => {
        ReactGA.event({
            category: 'User',
            action: 'Logged Out'
        });
        return request({
            method: 'POST',
            url: logoutUrl,
            userToken,
        });
    },
    refreshToken: (userToken, refreshToken) => {
        return request({
            method: 'POST',
            url: refreshTokenUrl,
            payload: {'refresh_token': refreshToken},
            userToken,
        });
    },
};