import {types as EventTypes} from '../events';
import basicReducer from '../../common/reducers/basic-reducer';
import {types as AuthEventTypes} from '../../authentication/events';

import constant from 'lodash/constant';

const initialState = [];

const reductionLookup = {
    [AuthEventTypes.LoggedOut]: constant(initialState),

    [EventTypes.GetRepsFailed]: constant(initialState),
    [EventTypes.GetRepsSucceeded]: (_, result) => result,

    [EventTypes.GetRepsForDistLoading]: constant(initialState),
    [EventTypes.GetRepsForDistFailed]: constant(initialState),
    [EventTypes.GetRepsForDistSucceeded]: (_, result) => result,

    [EventTypes.DeleteRepFailed]: constant(initialState),
    [EventTypes.DeleteRepSucceeded]: (_, result) => result,

    [EventTypes.SubmitAccountInfoFailed]: constant(initialState),
    [EventTypes.SubmitAccountInfoSucceeded]: (_, result) => result,

    [EventTypes.HasAccountFailed]: constant(initialState),
    [EventTypes.HasAccountSucceeded]: (_, result) => result,

};

export default basicReducer(initialState, reductionLookup);