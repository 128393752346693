import { connect } from 'react-redux';
import Component from './component';
import Commands from 'state/commands';
import fromState from 'state/selectors';

function mapStateToProps(state) {
    return {
        impersonationString: fromState.Admin.impersonationString()(state),
        retailersList: fromState.Admin.retailersList()(state),
        distributorsList: fromState.Admin.distributorsList()(state),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getRetailers: () => dispatch(Commands.Admin.GetRetailers()),
        getDistributors: () => dispatch(Commands.Admin.GetDistributors()),
        startImpersonating: (companyType, companyId, companyName) => dispatch(Commands.Admin.StartImpersonating(companyType, companyId, companyName)),
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(Component);