exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-components-filters-styles--wrapper {\n  padding: 2rem 1rem 2rem 1rem;\n  background-color: #f1e2c3;\n  border-radius: 10px;\n  border: 1px solid #ccc; }\n", ""]);

// exports
exports.locals = {
	"wrapper": "src-components-filters-styles--wrapper"
};