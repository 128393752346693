import { connect } from 'react-redux';

import Component from './component';
import Commands from 'state/commands';
import fromState from 'state/selectors';

function mapStateToProps(state) {
    return {
        isAdmin: fromState.Authentication.isAdmin()(state),
        company_id: fromState.Authentication.companyId()(state),
        loginError: fromState.Authentication.error()(state),
        username: fromState.Authentication.currentUsername()(state),
        userToken: fromState.Authentication.currentToken()(state),
        usertype: fromState.Authentication.currentUsertype()(state),
        rows: fromState.Cart.cartRows()(state),
        fieldValues: fromState.Profile.fieldValue()(state),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getRows: () => dispatch(Commands.Cart.CartSummary()),
        getProfile: () => dispatch(Commands.Profile.GetProfile()),
        onLogout: () => dispatch(Commands.Authentication.Logout()),
        onLogin: (username, password) => dispatch(Commands.Authentication.Login(username,password)),        
        onSignup: (username, password, companyType) => dispatch(Commands.Authentication.Signup(username,password, companyType)),      
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
