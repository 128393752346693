import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import styles from './styles.scss';
import classnames from 'classnames';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { withAlert } from 'react-alert';
import { isMobileOnly, isTablet,  isBrowser} from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const initialState = {
    currentUrl: '/',
    title: 'BuyFluidly',
    username: '',
    password: '',
    usertype: '',
    showMobileMenu: false,
    adminName: '',
};


class Header extends React.Component {

    constructor(props){
        super(props);
        this.state = initialState;
        this.props.history.listen((location) => {
            setTimeout(this.setState({ adminName: this.state.adminName === '' ? props.companyName : this.state.adminName  }), 1000);
            this.setState({ 
                currentUrl: location.pathname, });
            

        });
    }

    componentDidMount(){
        this.setState({ currentUrl: this.props.history.location.pathname, });
    }

    toggleMobileMenu() {
        const { showMobileMenu } = this.state;
        this.setState({
            showMobileMenu: !showMobileMenu,
        });
    }

    updateUsername(username) {
        this.setState({username});
    }

    updatePassword(password) {
        this.setState({password});
    }

    attemptSignup() {
        this.props.onSignup(this.state.username, this.state.password);
    }

    attemptLogin() {
        this.props.onLogin(this.state.username, this.state.password).then((result) => {
            if(get(result, 'type') === 'authentication-authentication-failed'){
                this.props.alert.show(
                    <div style={{padding: '3rem',}}><b>Login Failed: </b>{get(result, 'payload')}</div>,
                    {
                        timeout: 4000,  
                        type: 'success',
                        onClose: () => {} 
                    });
            } 
        }).then(() => {
            this.props.getProfile();
        });
    }

    logout() {
        this.setState(initialState);
        this.setState({ adminName: '', });
        this.props.onLogout().then(() => this.props.history.push('/'));
    }

    enterPressed(event) {
        const code = event.keyCode || event.which;
        if(code === 13) { //13 is the enter keycode
            this.attemptLogin();
        } 
    }

    render() {
        const { fieldValues, isAdmin, rows, username, usertype, company_id, impersonationName } = this.props;
        const { adminName, currentUrl, showMobileMenu, } = this.state;
        const companyName = get(fieldValues, 'companyName');
        return(
            <div className={classnames('level is-mobile', styles.wrap)} style={{backgroundColor: isAdmin ? '#620037' : '#1b1725'}}>
                <div className='level-left' style={{maxWidth: '50%', }}>
                    { !isEmpty(usertype) && currentUrl !=='/' &&
                        <div className='level-item is-hidden-tablet is-hidden-desktop image is-24x24'
                            onClick={() => this.toggleMobileMenu()}>
                            <img src={require('images/icon-burger.png')} />
                        </div>
                    }
                    <div className="level-item">
                        <div className='image is-48x48' style={{padding: '5px', }} onClick={() => this.setState({ showMobileMenu: false, })}>
                            <Link to='/'>
                                { currentUrl === '/distributor-dashboard' &&
                                    <img src={require('images/footer-truck-yellow.png')} />
                                }
                                {
                                    currentUrl !== '/distributor-dashboard' &&
                                    <img src={require('images/footer-truck-white.png')} />
                                }
                            </Link>
                        </div>
                        <Link to='/'>
                            <img 
                                className='is-hidden-mobile'
                                style={{ maxHeight: '70%', maxWidth: '70%',}}
                                src={require('images/bf-logo-white.png')} 
                            />
                            <img 
                                className='is-hidden-desktop is-hidden-tablet'
                                style={{ maxHeight: '50%', maxWidth: '50%',}}
                                src={require('images/bf-logo-white.png')} 
                            />
                        </Link>    
                    </div>
                    {!isEmpty(usertype) && company_id !== '' &&
                    <div className="level-item is-hidden-mobile">
                        <Link to={ (usertype==='1') ? '/consumer-dashboard' : '/distributor-catalog' }>
                            <div className={ currentUrl === '/consumer-dashboard' || currentUrl === '/distributor-catalog' 
                                ? 'button is-text has-text-primary' : ' button is-text has-text-white'}>Products</div>
                        </Link>
                    </div>}
                    {!isEmpty(usertype) && company_id !== '' &&
                    <div className="level-item is-hidden-mobile">
                        <Link to={ (usertype==='1') ? '/consumer-order-history' : '/distributor-order-history'}>
                            <div className={ currentUrl === '/consumer-order-history' || currentUrl === '/distributor-order-history' 
                                ? 'button is-text has-text-primary' : ' button is-text has-text-white'}>Orders</div>
                        </Link>
                    </div> }
                    { !isEmpty(usertype) && usertype === '1' && company_id !== '' &&
                    <div className="level-item is-hidden-mobile">
                        <Link to='/consumer-cart'>
                            <div className={ currentUrl === '/consumer-cart' 
                                ? 'button is-text has-text-primary' : ' button is-text has-text-white'}>Cart</div>
                        </Link>
                    </div>
                    }
                    {!isEmpty(usertype) && company_id !== '' &&
                    <div className="level-item is-hidden-mobile">
                        <a href="mailto: support@buyfluidly.com">
                            <div className="button is-text has-text-white">Support</div>
                        </a>
                    </div>}
                    {!isEmpty(usertype) &&
                    <div className="level-item is-hidden-mobile">
                        <div className="button is-text has-text-white" onClick={() => this.logout()}>Sign Out</div>
                    </div>}
                    {!isEmpty(usertype) && !isMobileOnly &&
                    <Link to={{ pathname: usertype === '1' ? '/consumer-profile' : '/distributor-profile', state: { isEdting: true} }}>
                        <div className='button' style={{marginLeft: '1rem'}}>
                            <FontAwesomeIcon icon="user" className={styles.icon}/>&nbsp;{isAdmin ? `${adminName} (Admin)` : `${companyName}`}
                        </div>
                    </Link>
                    }
                    {!isEmpty(usertype) && isAdmin &&
                    <Link to={{ pathname: '/users-list' }}>
                        <div style={{marginLeft: '1rem'}} className={ currentUrl === '/users-list' 
                            ? 'button is-text has-text-primary' : ' button is-text has-text-white'}>Users List</div>
                    </Link>
                    }
                </div>
                <div className="level-right">
                    { 
                        isEmpty(usertype) && currentUrl ==='/' && isEmpty(username) && 
                            <div className="level-item is-hidden-mobile">
                                <input className="input" 
                                    type="email" 
                                    placeholder="Email Address"
                                    onChange={(e) => this.updateUsername(e.target.value)}
                                    onKeyPress={this.enterPressed.bind(this)}/>
                                
                            </div>
                    }
                    { isEmpty(usertype) && currentUrl ==='/' && isEmpty(username) && 
                            <div className="level-item is-hidden-mobile" style={{marginRight: '10px'}}>
                                <input 
                                    className="input" 
                                    type="password" 
                                    placeholder="Password"
                                    onChange={(e) => this.updatePassword(e.target.value)}
                                    onKeyPress={this.enterPressed.bind(this)}/>
                                
                            </div>
                    }
                    { isEmpty(usertype) && currentUrl ==='/' && 
                        <div className="level-item is-hidden-mobile">
                            <Link to='/reset-password'>
                                <div className='image is-32x32'>
                                    <img src={require('../../images/icon-forgot-password.png')} />
                                </div>
                            </Link>
                        </div>
                    }
                    { isEmpty(usertype) && currentUrl ==='/' && 
                        <div className="level-item is-hidden-mobile">
                            <div className="button"  onClick={() => this.attemptLogin()}>Sign In</div>
                        </div>
                    }
                    { isEmpty(usertype) && currentUrl ==='/' && 
                        <div className="level-item is-hidden-mobile">
                            <Link to={{ pathname: '/signup', state: { isSignup: true } }}>
                                <div className="button is-dark">Create An Account</div>
                            </Link>
                        </div>
                    }

                    { isEmpty(usertype) && currentUrl ==='/' &&
                        <div className="level-item is-hidden-tablet is-hidden-desktop is-pulled-right">
                            <Link to={{ pathname: '/signup', state: { isSignup: false } }}>
                                <div className="button">Sign In / Up</div>
                            </Link>
                        </div> 
                    }
                    { (usertype === '1') && currentUrl !=='/' && company_id !== '' &&
                        <figure className="level-item image is-48x48 is-pulled-right" style={{padding: isMobileOnly ? '.4rem 0' : '.4rem .4rem 0 0'}}>
                            <Link to='/consumer-cart'>
                                <img src={require('../../images/cart-white.png')} />
                            </Link>
                            { rows> 0 && 
                            <div className={styles.badge}>{rows}</div>
                            }
                        </figure>         
                    }
                    
                </div>
                {impersonationName !=='' && 
                <Link style={{position: 'absolute', bottom: 0, color: 'white', }} to={{ pathname: usertype === '1' ? '/consumer-profile' : '/distributor-profile', state: { isEdting: true} }}>
                    <div>Impersonating: {impersonationName}</div>
                </Link>
                }
                { showMobileMenu &&
                <div style={{position: 'absolute', top: '100%', left: 0, width: '100%', backgroundColor: 'rgba(0,0,0,0.85)', padding: '1rem',}}>
                    {!isEmpty(usertype) && company_id !== '' &&
                        <Link to={ (usertype==='1') ? '/consumer-dashboard' : '/distributor-catalog' }>
                            <div className={ currentUrl === '/consumer-dashboard' || currentUrl === '/distributor-catalog' 
                                ? 'button is-text has-text-primary' : ' button is-text has-text-white'}
                            onClick={() => this.toggleMobileMenu()}>Products</div>
                        </Link>
                    }
                    {!isEmpty(usertype) && company_id !== '' &&
                    <Link to={ (usertype==='1') ? '/consumer-order-history' : '/distributor-order-history'}>
                        <div className={ currentUrl === '/consumer-order-history' || currentUrl === '/distributor-order-history' 
                            ? 'button is-text has-text-primary' : ' button is-text has-text-white'}
                        onClick={() => this.toggleMobileMenu()}>Orders</div>
                    </Link>
                    }
                    { !isEmpty(usertype) && usertype === '1' && company_id !== '' &&
                    <Link to='/consumer-cart'>
                        <div className={ currentUrl === '/consumer-cart' 
                            ? 'button is-text has-text-primary' : ' button is-text has-text-white'}
                        onClick={() => this.toggleMobileMenu()}>Cart</div>
                    </Link>
                    }
                    {!isEmpty(usertype) && company_id !== '' &&
                    <a href="mailto: support@buyfluidly.com">
                        <div className="button is-text has-text-white">Support</div>
                    </a>
                    }
                    {!isEmpty(usertype) &&
                    <div className="button is-text has-text-white" onClick={() => this.logout().then(() => this.toggleMobileMenu())}>Sign Out</div>
                    }
                    {!isEmpty(usertype) && isMobileOnly &&
                    <Link to={{ pathname: usertype === '1' ? '/consumer-profile' : '/distributor-profile', state: { isEdting: true} }}
                        onClick={() => this.toggleMobileMenu()}>
                        <div className='button'>
                            <FontAwesomeIcon icon="user" className={styles.icon}/>&nbsp;{isAdmin ? `${adminName} (Admin)` : `${companyName}`}
                        </div>
                    </Link>
                    }
                </div>
                }
            </div>


            
        );
    }
}   

Header.propTypes = { 
    companyName: PropTypes.string,
    impersonationName: PropTypes.string,
    isAdmin: PropTypes.bool,
    alert: PropTypes.object,
    company_id: PropTypes.string,
    getRows: PropTypes.func,
    getProfile: PropTypes.func,
    loginError: PropTypes.string, 
    fieldValues: PropTypes.object,
    username: PropTypes.string,
    usertype: PropTypes.string,
    onSignup: PropTypes.func.isRequired,
    onLogin: PropTypes.func.isRequired,
    onLogout: PropTypes.func.isRequired,
    match: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object,
    rows: PropTypes.number,
};

Header.defaultProps = {   
    usertype: '',
};

export default withAlert(withRouter(Header));
