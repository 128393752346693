import {types as EventTypes} from '../events';
import basicReducer from '../../common/reducers/basic-reducer';

import constant from 'lodash/constant';
import get from 'lodash/get';

const initialState = '';

const reductionLookup = {
    [EventTypes.LoggedOut]: constant(initialState),
    [EventTypes.GetCustomerNumFailed]: constant(initialState),
    [EventTypes.GetCustomerNumSucceeded]: (result) => get(result, 'number', '')
};

export default basicReducer(initialState, reductionLookup);