import React from 'react';
import { Tr, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import PropTypes from 'prop-types';
import styles from './styles.scss';
import classnames from 'classnames';
import get from 'lodash/get';
import { isMobileOnly, isTablet,  isBrowser} from 'react-device-detect';

class CartRow extends React.Component {

    constructor(){
        super();
        this.onRowClick = this.onRowClick.bind(this);
        this.state = { isMounted: false, };
    }

    componentDidMount() {
        this.setState({isMounted: true});
        const { distributorId, retailerId } = this.props;
        this.props.getCustomerNum(distributorId, retailerId).then((result) => {
            const customerNumber = get(result, 'payload.number', 'N/A');
            const distributorContactPhone = get(result, 'payload.repPhone', 'N/A');
            const distributorContactName = get(result, 'payload.repName', 'N/A');
            const distributorContactEmail = get(result, 'payload.repEmail', 'N/A');
            if (this.state.isMounted) {
                this.setState({
                    customerNumber:customerNumber,
                    distributorContactEmail: distributorContactEmail,
                    distributorContactName: distributorContactName, 
                    distributorContactPhone: distributorContactPhone,         
                });
            }
        });
    }

    componentWillUnmount(){
        this.setState({isMounted: false});
    }

    onRowClick(index) {
        this.props.onRowClick(index);
    }

    onQtyChange(event, index, id) {
        const value = event.target.value;
        this.props.onQtyChange(index, value, id);
    }

    onCommentChange(e){
        const value = e.target.value;
        this.props.onCommentChange(value);
    }

    render() {
        const { 
            distributor,
            customerPhone,
            lineItems,
            subTotal,
            comment,
        } = this.props;
        const { customerNumber, distributorContactName, distributorContactEmail } = this.state;
        return (
            <Tr style={{padding: isBrowser ? '' : '10px', backgroundColor: !isBrowser && this.props.index%2 ===0 ? 'rgb(241,226,195,0.6)' : '', }}>
                <h1 className='title is-size-5-mobile'>{distributor}</h1>
                <div className='level'>
                    <div className="level-item-left">{distributorContactName}: {distributorContactEmail}<br />Phone: <a href={'tel:' + customerPhone}>{customerPhone}</a></div>
                    <div className="level-item-right">Customer Number: {customerNumber}</div>
                </div>
                <div className="box is-paddingless">
                    <div style={{backgroundColor: '#f1e2c3', borderTopLeftRadius: '5px', borderTopRightRadius: '5px',}}>
                        <div className='columns is-marginless is-hidden-mobile' style={{padding: '0 1rem 0 2rem',}}>
                            <div className="column is-1 has-text-centered">SKU</div>
                            <div className="column is-4 has-text-centered">Description</div>
                            <div className="column is-2 has-text-centered">Packaging</div>
                            <div className="column is-2 has-text-centered">Qty</div>
                            <div className="column is-1 has-text-centered">Price</div>
                            <div className="column is-2 has-text-centered">Sub-Total</div>
                        </div>  
                    </div>  
                    <div className="is-paddingless is-marginless" style={{height: '1px', width: '100%', backgroundColor: 'lightgrey',}}/>
                    {lineItems.map((item, index) => (
                        isBrowser ?
                            <div key={index} className='columns is-marginless' style={{backgroundColor: index%2 ===0 ? '' : '#fafafa', padding: '0 1rem 0 2rem',}}>
                                <div className="column is-1 has-text-centered">
                                    <p className='is-size-6 has-text-weight-bold' style={{marginBottom: '1rem',}}>{item.product.sku}</p>
                                    <a className="delete is-large" onClick={() => this.onRowClick(item.id)}/>
                                </div>
                                <div className="column is-4 has-text-centered">
                                    <p className='is-size-5 has-text-weight-bold'>{item.product.name}</p>
                                    <p className='is-size-5'>{item.product.subType.name}</p>
                                </div>
                                <div className={classnames('column is-2 has-text-centered', styles.vertMiddle)}>
                                    <div className='level'>
                                        { item.productVariant.masterTypeId !== null &&
                                        <img src={require(`../../images/${item.productVariant.masterTypeId}-icon.png`)}/>
                                        }
                                        <p className='is-pulled-left'>{item.productVariant.qty}/{item.productVariant.packageTypeName} {item.productVariant.masterType}</p>
                                    </div>
                                </div>
                                <div className={classnames('column is-2 has-text-centered', styles.vertMiddle)}>
                                    <input className="input" value={item.qty} onChange={(event) => this.onQtyChange(event, index, item.id)} type='number' style={{height: '3rem', width: '5rem',}}/>
                                </div>
                                <div className={classnames('column is-1 has-text-centered', styles.vertMiddle)}>
                                    <p>${item.price.toFixed(2)}</p>
                                </div>
                                <div className={classnames('column is-2 has-text-centered', styles.vertMiddle)}>
                                    <p>${(item.price * item.qty).toFixed(2)}</p>
                                </div>
                            </div>
                            :
                            // mobile layout below
                            <div key={index} style={{backgroundColor: index%2 ===0 ? '' : '#fafafa', padding: '10px', position: 'relative'}}>
                                <p className='is-size-6' style={{marginBottom: '1rem',}}><b>SKU: </b>{item.product.sku}</p>
                                <a className="delete is-large" 
                                    onClick={() => this.onRowClick(item.id)}
                                    style={{ position: 'absolute', top: '10px', right: '10px',}}/>
                                <p className='is-size-5 has-text-weight-bold'>{item.product.name}</p>
                                <p className='is-size-6'>{item.product.subType.name}</p>
                                <div className='level is-mobile'>
                                    <div className='level-item'>
                                        { item.productVariant.masterTypeId !== null &&
                                        <img src={require(`../../images/${item.productVariant.masterTypeId}-icon.png`)}/>
                                        }
                                        <p style={{width: '40%',}}>{item.productVariant.qty}/{item.productVariant.packageTypeName} {item.productVariant.masterType}</p>
                                        <input className="input" value={item.qty} onChange={(event) => this.onQtyChange(event, index, item.id)} type='number' style={{height: '3rem', width: '5rem', }}/>
                                    </div>
                                </div>
                                <div className='level is-mobile'>
                                    <div className='level-item-left'>
                                        <p><b>Price: </b>${item.price.toFixed(2)}</p>
                                    </div>
                                    <div className='level-item-right'>
                                        <p><b>Subtotal: </b>${(item.price * item.qty).toFixed(2)}</p>
                                    </div>
                                </div>
                            </div>
                    ))}
                </div>
                <div className='level' style={{marginBottom: '2rem'}}>
                    <div className='level-item'>
                        <textarea 
                            className='textarea' 
                            placeholder={isBrowser ? 'Enter any comments to be sent to the supplier here.' : 'Comments for supplier'}
                            rows='1'
                            onChange={(e) => this.onCommentChange(e)}>{comment}</textarea>
                    </div>
                    <div className="level-item-right">
                        <p className="is-size-5 has-text-right has-text-weight-semibold" style={{margin: !isBrowser ? '' : '0 3rem',}}>Supplier Total &nbsp;&nbsp;&nbsp;${subTotal.toFixed(2)}</p>
                    </div>
                </div>
            </Tr>
        );
    }
}
export default CartRow;

CartRow.propTypes = {  
    order: PropTypes.object, 
    onRowClick: PropTypes.func,
    distributor: PropTypes.string,    
    distributorContactName: PropTypes.string,     
    distributorContactEmail: PropTypes.string, 
    customerPhone: PropTypes.string,
    getCustomerNum: PropTypes.func,
    lineItems: PropTypes.array,
    onCommentChange: PropTypes.func,
    onQtyChange: PropTypes.func,
    subTotal: PropTypes.number,
    comment: PropTypes.string,                   
};

CartRow.defaultProps = {  
    distributor: 'unknown distributor',  
    distributorContactName: '',     
    distributorContactEmail: '',
};
