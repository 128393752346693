import React, {Component} from 'react';
import SegmentedControl from '../../components/users-segmented';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import styles from './styles.scss';



class UserList extends Component {

    constructor(props){
        super(props);
        this.state = {
            currentSegment: 0,
        };
        this.toggleSegment = this.toggleSegment.bind(this);
        this.impersonate = this.impersonate.bind(this);
    }

    componentDidMount(){
        this.props.getRetailers();
        this.props.getDistributors();
    }

    toggleSegment(id){
        this.setState({
            currentSegment: id,
        });
    }

    impersonate(companyType, id, companyName){
        this.props.startImpersonating(companyType, id, companyName);
    }

    render () {
        const { retailersList, distributorsList, impersonationString } = this.props;
        const { currentSegment } = this.state;
        return (
            <div style={{ padding: '2rem', }}>
                <SegmentedControl onToggle={(id) => this.toggleSegment(id)}/>
                <div className='tile is-ancestor' style={{ flexWrap: 'wrap', padding: '2rem',}}>
                    { currentSegment===0 ?
                        retailersList.map((retailer) => (
                            <div
                                onClick={() => this.impersonate(1, retailer.id, retailer.name)} 
                                key={retailer.id} 
                                style={{ padding: '.5rem', }} 
                                className='tile is-3'>
                                <div className='box' style={{ width: '100%', backgroundColor: (impersonationString.split('|')[0] == 1 && retailer.id == impersonationString.split('|')[1]) ? '#ddd' : ''}}><b>{`${retailer.name} - ${retailer.companyType}:${retailer.id}`}</b><br />{retailer.contactEmail}</div>
                            </div> )) :
                        distributorsList.map((distributor) => (
                            <div 
                                onClick={() => this.impersonate(2, distributor.id, distributor.name)}
                                key={distributor.id} 
                                style={{ padding: '.5rem', }} 
                                className='tile is-3'>
                                <div className='box' style={{ width: '100%', backgroundColor: (impersonationString.split('|')[0] == 2 && distributor.id == impersonationString.split('|')[1]) ? '#ddd' : ''}}><b>{`${distributor.name} - ${distributor.companyType}:${distributor.id}`}</b><br />{distributor.contactEmail}</div>
                            </div> ))
                    }
                </div>
            </div>
        );
    }
}
     
export default withRouter(UserList);

UserList.propTypes = { 
    impersonationString: PropTypes.string, 
    retailersList: PropTypes.array, 
    distributorsList: PropTypes.array, 
    getRetailers: PropTypes.func, 
    getDistributors: PropTypes.func,
    startImpersonating: PropTypes.func,
};
