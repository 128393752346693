import {types as EventTypes} from '../events';
import {types as ProfileEventTypes} from '../../profile/events';
import basicReducer from '../../common/reducers/basic-reducer';
import constant from 'lodash/constant';

const initialState = false;

const reductionLookup = {
    [EventTypes.LoggedOut]: constant(initialState),
    
    [EventTypes.SignupSucceeded]: constant(true),
    [EventTypes.SignupFailed]: constant(initialState),
    [EventTypes.SignupRequested]: constant(initialState),
    [ProfileEventTypes.SaveProfileSucceeded]: constant(initialState),
};

export default basicReducer(initialState, reductionLookup);
