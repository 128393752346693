import {types as AuthEventTypes} from '../../authentication/events';
import {types as EventTypes} from '../events';
import basicReducer from '../../common/reducers/basic-reducer';

import constant from 'lodash/constant';

const initialState = [];

const reductionLookup = {
    [AuthEventTypes.LoggedOut]: constant(initialState),
    [EventTypes.GetRetailersFailed]: constant(initialState),
    [EventTypes.GetRetailersSucceeded]: (_, result) => result,
};

export default basicReducer(initialState, reductionLookup);