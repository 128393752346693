import request from './common/request';

const cartUrl = 'https://api.buyfluidly.com/v1/cart';
const ordersUrl = 'https://api.buyfluidly.com/v1/orders';


export default {
    
    addComment: (userToken, orderId, commentString, impersonationString) => {
        return request({
            method: 'POST',
            url: `${ordersUrl}/${orderId}/comments`,
            payload: { commentText: commentString },
            userToken,
            impersonationString,
        });
    },

    deleteComment: (userToken, orderId, commentId, impersonationString) => {
        return request({
            method: 'DELETE',
            url: `${ordersUrl}/${orderId}/comments/${commentId}`,
            userToken,
            impersonationString,
        });
    },

    loadComments: (userToken, orderId, impersonationString) => {
        return request({
            method: 'GET',
            url: `${ordersUrl}/${orderId}/comments`,
            userToken,
            impersonationString,
        });
    },

    addLineItem: (userToken, itemObj, impersonationString) => {
        return request({
            method: 'POST',
            url: cartUrl,
            payload: itemObj,
            userToken,
            impersonationString,
        });
    },

    submitCart: (userToken, impersonationString) => {
        return request({
            method: 'POST',
            url: `${cartUrl}/submit`,            
            userToken,
            impersonationString,
        });
    },

    updateCartItem: (userToken, id, value, impersonationString) => {
        return request({
            method: 'POST',
            url: `${cartUrl}/items/${id}`,  
            payload: { qty: value },          
            userToken,
            impersonationString,
        });
    },

    verifyOrder: (userToken, orderId, impersonationString) => {
        return request({
            method: 'POST',
            url: `${ordersUrl}/${orderId}/verify`,            
            userToken,
            impersonationString,
        });
    },

    deleteLineItem: (userToken, id, impersonationString) => {
        return request({
            method: 'DELETE',
            url: `${cartUrl}/items/${id}`,
            userToken,
            impersonationString,
        });
    },

    loadCart: (userToken, impersonationString) => {
        return request({
            method: 'GET',
            url: cartUrl,
            userToken,
            impersonationString,
        });
    },

    loadOrderHeaders: (userToken, impersonationString) => {
        return request({
            method: 'GET',
            url: ordersUrl,
            userToken,
            impersonationString,
        });
    },

    loadOrderDetails: (userToken, orderId, impersonationString) => {
        return request({
            method: 'GET',
            url: `${ordersUrl}/${orderId}`,
            userToken,
            impersonationString,
        });
    },

    cartSummary: (userToken, impersonationString) => {
        return request({
            method: 'GET',
            url: `${cartUrl}/summary`,
            userToken,
            impersonationString,
        });
    },
};