import {combineReducers} from 'redux';

import currentUsername from './current-username';
import currentUsertype from './current-usertype';
import companyId from './company-id';
import retailerType from './current-retailertype';
import error from './error';
import isLoading from './is-loading';
import isSigningUp from './is-signing-up';
import refreshTokenTimeoutId from './refresh-token-timeout-id';
import token from './token';
import isAdmin from './is-admin';

export default combineReducers({
    companyId,
    currentUsername,
    currentUsertype,
    error,
    isAdmin,
    isLoading,
    isSigningUp,
    refreshTokenTimeoutId,
    retailerType,
    token,
});
