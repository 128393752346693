exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-components-multi-checkbox-styles--wrapper {\n  padding: .5rem 0 0 0; }\n\n.src-components-multi-checkbox-styles--label {\n  padding: 1rem 0 1rem 0; }\n\n/* Customize the label (the container) */\n.src-components-multi-checkbox-styles--container {\n  display: block;\n  position: relative;\n  padding-left: 35px;\n  margin-bottom: 12px;\n  cursor: pointer;\n  font-size: 22px;\n  -webkit-user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none;\n  user-select: none; }\n\n/* Hide the browser's default checkbox */\n.src-components-multi-checkbox-styles--container input {\n  position: absolute;\n  opacity: 0;\n  cursor: pointer; }\n\n/* Create a custom checkbox */\n.src-components-multi-checkbox-styles--checkmark {\n  position: absolute;\n  top: .4rem;\n  left: 0;\n  height: 25px;\n  width: 25px;\n  border-style: solid;\n  border-width: thin;\n  border-color: #ccc; }\n\n/* On mouse-over, add a grey background color */\n.src-components-multi-checkbox-styles--container:hover input ~ .src-components-multi-checkbox-styles--checkmark {\n  border-color: #888; }\n\n/* Create the checkmark/indicator (hidden when not checked) */\n.src-components-multi-checkbox-styles--checkmark:after {\n  content: \"\";\n  position: absolute;\n  display: none; }\n\n/* Show the checkmark when checked */\n.src-components-multi-checkbox-styles--container input:checked ~ .src-components-multi-checkbox-styles--checkmark:after {\n  display: block; }\n\n/* Style the checkmark/indicator */\n.src-components-multi-checkbox-styles--container .src-components-multi-checkbox-styles--checkmark:after {\n  left: 7px;\n  top: 0;\n  width: 10px;\n  height: 20px;\n  border: solid #888;\n  border-width: 0 3px 3px 0;\n  -webkit-transform: rotate(45deg);\n  -ms-transform: rotate(45deg);\n  transform: rotate(45deg); }\n", ""]);

// exports
exports.locals = {
	"wrapper": "src-components-multi-checkbox-styles--wrapper",
	"label": "src-components-multi-checkbox-styles--label",
	"container": "src-components-multi-checkbox-styles--container",
	"checkmark": "src-components-multi-checkbox-styles--checkmark"
};