exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-components-table-row-styles--inStock {\n  color: green; }\n\n.src-components-table-row-styles--outOfStock {\n  color: red; }\n", ""]);

// exports
exports.locals = {
	"inStock": "src-components-table-row-styles--inStock",
	"outOfStock": "src-components-table-row-styles--outOfStock"
};