import { connect } from 'react-redux';

import Component from './component';
import Commands from 'state/commands';
import fromState from 'state/selectors';

function mapStateToProps(state) {
    return {
        activePage: fromState.Catalog.productsActivePage()(state),
        isSearching: fromState.Catalog.isSearching()(state),
        brands: fromState.Catalog.catalogBrands()(state),
        regions: fromState.Catalog.catalogRegions()(state),
        categories: fromState.Catalog.catalogCategories()(state),
        productTypes: fromState.Catalog.productTypes()(state),
        subTypes: fromState.Catalog.subTypes()(state),
        subRegions: fromState.Catalog.subRegions()(state),
        packagingTypes: fromState.Catalog.packagingTypes()(state),
        products: fromState.Catalog.products()(state),
        currentUsertype: fromState.Authentication.currentUsertype()(state), 
        retailerType: fromState.Authentication.retailerType()(state), 
    };
}

function mapDispatchToProps(dispatch) {
    return {
        loadCatalogBrands: () => dispatch(Commands.Catalog.LoadCatalogBrands()),
        loadRegions: () => dispatch(Commands.Catalog.LoadRegions()),
        loadCatalogCategories: () => dispatch(Commands.Catalog.LoadCatalogCategories()),
        loadCatalogCategoryProductTypes: (categoryId) => dispatch(Commands.Catalog.LoadCatalogCategoryProductTypes(categoryId)),
        loadSubTypes: (categoryId, typeId) => dispatch(Commands.Catalog.LoadSubTypes(categoryId, typeId)), 
        loadSubRegions: (id) => dispatch(Commands.Catalog.LoadSubRegions(id)), 
        loadPackagingTypes: (id) => dispatch(Commands.Catalog.LoadPackagingTypes(id)),
        onLogout: () => dispatch(Commands.Authentication.Logout()),
        searchCatalogProducts: (distId, productName, brandId, categoryId, typeId, subTypeId, regionId, subRegionId, packageTypeId, priceLow, priceHigh) => 
            dispatch(Commands.Catalog.SearchCatalogProducts(distId, productName, brandId, categoryId, typeId, subTypeId, regionId, subRegionId, packageTypeId, priceLow, priceHigh)),     
        setActivePage: (value) => dispatch(Commands.Catalog.StoreActivePage(value)), 
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(Component);