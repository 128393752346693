import PropTypes from 'prop-types';
import React from 'react';

function Checkbox({onChange, value}) {
    return (
        <input type="checkbox" onChange={v => onChange(v.target.checked)} checked={value}/>
    );
}

Checkbox.propTypes = {
    className: PropTypes.any,
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    hasError: PropTypes.bool,
    customClass: PropTypes.any,
    disabled: PropTypes.bool,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
    ])
};


export default Checkbox;