import selectorsCommon from 'state/common/selectors';

import get from 'lodash/get';

export default selectorsCommon.bindToReducer('admin', {
    impersonationName: () => (state) => get(state, 'impersonationName', ''),
    impersonationString: () => (state) => get(state, 'impersonationString', ''),
    isImpersonating: () => (state) => get(state, 'isImpersonating', false),
    retailersList: () => (state) => get(state, 'retailersList', []),
    distributorsList: () => (state) => get(state, 'distributorsList', []),
});
