import selectorsCommon from 'state/common/selectors';

import get from 'lodash/get';

export default selectorsCommon.bindToReducer('authentication', {
    currentToken: () => (state) => get(state, 'token.currentToken', ''),
    currentUsername: () => (state) => get(state, 'currentUsername', ''),
    currentUsertype: () => (state) => get(state, 'currentUsertype', ''),
    companyId: () => (state) => get(state, 'companyId', ''),
    retailerType: () => (state) => get(state, 'retailerType', ''),
    error: () => (state) => get(state, 'error', ''),
    isAdmin: () => (state) => get(state, 'isAdmin', false),
    isLoading: () => (state) => get(state, 'isLoading', false),
    isSigningUp: () => (state) => get(state, 'isSigningUp', false),
    refreshToken: () => (state) => get(state, 'token.refreshToken', ''),
    refreshTokenTimeoutId: () => (state) => get(state, 'refreshTokenTimeoutId', ''),
    expiresIn: () => (state) => get(state, 'token.expiresIn', 0),
});
