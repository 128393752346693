import { connect } from 'react-redux';

import Component from './component';
import Commands from 'state/commands';
import fromState from 'state/selectors';

function mapStateToProps(state) {
    return {
        cart: fromState.Cart.cart()(state),
        packagingTypes: fromState.Catalog.packagingTypes()(state),
        productDetails: fromState.Catalog.productDetails()(state),
        productLinks: fromState.Catalog.productLinks()(state),
        productVariants: fromState.Catalog.productVariants()(state),
        retailerId: fromState.Profile.retailerId()(state),
        repsForDist: fromState.Profile.repsForDist()(state),
        companyId: fromState.Authentication.companyId()(state),
        retailerType: fromState.Authentication.retailerType()(state),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        addLineItem: (productId, productVariantId, price, qty) => dispatch(Commands.Cart.AddLineItem(productId, productVariantId, price, qty)),
        loadCart: () => dispatch(Commands.Cart.LoadCart()),
        getProfile: () => dispatch(Commands.Profile.GetProfile()),
        getRepsForDist: (distributorId) => dispatch(Commands.Profile.GetRepsForDist(distributorId)),
        hasAccount: (distributorId, retailerId) => dispatch(Commands.Profile.HasAccount(distributorId, retailerId)),
        loadPackagingTypes: (id) => dispatch(Commands.Catalog.LoadPackagingTypes(id)),      
        loadProductDetails: (productId) => dispatch(Commands.Catalog.LoadProductDetails(productId)),
        loadProductLinks: (productId) => dispatch(Commands.Catalog.LoadProductLinks(productId)),  
        loadProductVariants: (productId) => dispatch(Commands.Catalog.LoadProductVariants(productId)),
        updateRepInfo: (distributorId, retailerId, newRepId, newCustomerNum) => 
            dispatch(Commands.Profile.UpdateRepInfo(distributorId, retailerId, newRepId, newCustomerNum)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
