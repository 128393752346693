import {combineReducers} from 'redux';

import fieldErrors from './field-errors';
import fieldValues from './field-values';
import hasAttemptedToSave from './has-attempted-to-save';
import isLoading from './is-loading';
import isSaved from './is-saved';
import isSaving from './is-saving';
import states from './states';
import counties from './counties';
import postRep from './post-rep';
import reps from './reps';
import profile from './profile';
import customerNum from './customer-number';
import territories from './territories';


export default combineReducers({
    counties,
    customerNum,
    fieldErrors,
    fieldValues,
    hasAttemptedToSave,
    isLoading,
    isSaved,
    isSaving,
    postRep,
    profile,
    states,
    territories,
    reps,
});
