import { connect } from 'react-redux';

import Component from './component';
import Commands from 'state/commands';
import fromState from 'state/selectors';

import merge from 'lodash/merge';
import set from 'lodash/set';

const fields = [
    'address',
    'address2',
    'agreed',
    'businessType',
    'city',
    'companyName',
    'county',
    'counties',
    'ein',
    'email',
    'fullName',
    'phone',
    'state',
    'states',
    'website',
    'zip',
];

function mapStateToProps(state) {

    return {
        fields: fields.reduce((acc, key) => set(acc, key, {
            error: fromState.Profile.fieldError(key)(state),
            value: fromState.Profile.fieldValue(key)(state),
        }), {}),
        isSaved: fromState.Profile.isSaved()(state),
        statesList: fromState.Profile.getStates()(state),
        counties: fromState.Profile.getCounties()(state),
        reps: fromState.Profile.getReps()(state),
        territories: fromState.Profile.territories()(state),

    };
}

function mapDispatchToProps(dispatch) {
    return {
        fields: fields.reduce((acc, key) => set(acc, key, {
            onChange: value => dispatch(Commands.Profile.UpdateField(key, value)),
        }), {}),
        save: () => dispatch(Commands.Profile.Save()), 
        loadCounties: (states) => dispatch(Commands.Profile.LoadCounties(states)),
        getReps: () => dispatch(Commands.Profile.GetReps()),
        postRep: (repObject) => dispatch(Commands.Profile.PostRep(repObject)),
        deleteRep: (id) => dispatch(Commands.Profile.DeleteRep(id)),
        postTerritories: (arr) => dispatch(Commands.Profile.PostTerritories(arr)),
        getTerritories: () => dispatch(Commands.Profile.GetTerritories()),
    };
}

function mergeProps(stateProps, dispatchProps) {
    return merge(stateProps, dispatchProps);
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Component);