import getFp from 'lodash/fp/get';
import flow from 'lodash/flow';
import fromPairs from 'lodash/fromPairs';
import toPairs from 'lodash/toPairs';
import mapValues from 'lodash/fp/mapValues';
import reduce from 'lodash/reduce';

const nestedSelectorOp = key => selector => (...params) => flow(
    getFp(key),
    selector(...params)
);

function splitNestedSelector([key, nestedSelectorObject]) {
    return flow(
        mapValues(nestedSelectorOp(key)),
        toPairs
    )(nestedSelectorObject);
}

const bindToReducer = (reducer, selectors) => flow(
    splitNestedSelector,
    fromPairs
)([reducer, selectors]);

export default {
    bindToReducer,
    lookup: property => (...keys) => state => reduce(keys, (lookup, key) => getFp(key)(lookup), getFp(property)(state)),
};