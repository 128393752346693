import React from 'react';
import styles from './styles.scss';
import Dropdown from '../../components/dropdown';
import TextInput from '../../components/text-input';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import get from 'lodash/get';
import { withAlert } from 'react-alert';
import { isMobileOnly, isTablet,  isBrowser} from 'react-device-detect';

class Signup extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            email: '',
            password: '',
            password2: '',
            usertype: '',
            isSignup:  this.props.location.state.isSignup,
            formErrors: {email: '', password: '', passwordMatch: '', },
            emailValid: false,
            passwordValid: false,
            passwordMatch: false,
            formValid: false,
            dropdownTitle: 'Select a type...',
            typeSelected: false,
            type: '',
            ein: props.ein,
        };

        this.onClick = this.onClick.bind(this);
        this.handleUserInput = this.handleUserInput.bind(this);
    }


    errorClass(error) {
        return(error.length === 0 ? '' : 'has-error');
    }

    attemptSignup() {
        if(this.state.type !== '') {
            const isConsumer = this.state.type == 0;
            this.props.onSignup(this.state.email, this.state.password, isConsumer ? '1' : '2')
                .then((result) => {
                    if(get(result, 'type', '') === '') {
                        this.props.history.push(isConsumer ? '/consumer-profile' : '/distributor-profile');
                    } else if (get(result, 'type', '') === 'authentication-signup-failed') {
                        this.props.alert.show(
                            <div style={{padding: isMobileOnly ? '1rem' : '3rem',}}>{get(result, 'payload')}</div>,
                            {
                                timeout: 4000,  
                                type: 'success',
                                onClose: () => {} 
                            });
                    }
                });
        }
    }

    attemptLogin() {
        this.props.onLogin(this.state.email, this.state.password).then((result) => {
            if(get(result, 'type') === 'authentication-authentication-failed'){
                this.props.alert.show(
                    <div style={{padding: isMobileOnly ? '1rem' : '3rem',}}><b>Login Failed: </b>{get(result, 'payload')}</div>,
                    {
                        timeout: 4000,  
                        type: 'success',
                        onClose: () => {} 
                    });

            } else {
                this.props.getProfile();
                this.props.history.push(this.props.usertype == 1 ? '/consumer-dashboard' : '/distributor-dashboard');
            }
        });
    }

    submit() {
        if (!this.state.formValid) {
            let message = 'Please be sure to fill out all required fields and select an account type.';
            if (this.state.formErrors.email !== '') message = this.state.formErrors.email; 
            else if (this.state.formErrors.password !== '') message = this.state.formErrors.password; 
            else if (this.state.formErrors.passwordMatch !== '' && this.state.isSignup) message = this.state.formErrors.passwordMatch;
            this.props.alert.show(
                <div style={{padding: '3rem',}}>{message}</div>,
                {
                    timeout: 4000,  
                    type: 'success',
                    onClose: () => {} 
                }); 
        } else {
            this.state.isSignup ? this.attemptSignup() : this.attemptLogin();
        }
    }

    onClick() {
        const { isSignup } = this.state;
        this.setState({ 
            isSignup: !isSignup, 
            email: '',
            password: '',
            password2: '',
        });
    }  

    validateForm() {
        this.setState({formValid: (this.state.isSignup) ? 
            this.state.emailValid && this.state.passwordValid && this.state.passwordMatch && this.state.typeSelected 
            : this.state.emailValid && this.state.passwordValid });
    }

    validateField(fieldName, value) {
        const fieldValidationErrors = this.state.formErrors;
        let emailValid = this.state.emailValid;
        let passwordValid = this.state.passwordValid;
        let passwordMatch = this.state.passwordMatch;
        let typeSelected = this.state.typeSelected;
        const emailRegex = new RegExp(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        const passRegex = new RegExp(/^(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,}$/);
        switch(fieldName) {
            case 'email':
                emailValid = emailRegex.test(value);
                fieldValidationErrors.email = (emailValid  || value==='') ? '' : 'email is invalid';
                break;
            case 'password':
                passwordValid = passRegex.test(value);
                fieldValidationErrors.password = (passwordValid || value==='')
                    ? '': 'password must be at least eight characters including one uppercase letter, one number, and one special character';
                passwordMatch = this.state.password === this.state.password2;
                fieldValidationErrors.passwordMatch = passwordMatch ? '': 'passwords do not match';
                break;
            case 'password2':
                passwordMatch = this.state.password === this.state.password2;
                fieldValidationErrors.passwordMatch = passwordMatch ? '': 'passwords do not match';
                break;
            case 'dropdown':
                typeSelected = this.state.type !== '';
                fieldValidationErrors.typeSelected = typeSelected ? '': 'Select an account type';
                break;
            default:
                break;
        }
        this.setState({formErrors: fieldValidationErrors,
            emailValid: emailValid,
            passwordValid: passwordValid,
            passwordMatch: passwordMatch,
            typeSelected: typeSelected,
        }, this.validateForm);
    }

    handleUserInput (e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({[name]: value}, 
            () => { this.validateField(name, value); });
    }

    handleDropdown (value) {
        this.setState({
            type: value,
            typeSelected: true,
        }, () => {
            this.validateField('dropdown', value);
        });
    }

    render(){
        const types =  [ 
            [0, 'Retailer'],
            [1, 'Distributor'],
        ];
        return (
            <div>
                <div className={styles.wrapper}>
                    <div className="columns">
                        <div className="column is-half">
                            {this.state.isSignup &&
                            <div style={{marginBottom: '1rem',}}>
                                <div className="is-size-6" style={{marginBottom: '.5rem',}}>Account Type</div>
                                <Dropdown
                                    value={this.state.type}
                                    options={types} 
                                    onChange={(value) => this.handleDropdown(value)}
                                    placeholder="Select Account Type" 
                                    fieldName='dropdown'
                                    errorMessage={this.state.formErrors.typeSelected} />
                            </div>
                            }
                            <form onSubmit={(e) => {this.submit(); e.preventDefault();}}>
                                <TextInput 
                                    className={`${this.errorClass(this.state.formErrors.email)}`} 
                                    labelText="Email Address:" fieldName="email" type="email" value={this.state.email} 
                                    errorMessage={this.state.formErrors.email}
                                    childChange={(event) => this.handleUserInput(event)} />
                                <TextInput 
                                    className={`${this.errorClass(this.state.formErrors.password)}`}
                                    labelText="Password:" fieldName="password" type="password" value={this.state.password} 
                                    errorMessage={this.state.formErrors.password}
                                    childChange={(event) => this.handleUserInput(event)}/>
                                { this.state.isSignup &&
                                <TextInput 
                                    className={`${this.errorClass(this.state.formErrors.passwordMatch)}`}
                                    labelText="Confirm Password:" fieldName="password2" type="password" value={this.state.password2}
                                    errorMessage={this.state.formErrors.passwordMatch} 
                                    childChange={(event) => this.handleUserInput(event)} />
                                }
                            
                                <input className="button is-fullwidth" style={{marginTop: '3rem'}} 
                                    type="submit" value="Continue"/>
                            </form>
                        </div>
                        <div className={classnames('column is-half', styles.wrapper)}>
                            {this.state.isSignup && 
                            <div>
                                <div className="title is-hidden-mobile">Get Ready for Simple.</div>
                                <div className={classnames('subtitle is-hidden-mobile', styles.subtext)}>You are just a few clicks away from accessing a simple, yet powerful beverage supply ordering platform. Simple is better.<br /><br />It couldn&apos;t be easier. Sign up now.</div>
                                <div className="button is-text is-paddingless" onClick={this.onClick}>I already have an account</div>
                            </div>
                            }
                            {!this.state.isSignup && 
                            <div>
                                <div className="title is-hidden-mobile">Welcome Back</div>
                                <div className={classnames('subtitle is-hidden-mobile', styles.subtext)}>You know what to do.</div>
                                <div className="button is-text is-paddingless" onClick={this.onClick}>I need an account.</div>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Signup.propTypes = {  
    alert: PropTypes.object,
    isSignup: PropTypes.bool,
    email: PropTypes.string,
    getProfile: PropTypes.func,
    onLogin: PropTypes.func.isRequired,
    props: PropTypes.object,
    location: PropTypes.object,
    password: PropTypes.string,
    formErrors: PropTypes.object,
    emailValid: PropTypes.bool,
    passwordValid: PropTypes.bool,
    passwordMatch: PropTypes.bool,
    formValid: PropTypes.bool,
    onSignup: PropTypes.func.isRequired,
    typeSelected: PropTypes.bool,
    usertype: PropTypes.string,
    ein: PropTypes.string,
    history: PropTypes.object,
};


Signup.defaultProps = {
    isSignup: true,
    emailValid: false,
    passwordValid: false,
    formValid: false,
    typeSelected: false,
};

export default withAlert(withRouter(Signup));
