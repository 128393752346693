import React, {Component} from 'react';
import styles from './styles.scss';
import { withRouter } from 'react-router';



class TermsAndConditions extends Component {
  render () {

    const style = {
        margin: "20px"
   
      }
      
      const style1 = {
          textAlign: "center",
          fontSize: "35px",
          fontWeight: "bold",
          marginTop: "20px"
      }

      const style2 = {
        fontSize: "18px",
        fontWeight: "bold"
    }

    return (
        <div style={{padding: '2rem',}}>
            <h1 style={style1}>Terms Of Service</h1>
            <p style={style}>
            Please read these Terms of Service (collectively with BuyFluidly, Inc.’s Privacy
 Policy www.buyfluidly.com/privacypolicy, the “Terms of Service”) fully and carefully before
 using https:/buyfluidly.com (“BuyFluidly”) and the services, features, content or applications
  offered by BuyFluidly, Inc. (“we”, “us” or “our”) (the “Services”). These Terms of
 Service set forth the legally binding terms and conditions for your use of BuyFluidly and the Services.
 If you are using the Services in connection with or on behalf of an entity’s use of the Services (“Entity”), 
 then by using the Services in any manner, both you and the Entity you represent agree to be bound by the terms 
 and conditions of these Terms of Service. You hereby represent and warrant that you have full power and authority 
 to bind the Entity to these Terms of Service. If you provide to us any personally identifiable information in connection with your
 use and the Entity’s use of the Services, such as name and contact information of the Entity’s personnel members, then you 
 and the Entity hereby represent and warrant that you have the full rights and authority to provide to us such personally identifiable 
 information.</p>
 <p style={style}><span style={style2}>1. Acceptance of Terms of Service.</span> <br/>
 a. By registering for and/or using the Services in any manner, including but not
 limited to visiting or browsing the Sites, you agree to these Terms of Service
 and all other operating rules, policies and procedures that may be
 published from time to time on the Site by us, each of which is incorporated by reference and each of which may be updated from time to time without notice to you.
 b. Certain features of the Services may be subject to additional terms and conditions specified by us from time to time; your use of such Services is subject to
 those additional terms and conditions, which are incorporated into these Terms of Service by this reference.
 c. These Terms of Service apply to all users of the Services, including, without limitation, users who are contributors of content, information, and other
 materials or services, registered or otherwise.</p>
 <p style={style}><span style={style2}>2. Eligibility.</span> <br/>By using the relevant Services offered by us, you represent and
 warrant that you have all necessary licenses from applicable state authorities to
 buy and/or sell alcoholic beverages on a commercial basis, and all such licenses
 are in good standing and in full force and effect. We may, in our sole discretion,
 refuse to offer the Services to any person or entity and change its eligibility
 criteria at any time. You are solely responsible for ensuring that these Terms of
 Service are in compliance with all laws, rules and regulations applicable to you
 and the right to access the Services is revoked where these Terms of Service or
 use of the Services is prohibited or to the extent offering, sale or provision of the Services conflicts with any applicable law, rule or regulation. Further, the
 Services are offered only for your use, and not for the use or benefit of any third
 party.</p>
 <p style={style}><span style={style2}>3. Registration.</span> <br/> To sign up for certain Services, you may be required to register for
 an account on the Services (an “Account”).You must provide accurate and
 complete information and keep your Account information updated. You shall
 not: (i) select or use as a username a name of another person with the intent to impersonate that person; (ii) use as a username a name subject to any rights of
 a person other than you without appropriate authorization; or (iii) use, as a
 username, a name that is otherwise offensive, vulgar or obscene. You are solely
 responsible for the activity that occurs on your Account, and for keeping your Account password secure. 
 You may never use another person’s user account or registration information for the Services without permission. 
 By registering for an Account on behalf of an establishment, you represent and warrant that you are authorized to 
 act on such establishment’s behalf with respect to orders made through the Account. You must notify us immediately 
 of any change in your eligibility to use the Services (including any changes to or revocation of any licenses from state authorities), 
 breach of security or unauthorized use of your Account. You should never publish, distribute or post login information for your Account. 
 You shall have the ability to delete your Account, either directly or through a request made to one of our employees or affliates.</p>
 <p style={style}><span style={style2}>4. Ordering Services.</span> <br/> If you opt in to use the Services to place orders on behalf of an Account (the “Ordering Services”), all users associated with the Account will be notified that your Account status has changed to enable you to use the Ordering Services. Distributors have the option to reject any order they deem to be invalid. We make no guarantees regarding tthe availability, pricing,
 accuracy or timely delivery of products that are ordered through the
 Ordering Services. Furthermore, information made available through the Services does not constitute an offer. Acceptance of any order is at the distributor’s sole discretion, and may be subject to variations in pricing or stock, depending on the distributor’s current availability. Any dispute regarding an
 order must be resolved exclusively between you and the applicable distributor, and you hereby fully release us from any claims regarding the same.</p>

 <p style={style}><span style={style2}>5. Order Entry Tool.</span> <br/> If you use the Order Entry Tool on the Services, then you hereby agree to the terms in this section. Your User Content (as defined below) shall include without 
     limitation all order history and other data uploaded to the BuyFluidly’s platform available through the Services (the “Buy Fluidly Platform”) in connection with the Order Entry Tool. 
     You shall be responsible for the acts and omissions (including without any limitation any breach of these Terms of Service) of any person who accesses the Services using passwords or access 
     procedures provided to or created by you. BuyFluidly and its licensors retain and shall own all right, title, and interest in and to (i) the Order Entry Tool and the
 Buy Fluidly Platform; (ii) the Aggregated Anonymous Data; (iii) all orders sent by buyers through the BuyFluidly Platform to distributors, and (iv) all copies, modifications and derivative works thereof, 
 and all software, data, products, works, analyses and other intellectual property and moral rights related to the foregoing or created, used, or provided by BuyFluidly in connection with the Order Form. 
 BuyFluidly may collect information from users through the BuyFluidly Platform, such as through user accounts and activity on the BuyFluidly Platform (“Analytics”). You acknowledge and agree that BuyFluidly may (a) 
 internally use Analytics for the purpose of improving the Services; (b) may (a) internally use Analytics for the purpose of improving the Services; (b) generate Aggregated Anonymous Data; and (c) freely use and make available 
 Aggregated Anonymous Data for BuyFluidly’s business purposes. </p>
 
 <p style={style}>“Aggregated Anonymous Data” means data submitted to, collected by or generated by BuyFluidly in connection with your use of the BuyFluidly Platform, but only in aggregate, anonymized form which can in no way be linked specifically to you
 or the Entity you represent. Upon any termination of any order form for the
 Order Entry Tool, or these Terms of Service, the license granted to you to use the Order Entry Tool shall terminate and you shall immediately cease use of the Order Entry Tool. The terms in this section are in addition to and do not limit the other terms in these Terms of Service. 
 The terms of this section shall survive any termination of any order form for the Order Entry Tool or these Terms of Service.</p> 
 <p style={style}><span style={style2}>6. Content</span></p>
 <p style={style}>a. Definition.  <br/> For purposes of these Terms of Service, the term “Content” includes, without limitation, information, data, text, photographs, videos, 
 audio clips, wrtten posts and comments, software, scripts, graphics, and interactive features generated, provided, or otherwise made accessible 
 on or through the Services. For the purposes of this Agreement, “Content” also includes all User Content (as defined below).</p>
 <p style={style}><span style={style2}>b. User Content.</span>  <br/> All Content added, created, uploaded, submitted, distributed, or posted to the Services by users (collectively “User Content”), whether publicly posted or privately transmtted, is the sole responsibility
 of the person who originated such User Content. You represent that all User Content provided by you is accurate, complete, up-to-date, and in compliance with all applicable laws, rules and regulations. You
 acknowledge that all Content, including User Content, accessed by you using the Services is at your own risk and you will be solely responsible for any damage or loss to you or any other party resulting therefrom. We do
 not guarantee that any Content you access on or through the Services is or will continue to be accurate.</p>
 <p style={style}><span style={style2}>c. Feedback.</span>  <br/> Any feedback, reviews, comments, and suggestions or recommendations for modifications, 
 improvements or changes to the Services or the Sites that you may choose in your sole discretion to provide us from time 
 to time (“Feedback”) shall be solely owned by us (including all intellectual property rights therein and thereto). 
 You hereby assign and agree to assign all right, title and interest in and to the Feedback to us.</p>
 <p style={style}><span style={style2}>d. Notices and Restrictions.</span>   <br/> The Services may contain Content specifically provided by us, our partners or our users and 
 such Content is protected by copyrights, trademarks, service marks, patents, trade secrets or other proprietary rights
  and laws. You shall abide by and maintain all copyright notices, information, and restrictions contained in any Content 
  accessed through the Services.</p>
  <p style={style}><span style={style2}>e. Use License.</span>   <br/> Subject to these Terms of Service, we grant each user of the Services a worldwide, non-exclusive, 
 non-sublicensable and nontransferable license to use (i.e., to download and display locally) Content
 solely for purposes of using the Services. Use, reproduction, modification, distribution or storage of any Content 
 for other than purposes of using theServices is expressly prohibited without prior written permission from us.
 You shall not sell, license, rent, or otherwise use or exploit any Content for commercial use or in any way 
 that violates any third party right.</p>
 <p style={style}><span style={style2}>f. License Grant.</span>   <br/> By submtting User Content through the Services, you
 hereby do and shall grant us a worldwide, non-exclusive, perpetual, royaltyfree, fully paid, sublicensable and transferable license to use, edit, modify,
 truncate, aggregate, reproduce, distribute, prepare derivative works of, display, perform, and otherwise fully exploit the User Content in connection with the Sites, the Services and our (and our successors’ and assigns’) businesses, including without limitation for promoting and redistributing
 part or all of the Sites or the Services (and derivative works thereof) in any media formats and through any media channels (including, without limitation, third party websites and feeds), and including a!er your termination of your Account or the Services. You also hereby do and shall grant each user of the Sites and/or the Services a non-exclusive, perpetual license to access your User Content through the Sites and/or the Services, and to use, edit, modify, reproduce, distribute, prepare derivative works of, display and perform such User Content, including after your termination of your Account or the Services. For clarity, the foregoing license grants to us and our users does not affect your other ownership or license rights in your User Content, including the right to grant additional licenses to your User Content, including the right to grant additional licenses to your User
 Content, unless otherwise agreed in writing. You represent and warrant that you have all rights to grant such licenses to us without 
infringement or violation of any third party rights, including without limitation, any privacy rights, publicity rights, copyrights, 
trademarks, contract rights, or any other intellectual property or proprietary rights.</p>
<p style={style}><span style={style2}>g. Confidential Contact Information.</span>  <br/> To the extent you submit any
 personal contact information for your retail buyers through the Service in a manner whereby such information is set to be private to you (“Confidential Contact Information”), we agree that we will not make such information generally accessible to all users of the Service without your authorization. Notwithstanding the foregoing, Confidential Contact Information shall not include information that is (x) known to us prior to disclosure by you, (y) disclosed to us by a third party without restriction, or (z) generally
 available to the public.</p>
 <p style={style}><span style={style2}>h. Availability off Content.</span>  <br/> We do not guarantee that any Content will be made available on the Sites or through the 
 Services. We reserve the right to, but do not have any obligation to, (i) remove, edit or modify any Content in our sole discretion, 
 at any time, without notice to you and for any reason (including, but not limited to, upon receipt of claims or allegations from
 third parties or authorities relating to such Content or if we are concerned that you may have violated these Terms of Service), or 
 for no reason at all and (ii) to remove or block any Content from the Services.</p>
 
 <p style={style}><span style={style2}>i. BuyFluidly Content.</span>  <br/> You may download or copy the materials
 published on BuyFluidly (including but not limited to news articles, photographs, images, illustrations, audio clips and video clips, also known
 as the “BuyFluidly Content”) and other downloadable items
 displayed on BuyFluidly for personal use only, provided you maintain
 all copyright and other notices contained therein. Copying or storing of
 any BuyFluidly for other than personal use is expressly
 prohibited without our prior written permission.</p>
 <p style={style}><span style={style2}>j. WARNING.. CERTAIN CONTENT PROVIDED THROUGH THE SERVICES IS PROVIDED BY THIRD PARTY VENDORS AND IS NOT GUARANTEED TO BE ACCURATE OR UP-TO-DATE. ALL PRODUCTS LISTED ARE SUBJECT TO ACTUAL AVAILABILITY AND TO ALL APPLICABLE
 LAWS, RULES AND REGULATIIONS..</span> </p>
 <p style={style}><span style={style2}>7. Rules of Conduct.</span></p>
 <p style={style}>a. As a condition of use, you represent, warrant and covenant not to use the Services for any purpose that is prohibited by these Terms of Service or applicable laws, rules and regulations applicable to you. You are
 responsible for all of your activity in connection with the Services.</p>
 <p style={style}>b. You shall not (and shall not permit any third party to) either (a) take any action or (b) 
 upload, download, post, submit or otherwise distribute or facilitate distribution of any Content on or through 
 the Service, including without limitation any User Content, that:</p>
 <p style={style}>i. infringes any patent, trademark, trade secret, copyright, right of
 publicity or other right of any other person or entity or violates any
 law or contractual duty;</p>
 <p style={style}>ii. you know is false, misleading, untruthful or inaccurate;</p>
 <p style={style}>iii. is unlawful, threatening, abusive, harassing, defamatory, libelous, deceptive, 
 fraudulent, invasive of another's privacy, tortious, obscene,
 vulgar, pornographic, offensive, profane, contains or depicts nudity,
 contains or depicts sexual activity, or is otherwise inappropriate as determined by us in our sole discretion;</p>
 <p style={style}>iv. constitutes unauthorized or unsolicited advertising, junk or bulk e-mail (“spamming”);</p>
 <p style={style}>v. contains software viruses or any other computer codes, files, or
 programs that are designed or intended to disrupt, damage, limit or
 interfere with the proper function of any so!ware, hardware, or telecommunications equipment or to damage or obtain unauthorized
 access to any system, data, password or other information of ours or of
 any third party;</p>
 <p style={style}>vi. impersonates any person or entity, including any of our employees or representatives; or</p>
 <p style={style}>vii. includes anyone’s identification documents or sensitive financial information.</p>
 <p style={style}>c. You shall not (directly or indirectly):</p>
 
 <p style={style}>i. take any action that imposes or may impose (as determined by us in our sole discretion) an unreasonable or disproportionately 
 large load on our (or our third party providers’) infrastructure;</p>
 <p style={style}>ii. interfere or attempt to interfere with the proper working of the Services or any activities conducted on 
 the Services;</p>
 <p style={style}>iii. bypass, circumvent or attempt to bypass or circumvent any measures we may use to prevent or restrict access 
 to the Services (or other accounts, computer systems or networks connected to the Services);</p>
 <p style={style}>iv. use manual or automated software, devices, or other processes to “crawl” or “spider” any page of the Sites;</p>
 <p style={style}>v. harvest or scrape any Content from the Services;</p>
 <p style={style}>vi. otherwise take any action in violation of our guidelines and policies;</p>
 <p style={style}>vii. decipher, decompile, disassemble, reverse engineer or otherwise
 attempt to derive any source code or underlying ideas or algorithms of
 any part of the Services (including without limitation any application),
 except to the limited extent applicable laws specifically prohibit such
 restriction;</p>
 <p style={style}>viii. modify, translate, or otherwise create derivative works of any part of
 the Services;</p>
 <p style={style}>ix. copy (except as set forth in Section 6(i) of these Terms of Service), rent, lease, distribute, publish or otherwise transfer any of the rights that
 you receive hereunder;</p>
 <p style={style}>x. cause the RSS feeds contained on BuyFluidly to be spliced into
 feeds of, or otherwise redistributed by, other users or third-party RSS
 providers; or</p>
 <p style={style}>xi. add advertisements or any promotional content to the RSS feeds.</p>
 <p style={style}>d. We also reserve the right to access, read, preserve, and disclose any information as we reasonably
  believe is necessary to:</p>
 <p style={style}>i. satisfy any applicable law, regulation, legal process or governmental
 request;</p>
 <p style={style}>ii. enforce these Terms of Service, including investigation of potential
 violations hereof;</p>
 <p style={style}>iii. detect, prevent, or otherwise address fraud, security or technical issues;</p>
 <p style={style}>iv. respond to user support requests; or</p>
 <p style={style}>v. protect the rights, property or safety of us, our users and the public.</p>
 <p style={style}><span style={style2}>8. Third Party Services.</span> <br/> The Services may permit you to link to other websites, services or resources on 
 the Internet, and other websites, services or resources may contain links to the Services. When you access third party resources 
 on the Internet, you do so at your own risk. These other resources are not under our control, and you acknowledge that we are not 
 responsible or liable for the content, functions, accuracy, legality, appropriateness or any other aspect of such websites or resources. 
 The inclusion of any such link does not imply our endorsement or any association between us and their operators. You further acknowledge 
 and agree that we shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, 
 goods or services available on or through any such website or resource.</p>
 <p style={style}><span style={style2}>9. Payments and Billing.</span><br/>
 a. Paid Services. Certain of our Services may be subject to payments now or
 in the future (the “Paid Services”). Please review the descriptions and prices of the current Paid Services within your Account Settings buyfluidly.com/account/settings. Please note that any payment terms presented
 to you in the process of using or signing up for a Paid Service are deemed
 part of this Agreement.</p>
 <p style={style}><span style={style2}>b. Billing.</span> <br/> We use a third-party payment processor (the “Payment Processor”)
 to bill you through a payment account linked to your Account on the
 Services (your “Billing Account”) for use of the Paid Services. The processing
 of payments will be subject to the terms, conditions and privacy policies of
 the Payment Processor in addition to this Agreement. We are not
 responsible for error by the Payment Processor. By choosing to use Paid Services, you agree to pay us, through the Payment Processor, all charges at
 the prices then in effect for any use of such Paid Services in accordance
 with the applicable payment terms and you authorize us, through the
 Payment Processor, to charge your chosen payment provider (your
 “Payment Method”). You agree to make payment using that selected
 Payment Method. We reserve the right to correct any errors or mistakes
 that it makes even if it has already requested or received payment.</p>
 <p style={style}><span style={style2}>c. Payment Method.</span> <br/> The terms of your payment will be based on your
 Payment Method and may be determined by agreements between you and
 the financial institution, credit card issuer or other provider of your chosen Payment Method. If we, through the Payment Processor, do not receive
 payment from you, you agree to pay all amounts due on your Billing
 Account upon demand.</p>
 <p style={style}><span style={style2}> d. Recurring Billing.</span> <br/>  Some of the Paid Services may consist of an initial
 period, for which there is a one-time charge, followed by recurring period
 charges as agreed to by you. By choosing a recurring payment plan, you acknowledge that such Services have an initial and recurring payment
 feature and you accept responsibility for all recurring charges prior to
 cancellation. WE MAY SUBMIT PERIODIC CHARGES (E.G., MONTHLY) WITHOUT FURTHER AUTHORIZATION FROM YOU, UNTIL YOU PROVIDE PRIOR NOTICE (RECEIPT OF WHICH IS CONFIRMED BY US) THAT YOU HAVE TERMINATED THIS AUTHORIZATION OR WISH TO CHANGE YOUR PAYMENT METHOD. SUCH NOTICE WILL NOT AFFECT CHARGES SUBMITTED BEFORE WE REASONABLY COULD ACT. TO TERMINATE YOUR AUTHORIZATION OR CHANGE YOUR PAYMENT METHOD, GO TO
 ACCOUNT SETTINGS buyfluidly.com/user/edit.</p>
 <p style={style}><span style={style2}>e. Current Information Required.</span> <br/>  YOU MUST PROVIDE CURRENT,
 COMPLETE AND ACCURATE INFORMATION FOR YOUR BILLING ACCOUNT. YOU MUST PROMPTLY UPDATE ALL INFORMATION TO KEEP YOUR BILLING ACCOUNT CURRENT, COMPLETE AND ACCURATE (SUCH AS A CHANGE IN BILLING ADDRESS, CREDIT CARD NUMBER, OR CREDIT CARD EXPIRATION DATE), AND YOU MUST PROMPTLY NOTIFY US OR OUR PAYMENT PROCESSOR IF YOUR PAYMENT METHOD IS CANCELED (E.G., FOR LOSS OR THEFT) OR IF YOU BECOME AWARE OF A POTENTIAL BREACH OF SECURITY, SUCH AS THE UNAUTHORIZED DISCLOSURE OR USE OF YOUR USER NAME OR PASSWORD. CHANGES TO SUCH INFORMATION CAN BE MADE AT ACCOUNT SETTINGS buyfluidly.com/user/edit. IF YOU FAIL TO PROVIDE ANY OF THE
 FOREGOING INFORMATION, YOU AGREE THAT WE MAY CONTINUE CHARGING YOU FOR ANY USE OF PAID SERVICES UNDER 
 YOUR BILLING ACCOUNT UNLESS YOU HAVE TERMINATED YOUR PAID SERVICES AS SET FORTH ABOVE.</p>
 <p style={style}><span style={style2}>f. Change in Amount Authorized.</span> <br/> If the amount to be charged to your
 Billing Account varies from the amount you preauthorized (other than due
 to the imposition or change in the amount of state sales taxes), you have
 the right to receive, and we shall provide, notice of the amount to be
 charged and the date of the charge before the scheduled date of the
 transaction. Any agreement you have with your payment provider will
 govern your use of your Payment Method. You agree that we may
 accumulate charges incurred and submit them as one or more aggregate
 charges during or at the end of each billing cycle.</p>
 <p style={style}><span style={style2}>g. Reaffirmation of Authorization.</span> <br/> Your non-termination or continued use
 of a Paid Service reaffirms that we are authorized to charge your Payment
 Method for that Paid Service. We may submit those charges for payment
 and you will be responsible for such charges. This does not waive our right
 to seek payment directly from you. Your charges may be payable in
 advance, in arrears, per usage, or as otherwise described when you initially 
 selected to use the Paid Service.</p>
 <p style={style}><span style={style2}>h. Free Trials and Other Promotions.</span> <br/> Any free trial or other promotion that
 provides access to a Paid Service must be used within the specified time of
 the trial. You must stop using a Paid Service before the end of the trial
 period in order to avoid being charged for that Paid Service. If you cancel
 prior to the end of the trial period and are inadvertently charged for a Paid
 Service, please contact us at billing@buyfluidly.com.</p>
 <p style={style}><span style={style2}>10. Termination.</span> <br/> We may terminate your access to all or any part of the Services at any time, with or without cause, with or without notice, effective immediately,
 which may result in the forfeiture and destruction of all information associated
 with your membership. If you wish to terminate your Account, you may do so by following the instructions on the Sites or through the Services. Any fees paid hereunder are non-refundable. All provisions of these Terms of Service which by their nature should survive termination shall survive termination, including,
 without limitation, licenses of User Content, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</p>
 <p style={style}><span style={style2}>11. Warranty Disclaimer.</span></p>
 <p style={style}>a. We have no special relationship with or fiduciary duty to you. You
 acknowledge that we have no duty to take any action regarding:</p>
 <p style={style}>i. which users gain access to the Services;</p>
 <p style={style}>ii. what Content you access via the Services; or</p>
 <p style={style}>iii. how you may interpret or use the Content.</p>
 <p style={style}>b. You release us from all liability for you having acquired or not acquired
 Content through the Services. We make no representations concerning any
 Content contained in or accessed through the Services, and we will not be responsible or liable for the accuracy, copyright compliance, or legality of
 material or Content contained in or accessed through the Services. Your
 interactions with organizations and/or individuals found on or through the
 Services, including payment and delivery of goods or services, and any
 other terms, conditions, warranties or representations associated with such
 dealings, are solely between you and such organizations and/or
 individuals. You should make whatever investigation you feel necessary or appropriate before proceeding with any online or offine transaction with
 any of these third parties. You agree that we shall not be responsible or
 liable for any loss or damage of any sort incurred as the result of any such
 dealings. If there is a dispute between participants on the Sites, or
 between users and any third party, you understand and agree that we are
 under no obligation to become involved. In the event that you have a
 dispute with one or more other users or third parties, you hereby release us,
 our officers, employees, agents, and successors in rights from claims,
 demands, and damages (actual and consequential) of every kind or nature,
 known or unknown, suspected or unsuspected, disclosed or undisclosed,
 arising out of or in any way related to such disputes.</p>
 <p style={style}>c. THE SERVICES AND CONTENT ARE PROVIDED “AS IS”, “AS AVAILABLE” AND WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING,
 BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF TITLE, NONINFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, AND ANY WARRANTIES IMPLIED BY
  ANY COURSE OF PERFORMANCE OR USAGE OF TRADE, ALL OF WHICH ARE EXPRESSLY DISCLAIMED. WE, AND OUR DIRECTORS, EMPLOYEES, AGENTS,
 SUPPLIERS, PARTNERS AND CONTENT PROVIDERS DO NOT WARRANT THAT: (I) THE SERVICES WILL BE SECURE OR AVAILABLE AT ANY PARTICULAR 
 TIME OR LOCATION; (II) ANY DEFECTS OR ERRORS WILL BE CORRECTED; (III) ANY CONTENT OR SOFTWARE AVAILABLE AT OR THROUGH THE SERVICES 
 IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS; OR (IV) THE RESULTS OF USING THE SERVICES WILL MEET YOUR REQUIREMENTS. 
 YOUR USE OF THE SERVICES IS SOLELY AT YOUR OWN RISK.</p>
 
 <p style={style}><span style={style2}>12. Indemnification.</span> <br/> You shall defend, indemnify, and hold harmless us, our affiliates and each of our and their respective employees, contractors, directors, suppliers and representatives from all liabilities, claims, and expenses, including reasonable attorneys’ fees, that arise from or relate to your use or misuse of, or access to, the Services, Content, or otherwise from your User Content, violation of these Terms of Service, or infringement by you, or any third party using your Account or identity in the Services, of any intellectual property or other right of any person or entity. We reserve the right to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which
 event you will assist and cooperate with us in asserting any available defenses.</p>
 <p style={style}><span style={style2}>13. Limitation of Liability.</span> IN NO EVENT SHALL WE, NOR OUR DIRECTORS, EMPLOYEES, AGENTS, 
 PARTNERS, SUPPLIERS OR CONTENT PROVIDERS, BE LIABLE UNDER CONTRACT, TORT, STRICT LIABILITY, NEGLIGENCE OR ANY 
 OTHER LEGAL OR EQUITABLE THEORY WITH RESPECT TO THE SERVICES (I) FOR ANY LOST PROFITS, DATA LOSS, COST OF PROCUREMENT
  OF SUBSTITUTE GOODS OR SERVICES, OR SPECIAL, INDIRECT, INCIDENTAL, PUNITIVE, COMPENSATORY OR CONSEQUENTIAL DAMAGES OF ANY
   KIND WHATSOEVER, SUBSTITUTE GOODS OR SERVICES (HOWEVER ARISING), (II) FOR ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE 
   (REGARDLESS OF THE SOURCE OF ORIGINATION), OR (III) FOR ANY DIRECT DAMAGES IN EXCESS OF (IN THE AGGREGATE) OF THE GREATER OF 
   (A) FEES PAID TO US FOR THE PARTICULAR SERVICES DURING THE IMMEDIATELY PREVIOUS THREE (3) MONTH PERIOD OR (B) $100.00.</p>
 <p style={style}><span style={style2}>14. Governing Law and Jurisdiction.</span> <br/> These Terms of Service shall be governed by
 and construed in accordance with the laws of the State of Massachusetts, including
 its conflicts of law rules, and the United States of America. You agree that any
 dispute arising from or relating to the subject matter of these Terms of Service
 shall be governed by the exclusive jurisdiction and venue of the state and
 Federal courts of Massachusetts.</p>
 <p style={style}><span style={style2}>15. Modification.</span> <br/> We reserve the right, in our sole discretion, to modify or replace
 any of these Terms of Service, or change, suspend, or discontinue the Services (including without limitation, the availability of any feature, database, or
 content) at any time by posting a notice on the Sites or by sending you notice
 through the Services, via e-mail or by another appropriate means of electronic communication. We may also impose limits on certain features and services or
 restrict your access to parts or all of the Services without notice or liability.
 While we will timely provide notice of modifications, it is also your responsibility
 to check these Terms of Service periodically for changes. Your continued use of
 the Services following notification of any changes to these Terms of Service
 constitutes acceptance of those changes.</p>
 <p style={style}><span style={style2}>16. Miscellaneous.</span></p>
 <p style={style}><span style={style2}>a. Entire Agreement and Severability.</span> <br/> These Terms of Service are the
 entire agreement between you and us with respect to the Services,
 including use of the Sites, and supersede all prior or contemporaneous
 communications and proposals (whether oral, written or electronic)
 between you and us with respect to the Services. If any provision of these Terms of Service is found to 
 be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary 
 so that these Terms of Service will otherwise remain in full force and effect and enforceable. The failure 
 of either party to exercise in any respect any right provided for herein shall not be deemed a waiver of any further rights hereunder</p>
 <p style={style}><span style={style2}>b. Force Majeure.</span> <br/> We shall not be liable for any failure to perform our obligations hereunder where such 
 failure results from any cause beyond our reasonable control, including, without limitation, mechanical, electronic or 
 communications failure or degradation.</p>
 <p style={style}><span style={style2}>c. Assignment.</span> <br/> These Terms of Service are personal to you, and are not assignable, transferable or sublicensable by 
 you except with our prior written consent. We may assign, transfer or delegate any of our rights and obligations hereunder without consent.</p>
 <p style={style}><span style={style2}>d. Agency.</span> <br/> No agency, partnership, joint venture, or employment relationship is created as a result of these Terms of Service 
 and neither party has any authority of any kind to bind the other in any respect.</p>
 <p style={style}><span style={style2}>e. Notices.</span> <br/> Unless otherwise specified in these Term of Service, all notices under these Terms of Service will be 
 in writing and will be deemed to have been duly given when received, if personally delivered or sent by certified or registered mail, 
 return receipt requested; when receipt is electronically confirmed, if transmitted by facsimile or e-mail; or the day after it is sent, 
 if sent for next day delivery by recognized overnight delivery service. Electronic notices should be sent to support@buyfluidly.com.</p>
 <p style={style}><span style={style2}>f. No Waiver.</span> <br/> Our failure to enforce any part of these Terms of Service shall not constitute a waiver of our right to later enforce that or any other part of these Terms of Service. Waiver of compliance in any particular instance does not mean that we will waive compliance in the future. In order for any waiver of compliance with these Terms of Service to be binding, we must provide you with written notice of such waiver through one of our
 authorized representatives.</p>
 <p style={style}><span style={style2}>g. Headings.</span> <br/> The section and paragraph headings in these Terms of Service are for convenience only and shall not affect their interpretation.
 Contact. You may contact us at the following address: 51 Park Street, Lee, MA 01238 © 2018 BuyFluidly Inc. All rightsreserved.
            </p>
        </div>
    )
 }
 
  }
  
    

export default withRouter(TermsAndConditions);