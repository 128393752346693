import React from 'react';
import styles from './styles.scss';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { isMobileOnly, isTablet,  isBrowser} from 'react-device-detect';


class DistributorOrderHistory extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            orderSelected: false,
            orderId: -1,
            orderHeaders: this.props.orderHeaders,
        };
        this.onRowClick = this.onRowClick.bind(this);
    }

    componentDidMount() {
        this.props.loadOrderHeaders().then(() => {
            this.setState({ orderHeaders: this.props.orderHeaders, });
        });

    }

    onRowClick(orderId, distributorId) {
        this.props.history.push({ pathname: '/distributor-order-details', state: { orderId: orderId, distributorId:  distributorId}, });
    }

    try(func, fallbackValue) {
        try {
            const value = func();
            return (value === null || value === undefined) ? fallbackValue : value;
        } catch (e) {
            return fallbackValue;
        }
    }

    sortbyDate(){
        const { orderHeaders } = this.state;
        orderHeaders.sort(function compare(a, b) {
            const dateA = new Date(a.submitDate);
            const dateB = new Date(b.submitDate);
            return dateA - dateB;
        });
        this.setState({
            orderHeaders: orderHeaders,
        });
    }

    sortbyCustomer(){
        const { orderHeaders } = this.state;
        orderHeaders.sort(function compare(a, b) {
            return a.distributorId - b.distributorId;
        });
        this.setState({
            orderHeaders: orderHeaders,
        });
    }

    sortbyAmount(){
        const { orderHeaders } = this.state;
        orderHeaders.sort(function compare(a, b) {
            return a.total - b.total;
        });
        this.setState({
            orderHeaders: orderHeaders,
        });
    }

    sortbyStatus(){
        const { orderHeaders } = this.state;
        orderHeaders.sort(function compare(a, b) {
            return ('' + a.status).localeCompare(b.status);
        });
        this.setState({
            orderHeaders: orderHeaders,
        });
    }

    sortbyOrder(){
        const { orderHeaders } = this.state;
        orderHeaders.sort(function compare(a, b) {
            return a.orderId - b.orderId;
        });
        this.setState({
            orderHeaders: orderHeaders,
        });
    }

    render() {
        const { 
            distributorId,
            orderSelected,
            orderId,
            orderHeaders,
        } = this.state;
        return (
            <div className={styles.wrapper}>
                <div className='level'>
                    <div className='level-item-left'>
                        <div className={isMobileOnly ? 'is-size-5' : 'is-size-3'}>Customer Order Requests</div>
                    </div>
                    <div className='level-item-right'>
                        {/*
                        <div className="field has-addons">
                            <p className="control" style={{width: '24rem',}}>
                                <input className="input" type="text" placeholder="Search for orders that contain a product" />
                            </p>
                            <p className="control">
                                <button className="button">
                                Search
                                </button>
                            </p>
                        </div>
                    */}
                    </div>
                </div>
                <Table className='table is-striped' style={{border: '0.5px solid', borderRadius: 10, borderColor: '#ccc'}}>
                    <Thead>
                        <Tr style={{ borderWidth: '1px', borderColor: '#333', backgroundColor: '#f1e2c3',}}>
                            <Th style={{padding: '1rem 0 0 1rem',}} onClick={() => this.sortbyDate()}><a>Date</a></Th>
                            <Th style={{padding: '1rem',}} colSpan={2} onClick={() => this.sortbyCustomer()}><a>Customer</a></Th>
                            <Th style={{padding: '1rem',}} onClick={() => this.sortbyAmount()}><a>Amount</a></Th>
                            <Th style={{padding: '1rem',}} onClick={() => this.sortbyStatus()}><a>Status</a></Th>
                            <Th style={{padding: '1rem',}} onClick={() => this.sortbyOrder()}><a>Order #</a></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        { orderHeaders.map((item, index) => (
                            <Tr key={index} onClick={() => this.onRowClick(item.orderId, item.distributorId)}>
                                <Td>{new Date(this.try(() => item.submitDate, 0)).toLocaleDateString()}</Td>
                                <Td colSpan={2}>{this.try(() => item.retailer.name, 0)}</Td>
                                <Td>${(this.try(() => item.total, 0)).toFixed(2)}</Td>
                                <Td className={this.try(() => item.status==='verified') ? 'has-text-success' : 'has-text-danger'}>{this.try(() => item.status==='verified') ? 'Acknowledged' : 'Open'}</Td>
                                <Td><a>{this.try(() => item.orderId, 0)}</a></Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
                <div className='level'>
                    <div className='level-item-left' />
                    <div className='level-item-right'>
                        {/*
                        <a>Next 50&gt;</a>
                        */}
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(DistributorOrderHistory);

DistributorOrderHistory.propTypes = {
    history: PropTypes.object,
    orderHeaders: PropTypes.array,
    loadOrderHeaders: PropTypes.func,
};
 