exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-pages-terms-and-conditions-styles--center-text {\n  margin-top: 60px; }\n", ""]);

// exports
exports.locals = {
	"center-text": "src-pages-terms-and-conditions-styles--center-text"
};