import {buildEvents, noParameters, namedParams, singleParameter} from '../../common/events';

const definition = {
    AuthenticationRequested: noParameters,
    AuthenticationFailed: singleParameter,
    AuthenticationSucceeded: namedParams('username', 'result'),

    SignupRequested: noParameters,
    SignupFailed: singleParameter,
    SignupSucceeded: noParameters,

    TokenRefreshSucceeded: singleParameter,
    TokenRefreshFailed: noParameters,

    RefreshTokenTimoutIdCreated: singleParameter,
    RefreshTokenTimeoutTriggered: noParameters,

    SetRetailerType: singleParameter, 
    
    LoggedOut: noParameters,
};

const {types, events} = buildEvents('authentication', definition);

export {types};

export default events;