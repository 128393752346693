import React from 'react';
import PropTypes from 'prop-types';
import SegmentedControl from '../../components/segmented-control';
import bulmaCarousel from 'bulma-carousel/dist/js/bulma-carousel.min.js';
import 'bulma-carousel/dist/css/bulma-carousel.min.css';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';


class HomePage extends React.Component {

    constructor(props){
        super(props);
        this.toggleSegment = this.toggleSegment.bind(this);
        this.state = {
            retailer: true,
        };
    }

    componentDidMount () {
        bulmaCarousel.attach();
    }

    toggleSegment(segment) {
        this.setState({
            retailer: (segment===0) ? true : false,
        });
    }

    render() { 

        const {
            usertype,
            company_id,
            profile,
        } = this.props;
        return (
            <div>
                { 
                    usertype === '1' && company_id !== '' && <Redirect to='/consumer-dashboard' />
                }
                {
                    usertype === '2' && company_id !== '' && <Redirect to='distributor-dashboard' />
                }
                {
                    usertype !== '' && company_id === '' && <Redirect to={{ pathname: '/signup', state: { isSignup: true }}} />
                }
                <section className="hero is-paddingless">
                    <div className="hero-body is-paddingless">
                        <figure className="image">
                            <img src={require('../../images/hero-image.png')} />
                            <h1 className="title is-size-1 is-size-3-touch has-text-white" 
                                style={{position: 'absolute', bottom: '0', padding: '0 0 6rem 4rem', lineHeight: '1.4', }}
                            >
                                Beverage Supply<br />Simplified.</h1>
                        </figure>
                    </div>
                </section>
                <section className="section has-text-centered is-hidden-mobile" style={{marginTop: '-4rem'}}>
                    <SegmentedControl onToggle={this.toggleSegment}/>
                </section>
                <section className="section has-text-centered is-hidden-desktop is-hidden-tablet is-paddingless">
                    <SegmentedControl onToggle={this.toggleSegment}/>
                </section>
                <section className="section">
                    <div className="container">
                        <div className="columns">
                            <div className="column">
                                <div className="level">
                                    <div className="level-item has-text-centered">
                                        <figure className="image is-128x128">
                                            { this.state.retailer &&
                                            <img src={require('../../images/target.png')} alt="logo image" /> }
                                            { !this.state.retailer &&
                                            <img src={require('../../images/distrib-customers.png')} alt="logo image" /> }
                                        </figure>

                                    </div>
                                </div>
                                <div className="has-text-centered" style={{paddingBottom: '1rem'}}>
                                    <div className="title">{ this.state.retailer ? 'Product Visibility' : 'Reach More Customers' }</div>
                                </div>
                                <div className="has-text-centered">
                                    <div className="is-size-5">{ this.state.retailer ?
                                        'Access supplier inventory with detailed product descriptions, specs and ratings. Knowledgeably stock the right inventory for your customers.' :
                                        'Current and new customers will find your company when using the searchable product catalog. Works as a virtual salesforce to extend your reach.'
                                    }</div>
                                </div>
                            </div>
                            <div className="column">
                                <div className="level">
                                    <div className="level-item has-text-centered">
                                        <figure className="image is-128x128">
                                            { this.state.retailer &&
                                            <img src={require('../../images/clipboard.png')} alt="logo image" /> }
                                            { !this.state.retailer &&
                                            <img src={require('../../images/distrib-increase.png')} alt="logo image" /> }
                                        </figure>
                                    </div>
                                </div>
                                <div className="has-text-centered" style={{paddingBottom: '1rem'}}>
                                    <div className="title">{ this.state.retailer ? 'Simplified Ordering' : 'Increase Sales' }</div>
                                </div>
                                <div className="has-text-centered">
                                    <div className="is-size-5">{ this.state.retailer ?
                                        'Research products and place orders across multiple distributors all from one source.' : 
                                        'Provide your customers with greater awareness of products. Free up time for your sales team to promote and sell. Stay top of mind for your customers.'
                                    }</div>
                                </div>
                            </div>
                            <div className="column">
                                <div className="level">
                                    <div className="level-item has-text-centered">
                                        <figure className="image is-128x128">
                                            { this.state.retailer &&
                                            <img src={require('../../images/fluid-truck2.png')} alt="logo image" /> }
                                            { !this.state.retailer &&
                                            <img src={require('../../images/distrib-promote.png')} alt="logo image" /> }
                                        </figure>
                                    </div>
                                </div>
                                <div className="has-text-centered" style={{paddingBottom: '1rem'}}>
                                    <div className="title">{ this.state.retailer ? 'Dependable Delivery' : 'Promote Your Products' }</div>
                                </div>
                                <div className="has-text-centered">
                                    <div className="is-size-5">{ this.state.retailer ?
                                        'Your relationships with suppliers stay the same, payments and delivery exactly the way you expect.' : 
                                        'Create a full searchable product catalog with descriptions, specs and ratings. Educate your customer!'
                                    }</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <section id="trusted-by" className="section has-background-light">
                    <div className="level">
                        <div className="level-item">
                            <div className="level">
                                <div className="level-item image is-hidden-mobile">
                                    <img src={require('../../images/ornament-left.png')} style={{maxWidth: '100%', height: 'auto'}} />
                                </div>
                                <div className="level-item">
                                    <p className="is-size-5 has-text-black" style={{padding: '0 15px 0 15px'}}>TRUSTED BY</p>
                                </div>
                                <div className="level-item image is-hidden-mobile">
                                    <figure className="image">
                                        <img src={require('../../images/ornament-right.png')} style={{maxWidth: '100%', height: 'auto'}}/>
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="columns">
                            <div className="column">
                                <nav className="level">
                                    <div className="level-item has-text-centered">
                                        <div className="image is-96x96" style={{display: 'flex', alignItems: 'center'}}>
                                            <img src={require('../../images/trusted-logo-1.png')} alt="logo image" />
                                        </div>
                                    </div>
                                </nav>
                            </div>
                            <div className="column">
                                <div className="level">
                                    <div className="level-item has-text-centered">
                                        <figure className="image is-96x96" style={{display: 'flex', alignItems: 'center'}}>
                                            <img src={require('../../images/trusted-logo-2.png')} alt="logo image" />
                                        </figure>
                                    </div>
                                </div>
                            </div>
                            <div className="column">
                                <div className="level">
                                    <div className="level-item has-text-centered">
                                        <figure className="image is-96x96" style={{display: 'flex', alignItems: 'center'}}>
                                            <img src={require('../../images/trusted-logo-3.png')} alt="logo image" />
                                        </figure>
                                    </div>
                                </div>
                            </div>
                            <div className="column">
                                <div className="level">
                                    <div className="level-item has-text-centered">
                                        <figure className="image is-96x96" style={{display: 'flex', alignItems: 'center'}}>
                                            <img src={require('../../images/trusted-logo-4.png')} alt="logo image" />
                                        </figure>
                                    </div>
                                </div>
                            </div>
                            <div className="column">
                                <div className="level">
                                    <div className="level-item has-text-centered">
                                        <figure className="image is-96x96" style={{display: 'flex', alignItems: 'center'}}>
                                            <img src={require('../../images/trusted-logo-5.png')} alt="logo image" />
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                <section>
                    <div className="columns">
                        <div className="column">
                            <div className="level">
                                <div className="level-item has-text-centered">
                                    <div>
                                        <Link to={{ pathname: '/signup', state: { isSignup: true } }}>
                                            <button className="button is-large is-dark" style={{margin: '5rem 0 1rem 0'}}>
                                                { this.state.retailer ? 'Create A Free Account' : 'Create an Account' }
                                            </button>
                                        </Link>
                                        <p style={{margin: '0 0 5rem 0'}}>
                                            { this.state.retailer ? 'BuyFluidly is always completely free for Restaurants, Bars and Retailers' :
                                                'Try it free for 30 days.' }
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='has-background-light'>
                    <div className='carousel carousel-animated carousel-animate-slide' data-autoplay="true" data-delay="8000">
                        <div className='carousel-container'>
                            {this.state.retailer ?
                                <div className='carousel-item has-background'>
                                    <section className="section">
                                        <div className="container">
                                            <div className="columns">
                                                <div className="column is-half">
                                                    <div>
                                                        <figure className="image">
                                                            <img src={require('../../images/retailer-marketing1.jpg')} 
                                                                style={{maxWidth: '85%', height: 'auto', margin: 'auto', border: '2px solid black'}} />
                                                        </figure>
                                                    </div>
                                                </div>
                                                <div className='column is-half has-text-centered'>
                                                    <div>
                                                        <br/>
                                                        <h3 className='is-size-3'>Be in the Know</h3>
                                                        <p className='is-size-5' style={{marginTop: '1rem', }}>Visibility to all product offerings from distributors with descriptions, 
                                                        ratings and background information.  
                                                        Easily find products with our powerful searchable catalog.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                                :
                                <div className='carousel-item has-background'>
                                    <section className="section">
                                        <div className="container">
                                            <div className="columns">
                                                <div className="column is-half">
                                                    <div>
                                                        <figure className="image">
                                                            <img src={require('../../images/product-list.png')} 
                                                                style={{maxWidth: '85%', height: 'auto', margin: 'auto', border: '2px solid black'}} />
                                                        </figure>
                                                    </div>
                                                </div>
                                                <div className='column is-half has-text-centered'>
                                                    <div>
                                                        <br/>
                                                        <h3 className='is-size-3'>Be Top of Mind</h3>
                                                        <p className='is-size-5' style={{marginTop: '1rem', }}>Providing your customers with a complete list and greater visibility of your inventory will lead to more sales. 
                                                         Our powerful searchable product catalog will provide the product descriptions, 
                                                        images, specs and ratings that your customers want to order confidently.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            }
                            
                            {this.state.retailer ?
                                <div className='carousel-item has-background'>
                                    <section className="section">
                                        <div className="container">
                                            <div className="columns">
                                                <div className="column is-half">
                                                    <div>
                                                        <figure className="image">
                                                            <img src={require('../../images/retailer-marketing2.jpg')} 
                                                                style={{maxWidth: '85%', height: 'auto', margin: 'auto', border: '2px solid black'}} />
                                                        </figure>
                                                    </div>
                                                </div>
                                                <div className='column is-half has-text-centered'>
                                                    <div>
                                                        <br/>
                                                        <h3 className='is-size-3'>One Stop Beverage Ordering</h3>
                                                        <p className='is-size-5' style={{marginTop: '1rem', }}>Access available distributor inventory, research products and place orders all from one site.  
                                                            This simplified process saves you time and helps you improve your business offerings.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                                :
                                <div className='carousel-item has-background'>
                                    <section className="section">
                                        <div className="container">
                                            <div className="columns">
                                                {/* <div className="column is-half">
                                                    <div>
                                                        <figure className="image">
                                                            <img src={require('../../images/sales-force.jpg')} 
                                                                style={{maxWidth: '85%', height: 'auto', margin: 'auto', border: '2px solid black'}} />
                                                        </figure>
                                                    </div>
                                                </div> */}
                                                <div className='column has-text-centered'>
                                                    <div>
                                                        <br/>
                                                        <h3 className='is-size-3'>Improve Sales Force Productivity</h3>
                                                        <p className='is-size-5' style={{marginTop: '1rem', }}>BuyFluidly acts as your virtual salesforce, allowing your sales team to spend more time selling and visiting customers. 
                                                         Free up your team to be true salespeople instead of order takers.  
                                                        Independent producers can focus more time on the operation or the many other needs of the business.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            }
                        </div>
                    </div>
                </section>
                <footer className="footer" style={{backgroundColor: '#1b1725'}}>
                    <div className="columns">
                        <div className="column is-3" style={{display: 'flex', alignItems: 'center', color: 'white',}}>
                            <div style={{margin: '0 auto'}}>
                                <Link to='/about-us'>
                                    <p>About Us</p>
                                </Link>
                                    
                                <a href="mailto: demo@buyfluidly.com">
                                    <p>Request A Demo</p>
                                </a>
                                <a href="mailto: support@buyfluidly.com">
                                    <p>Contact Us</p>
                                </a>
                               
                                <a onClick={()=> window.open('/terms-and-conditions', '_blank')} >
                                    <p>Terms Of Service</p>
                                </a>
                                <Link to={{ pathname: '/signup', state: { isSignup: true } }}>
                                    <a>Signup For Free</a>
                                </Link>
                            </div>
                        </div>
                        <div className="column is-3" style={{display: 'flex', alignItems: 'center', color: 'white',}}>
                            <div style={{margin: '0 auto'}}>
                                <p>v 1.0.4</p>
                            </div>
                        </div> 
                        <div className="column is-2"></div>     
                        <div className="column is-4 is-hidden-mobile" style={{display: 'flex', alignItems: 'center'}}>
                            <div className="level" style={{marginLeft: 'auto', marginRight: '2rem'}}>
                                <img 
                                    style={{ maxHeight: '70%', maxWidth: '70%',}}
                                    src={require('images/bf-logo-white.png')} />
                                <figure className="image is-96x96">
                                    <img src={require('../../images/footer-truck-white.png')} alt="logo image" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}

HomePage.propTypes = { 
    alert: PropTypes.object,  
    usertype: PropTypes.string,
    company_id: PropTypes.string,
};

HomePage.defaultProps = {   
    usertype: '',
};

export default withRouter(HomePage);
