import { connect } from 'react-redux';

import Component from './component';
import Commands from 'state/commands';
import fromState from 'state/selectors';

function mapStateToProps(state) {
    return {
        customerNum: fromState.Profile.customerNum()(state),
        comments: fromState.Cart.comments()(state),
        orderDetails: fromState.Cart.orderDetails()(state),
        reps: fromState.Profile.getReps()(state),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        addComment: (orderId, commentString) => dispatch(Commands.Cart.AddComment(orderId, commentString)),
        deleteComment: (orderId, commentId) => dispatch(Commands.Cart.DeleteComment(orderId, commentId)),
        getCustomerNum: (distributorId, retailerId) => dispatch(Commands.Profile.GetCustomerNum(distributorId, retailerId)),
        getRepsForDist: (distributorId) => dispatch(Commands.Profile.GetRepsForDist(distributorId)),
        loadComments: (orderId) => dispatch(Commands.Cart.LoadComments(orderId)),
        loadOrderDetails: (orderId) => dispatch(Commands.Cart.LoadOrderDetails(orderId)),
        updateRepInfo: (distributorId, retailerId, newRepId, newCustomerNum) => 
            dispatch(Commands.Profile.UpdateRepInfo(distributorId, retailerId, newRepId, newCustomerNum)),
        verifyOrder: (orderId) => dispatch(Commands.Cart.VerifyOrder(orderId)),
        getReps: () => dispatch(Commands.Profile.GetReps()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);