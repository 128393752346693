import {buildEvents, noParameters, namedParams, singleParameter} from '../../common/events';

const definition = {

    CloneImageFailed: noParameters,
    CloneImageSucceeded: singleParameter,

    StoreActivePageFailed: noParameters,
    StoreActivePageSucceeded: singleParameter,

    StoreShowingNewFailed: noParameters,
    StoreShowingNewSucceeded: singleParameter,

    GetProductSuggestionsFailed: noParameters,
    GetProductSuggestionsSucceeded: singleParameter,

    AddProductVariantStarted: noParameters,
    AddProductVariantFailed: singleParameter,
    AddProductVariantSucceeded: singleParameter,

    AddUpdateProductStarted: noParameters,
    AddUpdateProductFailed: singleParameter,
    AddUpdateProductSucceeded: singleParameter,

    DeleteProductStarted: noParameters,
    DeleteProductFailed: singleParameter,
    DeleteProductSucceeded: singleParameter,

    UpdateProductStarted: noParameters,
    UpdateProductFailed: singleParameter,
    UpdateProductSucceeded: singleParameter,

    DeleteProductVariantStarted: noParameters,
    DeleteProductVariantFailed: singleParameter,
    DeleteProductVariantSucceeded: singleParameter,

    ProductLinkAdding: noParameters,
    AddProductLinkFailed: singleParameter,
    AddProductLinkSucceeded: singleParameter,

    ProductLinkDeleting: noParameters,
    DeleteProductLinkFailed: singleParameter,
    DeleteProductLinkSucceeded: singleParameter,


    PackagingTypesLoading: noParameters,
    LoadPackagingTypesFailed: singleParameter,
    LoadPackagingTypesSucceeded: singleParameter,

    ProductDetailsLoading: noParameters,
    LoadProductDetailsFailed: singleParameter,
    LoadProductDetailsSucceeded: singleParameter,

    ProductLinksLoading: noParameters,
    LoadProductLinksFailed: singleParameter,
    LoadProductLinksSucceeded: singleParameter,

    ProductVariantsLoading: noParameters,
    LoadProductVariantsFailed: singleParameter,
    LoadProductVariantsSucceeded: singleParameter,

    CatalogBrandsLoading: noParameters,
    LoadCatalogBrandsFailed: singleParameter,
    LoadCatalogBrandsSucceeded: singleParameter,
    
    CatalogCategoriesLoading: noParameters,
    LoadCatalogCategoriesFailed: singleParameter,
    LoadCatalogCategoriesSucceeded: singleParameter,

    CatalogCategoryProductTypesLoading: noParameters,
    LoadCatalogCategoryProductTypesFailed: singleParameter,
    LoadCatalogCategoryProductTypesSucceeded: namedParams('categoryId', 'types'),

    SubTypesLoading: noParameters,
    LoadSubTypesFailed: singleParameter,
    LoadSubTypesSucceeded: namedParams('categoryId','typeId', 'subTypes'),

    RegionsLoading: noParameters,
    LoadRegionsFailed: singleParameter,
    LoadRegionsSucceeded: singleParameter,

    LoadDistributorsFailed: singleParameter,
    LoadDistributorsSucceeded: singleParameter,

    SubRegionsLoading: noParameters,
    LoadSubRegionsFailed: singleParameter,
    LoadSubRegionsSucceeded: singleParameter,

    CatalogProductsLoading: noParameters,
    SearchCatalogProductsFailed: singleParameter,
    SearchCatalogProductsSucceeded: singleParameter,

    UploadImageStarted: noParameters,
    UploadImageSucceeded: singleParameter,
    UploadImageFailed: noParameters,
};

const {subRegions, types, subTypes, events} = buildEvents('catalog', definition);

export {types, subTypes, subRegions};

export default events;