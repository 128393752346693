import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import toPairs from 'lodash/toPairs';
import styles from './styles.scss';
import isEmpty from 'lodash/isEmpty';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



function optionList(options) {
    if (isArray(options)) return options;
    if (isObject(options)) return toPairs(options);
    return options;
}

function Option({option}) {
    const isValueArray = isArray(option);
    const value = isValueArray ? option[0] : option;
    const text = isValueArray ? option[1] : option;
    return (
        <option value={value}>
            {text}
        </option>
    );
}

Option.propTypes = {
    option: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.string,
    ]),
};



function DropDown({options, onChange, disabled, hasError, value, hasFocus, placeholder, enableClearButton}) {
    return (
        <div className='dd-wrapper box is-paddingless' style={{position: 'relative',}}>
            <select 
                ref={input => hasFocus && input && input.focus()}
                className={classnames(styles.select, {[styles.hasError]: hasError, [styles.placeholder]: placeholder && value == ''})}
                disabled={disabled}
                onChange={e => onChange(e.target.value)}
                value={value}
            >
                {placeholder && <option value='' disabled={true} >{placeholder}</option>}
                { options && optionList(options).map((option, index) =>
                    <Option option={option} key={index} />
                )}     
            </select>
            {
                !isEmpty(value) && enableClearButton &&
                <a className={styles.selectClearButton} onClick={() => onChange('')}>
                    <i className="fa fa-times" aria-hidden="true"/>
                </a> 
            }
            <FontAwesomeIcon icon="angle-down" className={styles.icon}/>
        </div>
    );
}

DropDown.propTypes = {
    options: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    value: PropTypes.any,
    hasError: PropTypes.bool,
    hasFocus: PropTypes.bool,
    placeholder: PropTypes.string,
    enableClearButton: PropTypes.bool,
};


export default DropDown;
