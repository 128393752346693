import {types as EventTypes} from '../events';
import basicReducer from '../../common/reducers/basic-reducer';
import {types as AuthEventTypes} from '../../authentication/events';
import constant from 'lodash/constant';

const initialState = {};

const reductionLookup = {
    [AuthEventTypes.LoggedOut]: constant(initialState),
    [EventTypes.CatalogCategoryProductTypesLoading]: constant(initialState),
    [EventTypes.LoadCatalogCategoryProductTypesFailed]: constant(initialState),
    [EventTypes.LoadCatalogCategoryProductTypesSucceeded]: (_, {categoryId, types}) => ({categoryId, types}),
};

export default basicReducer(initialState, reductionLookup);
