import ReactGA from 'react-ga';
import React from 'react';
import '../css/mystyles.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faAngleUp, faAngleDown, faCheck, faPlusCircle, faUser} from '@fortawesome/free-solid-svg-icons';
import { BrowserRouter as Router, Route, Switch, browserHistory} from 'react-router-dom';
import Home from 'pages/home';
import AboutUs from 'pages/about-us';
import ConsumerDashBoard from 'pages/consumer-dashboard';
import DistributorDashCatalog from 'pages/distributor-catalog';
import Signup from 'pages/signup';
import ConsumerProfile from 'pages/consumer-profile';
import DistributorProfile from 'pages/distributor-profile';
import DistributorProfileReps from 'pages/distributor-profile-reps';
import ProductAdd from 'pages/product-add';
import ProductEdit from 'pages/product-edit';
import ProductDetails from 'pages/product-details';
import ConsumerCart from 'pages/consumer-cart';
import ProfileDetails from 'pages/profile-details';
import Header from 'components/header';
import ConsumerOrderHistory from 'pages/consumer-order-history';
import ConsumerOrderDetails from 'pages/consumer-order-details';
import DistributorDashboard from 'pages/distributor-dashboard';
import DistributorOrderDetails from 'pages/distributor-order-details';
import DistributorOrderHistory from 'pages/distributor-order-history';
import TermsAndConditions from 'pages/terms-and-conditions';
import ResetPassword from 'pages/reset-password';
import ConfirmPassword from 'pages/confirm-password';
import UsersList from 'pages/users-list';
import MobileFilters from 'pages/mobile-filters';
import MetaTags from 'react-meta-tags';
import ScrollToTop from 'components/scroll-to-top';


library.add(faAngleUp, faAngleDown, faCheck, faPlusCircle, faUser);

ReactGA.initialize('UA-122178832-1');

class AppPage extends React.Component {

    render() {
        return (
            <Router history={browserHistory}>
                <ScrollToTop>
                    <div>
                        <MetaTags>
                            <meta name="viewport" content="width=device-width" />
                        </MetaTags>
                        <Header/>
                        <div style={{ height: '5rem', }} id='vertSpacer' />
                        <Switch>
                            <Route exact path="/" component={Home} />
                            <Route path="/terms-and-conditions" component={TermsAndConditions} /> 
                            <Route path="/about-us" component={AboutUs} /> 
                            <Route path="/signup" component={Signup} /> 
                            <Route path="/profile-details" component={ProfileDetails} /> 
                            <Route path="/consumer-profile" component={ConsumerProfile} />  
                            <Route path="/distributor-profile" component={DistributorProfile} />
                            <Route path="/distributor-profile-reps" component={DistributorProfileReps} />
                            <Route path="/distributor-order-history" component={DistributorOrderHistory} /> 
                            <Route path="/consumer-dashboard" component={ConsumerDashBoard} />
                            <Route path="/distributor-catalog" component={DistributorDashCatalog} /> 
                            <Route path="/consumer-order-history" component={ConsumerOrderHistory} /> 
                            <Route path="/consumer-order-details" component={ConsumerOrderDetails} /> 
                            <Route path="/distributor-dashboard" component={DistributorDashboard} /> 
                            <Route path="/distributor-order-details" component={DistributorOrderDetails} /> 
                            <Route path="/product-add" component={ProductAdd} /> 
                            <Route path="/product-details" component={ProductDetails} />  
                            <Route path="/product-add" component={ProductAdd} />  
                            <Route path="/product-edit" component={ProductEdit} />  
                            <Route path="/consumer-cart" component={ConsumerCart} />
                            <Route path="/reset-password" component={ResetPassword} /> 
                            <Route path="/confirm-password" component={ConfirmPassword} />
                            <Route path="/users-list" component={UsersList} />
                            <Route path="/mobile-filters" component={MobileFilters} />   
                        </Switch>
                    </div>
                </ScrollToTop>
            </Router>
        );
    }
}

export default AppPage;


