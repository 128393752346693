import {types as EventTypes} from '../events';
import basicReducer from '../../common/reducers/basic-reducer';

import constant from 'lodash/constant';
import get from 'lodash/get';

const initialState = '';

const reductionLookup = {
    [EventTypes.LoggedOut]: constant(initialState),
    [EventTypes.AuthenticationRequested]: constant(initialState),
    [EventTypes.AuthenticationFailed]: constant(initialState),
    [EventTypes.AuthenticationSucceeded]: (_, {result}) => get(result, 'company_subType', ''),
    [EventTypes.SetRetailerType]: (_, type) => type,
};

export default basicReducer(initialState, reductionLookup);