import request from './common/request';

const adminUrl = 'https://api.buyfluidly.com/v1/admin';

export default {
    
    getRetailers: (userToken) => {
        return request({
            method: 'GET',
            url: `${adminUrl}/retailers`,
            userToken,
        });
    },

    getDistributors: (userToken) => {
        return request({
            method: 'GET',
            url: `${adminUrl}/distributors`,
            userToken,
        });
    },
};