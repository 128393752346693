import { connect } from 'react-redux';

import Component from './component';
import Commands from 'state/commands';
import fromState from 'state/selectors';

import merge from 'lodash/merge';
import set from 'lodash/set';

const fields = [
    'address',
    'address2',
    'agreed',
    'businessType',
    'city',
    'companyName',
    'county',
    'counties',
    'ein',
    'email',
    'fullName',
    'phone',
    'state',
    'states',
    'website',
    'zip',
];

function mapStateToProps(state) {
    return {
        fields: fields.reduce((acc, key) => set(acc, key, {
            error: fromState.Profile.fieldError(key)(state),
            value: fromState.Profile.fieldValue(key)(state),
        }), {}),
        isSaved: fromState.Profile.isSaved()(state),
        statesList: fromState.Profile.getStates()(state),
        counties: fromState.Profile.getCounties()(state),
        profile: fromState.Profile.profile()(state),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        fields: fields.reduce((acc, key) => set(acc, key, {
            onChange: value => dispatch(Commands.Profile.UpdateField(key, value)),
        }), {}),
        getProfile: () => dispatch(Commands.Profile.GetProfile()),
        save: () => dispatch(Commands.Profile.Save()), 
        loadStates: () => dispatch(Commands.Profile.LoadStates()), 
        loadCounties: (states) => dispatch(Commands.Profile.LoadCounties(states)),
    };
}

function mergeProps(stateProps, dispatchProps) {
    return merge(stateProps, dispatchProps);
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Component);