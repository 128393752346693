import selectorsCommon from 'state/common/selectors';

import get from 'lodash/get';

export default selectorsCommon.bindToReducer('cart', {
    cart: () => (state) => get(state, 'cart', {}),
    cartRows: () => (state) => get(state, 'cart.rows', 0),
    orders: () => (state) => get(state, 'cart.orders', []),
    orderDetails: () => (state) => get(state, 'orderDetails', {}),
    orderHeaders: () => (state) => get(state, 'orderHeaders', []),
    retailerId: () => (state) => get(state, 'cart.retailer.id', []),
    comments: () => (state) => get(state, 'comments', []),
    isLoading: () => (state) => get(state, 'isLoading', false),
});
