import React from 'react';
import styles from './styles.scss';
import PropTypes from 'prop-types';
import DropDown from '../../components/dropdown';
import get from 'lodash/get';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-responsive-modal';
import TextField from '../../components/text-field';
import { withRouter } from 'react-router';
import { withAlert } from 'react-alert';
import { isMobileOnly, isTablet,  isBrowser} from 'react-device-detect';


class ProductEdit extends React.Component {

    constructor(props){
        super(props);
        this.state = this.props.location.state;
        this.setOnPremisePrice = this.setOnPremisePrice.bind(this);
        this.setOffPremisePrice = this.setOffPremisePrice.bind(this);
        this.setPackagingQty = this.setPackagingQty.bind(this);
        this.loadPackagingTypes = this.loadPackagingTypes.bind(this);
        this.fileSelectedHandler = this.fileSelectedHandler.bind(this);
        this.addItem = this.addItem.bind(this);
        this.onOpenModal = this.onOpenModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
        this.setLinkTitle = this.setLinkTitle.bind(this);
        this.setLinkUrl = this.setLinkUrl.bind(this);
        this.addLink = this.addLink.bind(this);
        this.addUpDateProduct = this.addUpDateProduct.bind(this);
        this.deleteProduct = this.deleteProduct.bind(this);
        this.state = {
            selectedFile: null,
            variants: [],
            links: [],
            product: {
                brand: '',
                name: '',
                categoryId: 2,
                typeId: 0, 
                subTypeId: 0,
                regionId: 1,
                subRegionId: 0,
                sku: '',
                abv: '',
                description: '',
                inStock: '',
            },
        };
    }

    
    componentDidUpdate(prevProps) {
        if(prevProps.productLinks!==this.props.productLinks){
            this.onCloseModal();
        }
    }

    componentDidMount() {
        this.props.loadProductDetails(this.props.location.state.productId).then((result)=>{
            if (get(result, 'type') == 'catalog-load-product-details-succeeded'){
                const productDetails = get(result, 'payload', {});
                this.props.loadCatalogCategoryProductTypes(productDetails.category.id).then(() => {
                    const { product } = this.state;
                    product.brand = productDetails.brand.name || '';
                    product.name = productDetails.name || '';
                    product.categoryId = productDetails.category.id || '';
                    product.typeId = productDetails.type.id || '';
                    product.subTypeId = productDetails.subType.id || '';
                    product.regionId = productDetails.region.id || '';
                    product.subRegionId = productDetails.subRegion.id || '';
                    product.sku = productDetails.sku || '';
                    product.abv = productDetails.abv || '';
                    product.description = productDetails.description || '';
                    product.inStock = productDetails.inStock;
                    product.regionId = productDetails.region.id || '';
                    product.subRegionId = productDetails.subRegion.id || '';
                    this.setState({
                        product: product,
                    });
                });
                this.props.loadProductVariants(this.props.location.state.productId);
                this.props.loadProductLinks(this.props.location.state.productId);
                this.props.loadSubTypes(productDetails.category.id, productDetails.type.id);
                this.props.loadRegions();
                this.props.loadSubRegions(productDetails.region.id);
                this.props.loadPackagingTypes(0);
                this.setPackagingId(0);
            }
        });
    }

    onOpenModal() {
        this.setState({ open: true });
    }
  
    onCloseModal() {
        this.setState({ 
            open: false, 
            newLinkTitle: '',
            newLinkUrl: '', 
        });
    }

    addItem(){
        const productId = this.props.location.state.productId;
        const storeItemObj = {
            masterPackageTypeId: parseInt(this.state.packageTypeId) || 0,
            packageTypeId: parseInt(this.state.packageSubtypeId) || 1,
            packageTypeOther: '', 
            offPremisePricing: parseFloat(this.state.offPremisePrice) || 0,
            onPremisePricing: parseFloat(this.state.onPremisePrice) || 0,
            packageQty: parseInt(this.state.packagingQty) || 1,
        };
        this.props.addProductVariant(productId, storeItemObj);
    }

    deleteVariant(variantId){
        const productId = this.props.location.state.productId;
        this.props.deleteProductVariant(productId, variantId);
    }

    setPackagingId(e){
        this.setState({
            packageTypeId: e,
        });
        this.loadPackagingTypes(e);
    }

    setSubpackagingId(e){
        this.setState({
            packageSubtypeId: e,
        });
    }

    loadPackagingTypes(e){
        this.props.loadPackagingTypes(e).then((result) => {
            const arr = get(result, 'payload');
            this.setState({
                currentPackagingTypes: result,
                packageSubtypeId: arr[0].id,
            });
        });
    }


    loadProductTypes(value){
        const { product } = this.state;
        product.categoryId = value;
        product.subTypeId = '';
        this.props.loadCatalogCategoryProductTypes(value).then(() => {
            this.loadSubTypes(value);
        });
        this.setState({ 
            product,
        });
    }

    //The Type Filter
    loadSubTypes(value){
        const { product } = this.state;
        product.typeId = parseInt(value);
        this.props.loadSubTypes(this.state.categoryId, parseInt(value)).then((result) => {
            const arr = get(result, 'payload.subTypes');
            if(arr.length === 0) { product.subTypeId = -1; product.subTypeId = ''; }
        });
        this.setState({ 
            product,
        });
    }

    loadRegions(value){
        const { product } = this.state;
        product.subTypeId = parseInt(value);
        this.setState({ 
            product,
        });
        this.props.loadRegions();
    }

    loadSubRegions(value){
        const { product } = this.state;
        product.regionId = parseInt(value);
        this.setState({ 
            product,
        });
        this.props.loadSubRegions(value).then((result) => {
            const arr = get(result, 'payload');
            if (arr.length === 0) { product.subRegionId = -1; }
        });
    }

    setSubRegion(value){
        const { product } = this.state;
        product.subRegionId = parseInt(value);
        this.setState({ 
            product,
        });
    }

    setAbv(e){
        const { product } = this.state;
        product.abv = e.target.value;
        this.setState({ 
            product,
        });
    }

    setDescription(e){
        const { product } = this.state;
        product.description = e.target.value;
        this.setState({ 
            product,
        });
    }

    setBrand(e){
        const { product } = this.state;
        product.brand = e.target.value;
        this.setState({ 
            product,
        });
    }

    setProductName(e){
        const { product } = this.state;
        product.name = e.target.value;
        this.setState({ 
            product,
        });
    }

    setSKU(e){
        const { product } = this.state;
        product.sku = e.target.value;
        this.setState({ 
            product,
        });
    }

    setOnPremisePrice(e){
        this.setState({
            onPremisePrice: e.target.value,
        });
    }

    setOffPremisePrice(e){
        this.setState({
            offPremisePrice: e.target.value,
        });
    }

    setPackagingQty(e){
        if(e.target.value > 0) {
            this.setState({
                packagingQty: e.target.value,
            });
        }
    }

    deleteLInk(linkId){
        const productId = this.props.location.state.productId;
        this.props.deleteProductLink(productId, linkId);
    }

    fileSelectedHandler(event) {
        this.setState({
            selectedFile: event.target.files[0],
            localImageFile: URL.createObjectURL(event.target.files[0]),
        });
    }

    addLink(){
        const { newLinkTitle, newLinkUrl} = this.state;
        const productId = this.props.location.state.productId;
        this.props.addProductLink(productId, {title: newLinkTitle, url: newLinkUrl}).then(() => {
            this.onCloseModal();
        });
    }

    setLinkTitle(e){
        this.setState({
            newLinkTitle: e,
        });
    }

    setLinkUrl(e){
        this.setState({
            newLinkUrl: e,
        });
    }

    setStock(value){
        const { product } = this.state;
        product.inStock = parseInt(value);
        this.setState({ 
            product,
        });
    }

    deleteProduct(){
        const productId = this.props.location.state.productId;
        this.props.deleteProduct(productId).then((result) => {
            if(get(result, 'type') === 'catalog-delete-product-succeeded') {
                this.props.alert.show(
                    <div style={{padding: '1rem',}}>Product deleted.</div>,
                    {
                        timeout: 4000,  
                        type: 'success',
                        onClose: () => { this.props.history.push('/distributor-catalog');
                        } 
                    }); 
            }
        });
    }

    addUpDateProduct() {
        const { productVariants } = this.props;
        const { selectedFile, product } = this.state; 
        const productId = this.props.location.state.productId;
        if(productVariants.length>0){
            this.props.updateProduct(product, productId).then((result) => {
                if(get(result, 'type') === 'catalog-update-product-succeeded') {
                    if(selectedFile !== null) {
                        const fd = new FormData();
                        fd.append('image', this.state.selectedFile, this.state.selectedFile.name);
                        this.props.uploadImage(productId, fd);
                    }
                    this.props.alert.show(
                        <div style={{padding: '1rem',}}>Product successfully edited.</div>,
                        {
                            timeout: 4000,  
                            type: 'success',
                            onClose: () => { this.props.history.goBack();
                            } 
                        }); 
                } else if(get(result, 'type') === 'catalog-update-product-failed') {
                    this.props.alert.show(
                        <div style={{padding: '1rem',}}>Product edit failed.</div>,
                        {
                            timeout: 4000,  
                            type: 'success',
                            onClose: () => { this.props.history.goBack();
                            } 
                        }); 
                }
            });
        } else {
            this.props.alert.show(
                <div style={{padding: '3rem',}}>You must include at least one product variant</div>,
                {
                    timeout: 4000,  
                    type: 'success',
                    onClose: () => {} 
                });
        }
    } 
    getMasterType(index){
        switch (index) {
            case 2:
                return 'Keg';
            case 1:
                return 'Can';
            default:
                return 'Bottle';
        }
    }

    render() {
        const availabilityOptions = [
            [0, 'Out of Stock'],
            [1, 'In Stock'],
        ];
        const masterPackagingTypes = [
            [0, 'Bottle'],
            [1, 'Can'],
            [2, 'Keg'],
        ];
        const categoryOptions = get(this.props, 'categories', []).map(category => [get(category, 'id'), get(category, 'title')]);
        const productTypes = get(this.props, 'productTypes', []).map(productType => [get(productType, 'id'), get(productType, 'title')]);
        const subTypes = get(this.props, 'subTypes', []).map(subType => [get(subType, 'id'), get(subType, 'title')]);
        const regionOptions = get(this.props, 'regions', []).map(region => [get(region, 'id'), get(region, 'title')]);
        const subRegionOptions = get(this.props, 'subRegions', []).map(subRegion => [get(subRegion, 'id'), get(subRegion, 'title')]);
        const packagingTypes = get(this.props, 'packagingTypes', []).map(packagingType => [get(packagingType, 'id'), get(packagingType, 'title')]);
        const baseUrl = 'https://api.buyfluidly.com/v1/catalog/products/'; 
        const { open, product, localImageFile } = this.state;
        const { productVariants, productLinks } = this.props;
        return (
            <div>
                <div className={styles.wrapper}>
                    <input 
                        style={{ display: 'none', }} 
                        type="file" 
                        onChange={this.fileSelectedHandler}
                        ref={fileInput => this.fileInput = fileInput}/>
                    <div className="columns">
                        <div className="column is-one-third">
                            <figure className="image is-square" style={{border: '1px solid'}}>
                                {  localImageFile &&
                                    <img src={ localImageFile } 
                                        style={{width: 'auto', height:'auto', display: 'block', maxHeight: '90%', margin: 'auto',}}/>
                                }
                                {   !localImageFile &&
                                    <img src={ baseUrl + this.props.location.state.productId + '/image'} 
                                        style={{width: 'auto', height:'auto', display: 'block', maxHeight: '90%', margin: 'auto',}}/>
                                 
                                }
                                <img src={require('../../images/icon-add-edit-photo.png')} 
                                    style={{width: '4rem', height:'4rem', position: 'absolute', zIndex: 99, marginTop: 'auto', left: '2rem', marginBottom: '2rem', }}
                                    onClick={() => this.fileInput.click()} />
                            </figure>
                            <div style={{padding: !isBrowser ? '1rem 0 0 0' : '1rem 0 0 1rem'}}>
                                <p className="is-size-3 has-text-weight-semibold" style={{padding: '1rem 1rem 1rem 0'}}>Details</p>
                                <p className="is-size-4 has-text-weight-semibold">Category:</p>
                                <DropDown 
                                    value={product.categoryId} 
                                    onChange={(value) => this.loadProductTypes(value)} 
                                    options={categoryOptions} 
                                    placeholder='Select A Category' />

                                <p className="is-size-4 has-text-weight-semibold">Subcategory:</p>
                                <DropDown 
                                    value={product.typeId} 
                                    onChange={(value) => this.loadSubTypes(value)} 
                                    options={productTypes} 
                                    placeholder='Select A Subcategory' />

                                { subTypes.length > 1 && 
                                <div style={{marginBottom: '1rem', }}>
                                    <p className="is-size-4 has-text-weight-semibold">Type:</p>
                                    <DropDown 
                                        value={product.subTypeId} 
                                        onChange={(value) => this.loadRegions(value)} 
                                        options={subTypes} 
                                        placeholder='Select a Type' />
                                </div>
                                }
                                { regionOptions.length > 1 && 
                                <div style={{marginBottom: '1rem', }}>
                                    <p className="is-size-4 has-text-weight-semibold">Region:</p>
                                    <DropDown value={product.regionId} 
                                        onChange={(value) => this.loadSubRegions(value)} 
                                        options={regionOptions} 
                                        placeholder='Select A Region' />
                                </div>
                                }
                                { subRegionOptions.length > 1 && 
                                <div style={{marginBottom: '1rem', }}>
                                    <p className="is-size-4 has-text-weight-semibold">SubRegion:</p>
                                    <DropDown value={product.subRegionId} 
                                        onChange={(value) => this.setSubRegion(value)} 
                                        options={subRegionOptions} 
                                        placeholder='Select A Region' />
                                </div>
                                }
                                <p className="is-size-4 has-text-weight-semibold">ABV:</p>
                                <input 
                                    value={product.abv} 
                                    onChange={(e) => this.setAbv(e)} 
                                    className="input" 
                                    style={{padding: '1.5rem', marginBottom: '1rem'}}/>

                                <p className="is-size-4 has-text-weight-semibold">Availability:</p>
                                <DropDown 
                                    value={product.inStock} 
                                    onChange={(value) => this.setStock(value)} 
                                    options={availabilityOptions} 
                                    placeholder='Select Availability' />
                            </div>
                        </div>
                        <div className="column">
                            <p className="is-size-5">Producer / Brand</p>
                            <input style={{marginBottom: '2rem'}} 
                                onChange={(e) => this.setBrand(e)}
                                className="input is-size-4" 
                                type="text" 
                                placeholder="Enter Producer or Brand Name" 
                                value={product.brand} />
                            <p className="is-size-5">Product Name</p>
                            <input style={{marginBottom: '2rem'}} 
                                onChange={(e) => this.setProductName(e)}
                                className="input is-size-3 has-text-weight-semibold" 
                                placeholder="Enter Product Name" 
                                value={product.name} />
                            <p className="is-size-5">SKU</p>
                            <input  style={{marginBottom: '2rem'}}
                                onChange={(e) => this.setSKU(e)}
                                className="input is-size-5" 
                                placeholder="Enter SKU" 
                                value={product.sku} />
                            <br />
                            { productVariants.map((item, index) => (
                                <div key={index} className="box" style={{ padding: !isBrowser ? '' : '1rem', backgroundColor: '#f1e2c3'}}>
                                    <div className="columns" style={{position: !isBrowser ? 'relative' : '',}}>
                                        <div className="column is-6">
                                            <div className="level">
                                                <div className="level-item">
                                                    <div className={!isMobileOnly ? 'image is-64x64' : 'image is-48x48'}>
                                                        {item.masterTypeId !== null &&
                                                        <img src={require(`../../images/${item.masterTypeId}-icon.png`)} />
                                                        }
                                                    </div>
                                                </div>
                                                <div className="level-item">
                                                    <div className="is-size-4 is-pulled-left">{item.qty}/{item.packageTypeName} {this.getMasterType(item.masterTypeId)}</div>
                                                </div>
                                                <div className="level-item" />
                                            </div>
                                        </div>
                                        <div className="column is-5">
                                            <div className={!isBrowser ? 'has-text-centered' : ''}>
                                                <div>On Premise: ${parseFloat(item.onPremisePrice).toFixed(2)}</div>
                                                <div>Off Premise: ${parseFloat(item.offPremisePrice).toFixed(2)}</div>
                                            </div>
                                        </div>
                                        <div className="column is0" style={{position: !isBrowser ? 'absolute' : '', top: 0, right: 0}}>
                                            <a 
                                                className="delete is-large"
                                                onClick={() => this.deleteVariant(item.id)}></a> 
                        
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div className="box" style={{ padding: '2.5rem', backgroundColor: '#f1e2c3'}}>
                                <div className="columns">
                                    <div className="column is-6">
                                        <DropDown 
                                            onChange={(e) => this.setPackagingId(e)}
                                            options={masterPackagingTypes}
                                            placeholder='Select Package Type'
                                        />
                                        <DropDown 
                                            onChange={(e) => this.setSubpackagingId(e)}
                                            options={packagingTypes}
                                            placeholder='Select Package Size'
                                        />
                                    </div>
                                    <div className="column is-5">
                                        <input style={{marginBottom: '1rem', padding: '1.6rem'}} 
                                            className="input is-size-5" 
                                            type="number" 
                                            onChange={this.setOnPremisePrice}
                                            placeholder="On Premise Price" 
                                            value={this.state.onPremisePrice} />
                                        <input style={{marginBottom: '1rem', padding: '1.6rem'}} 
                                            className="input is-size-5" 
                                            type="number" 
                                            onChange={this.setOffPremisePrice}
                                            placeholder="Off Premise Price" 
                                            value={this.state.offPremisePrice} />
                                    </div>
                                </div>
                                <div className="columns">
                                    <div className={!isBrowser ? 'column is-size-6 is-6' : 'column has-text-right is-size-6 is-6'} style={{marginTop: '.9rem'}}>Packaging Qty</div>
                                    <div className="column has-text-left is-3" style={{position: !isBrowser ? 'relative' : '',}}>
                                        <input style={{marginBottom: '1rem', padding: '1.6rem', width: !isBrowser ? '7rem' : ''}} 
                                            className="input is-size-5" 
                                            onChange={this.setPackagingQty}
                                            type="number" 
                                            placeholder="Qty" 
                                            value={this.state.packagingQty}
                                            onBlur={e => e.target.value === '' ? e.target.value = this.state.packagingQty : null }
                                            onFocus={e => e.target.value == this.state.packagingQty ? e.target.value = '' : null} />
                                        {!isBrowser &&
                                            <div className='column' style={{position: 'absolute', top: 0, right: 0}}>
                                                <FontAwesomeIcon
                                                    className={styles.plusCircle} 
                                                    icon="plus-circle" 
                                                    size='3x'
                                                    onClick={this.addItem}
                                                />
                                            </div>
                                        }
                                    </div>
                                    {isBrowser &&
                                    <div className={classnames('has-text-centered column', 'is-2', styles.vertAlign)} >
                                        <FontAwesomeIcon 
                                            className={styles.plusCircle} 
                                            icon="plus-circle" 
                                            size='3x'
                                            onClick={this.addItem}>
                                        </FontAwesomeIcon>
                                    </div>
                                    }
                                </div>
                            </div>
                            <br /><br />
                            <p className="is-size-4 has-text-weight-semibold">Description</p>
                            <textarea style={{marginTop: '2rem'}} 
                                className="textarea" 
                                rows="9"
                                onChange={(e) => this.setDescription(e)}
                                value={product.description} />
                            <p className="is-size-4 has-text-weight-semibold" style={{marginTop: '2rem'}}>Links</p>
                            <div>
                                {productLinks.map((item, index) => (
                                    <div key={index} style={{margin: '1rem',}}>
                                        <a href={item.url}><p style={{float: 'left',}}>{item.title}</p></a>
                                        <a className="delete" style={{float: 'left', marginLeft: '2rem',}} 
                                            onClick={() => this.deleteLInk(item.id)}></a>
                                        <div style={{clear: 'both',}} />
                                    </div>

                                ))}
                            </div>
                            <div id="spacer" style={{height: '2rem',}} />
                            <div className='level' onClick={this.onOpenModal} style={{position: 'relative',}}>
                                <FontAwesomeIcon icon="plus-circle" size='2x' style={{position: 'absolute',}}></FontAwesomeIcon>
                                <p className="is-size-4 has-text-weight-semibold" style={{marginLeft: '3rem',}}>Add New Link</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="columns" style={{ padding: !isBrowser ? '0 1rem' : '0 0 0 6rem',}}>
                    <div className="column is-3">
                        <button 
                            style={{ margin: !isBrowser ? '2rem 0' : '',}}
                            className="button is-fullwidth"
                            onClick={this.addUpDateProduct}
                        >Save Changes</button>
                    </div>
                    <div className="column" />
                    <div className="column is-2">
                        <button 
                            className="button is-fullwidth is-text"
                            onClick={this.deleteProduct}
                        >Delete Product</button>
                    </div>
                </div>

                <Modal open={open} onClose={this.onCloseModal} center>
                    <div className={styles.modal}>
                        <div className="columns">
                            <div className="column">
                                <h1>Title</h1>
                                <TextField onChange={(e) => this.setLinkTitle(e)} value={this.state.newLinkTitle}/> 
                            </div>
                            <div className="column">
                                <h1>Link</h1>
                                <TextField onChange={(e) => this.setLinkUrl(e)} value={this.state.newLinkUrl}/> 
                            </div>
                        </div>
                        <button 
                            onClick={this.addLink}
                            className="button" 
                            style={{position: 'absolute', bottom: '1rem', textAlign: 'center', left: '50%', marginLeft: '0rem',}}>Add</button>
                    </div>
                </Modal>
            </div>
        );
    }
}

ProductEdit.propTypes = { 
    alert: PropTypes.object,
    addUpdateProduct: PropTypes.func,
    addProductLink: PropTypes.func,
    addProductVariant: PropTypes.func,
    deleteProduct: PropTypes.func,
    deleteProductVariant: PropTypes.func,
    deleteProductLink: PropTypes.func,
    history: PropTypes.object,
    loadRegions: PropTypes.func,
    loadCatalogCategories: PropTypes.func,
    loadPackagingTypes: PropTypes.func,
    loadCatalogCategoryProductTypes: PropTypes.func,
    loadProductVariants: PropTypes.func,
    loadSubTypes: PropTypes.func,
    loadSubRegions: PropTypes.func,
    loadProductDetails: PropTypes.func,
    loadProductLinks: PropTypes.func,
    location: PropTypes.object,
    state: PropTypes.object,
    packagingTypes: PropTypes.array,
    productLinks: PropTypes.array,
    productVariants: PropTypes.array,
    uploadImage: PropTypes.func,
    updateProduct: PropTypes.func,
};


ProductEdit.defaultProps = {
    
};

export default withRouter(withAlert(ProductEdit));
