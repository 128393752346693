import queryString from 'query-string';
import isNil from 'lodash/isNil';
import set from 'lodash/set';

const defaultHeaders = {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
};

const headers = (userToken, impersonationString) => {
    if (!userToken) return defaultHeaders;
    return isNil(impersonationString) ? {
        ...defaultHeaders,
        Authorization: `Bearer ${userToken}`,
    } : 
        {
            ...defaultHeaders,
            Authorization: `Bearer ${userToken}`,
            Impersonate: impersonationString,
        };
};

export default ({method = 'GET', url, query, payload, userToken, impersonationString}) => {
    return fetch(`${url}?${queryString.stringify(query)}`, {
        method,
        headers: headers(userToken, impersonationString),
        body: isNil(payload) ? undefined : JSON.stringify(payload)
    }).then(async response => set(await response.json(), 'statusCode', response.status));
};