import React, { Component } from 'react';
import {render} from 'react-dom';
import {Provider} from 'react-redux';
import { Provider as AlertProvider } from 'react-alert';
import App from './app';
import Api from './api';
import createStore from './createStore';
import { PersistGate } from 'redux-persist/integration/react';
import PropTypes from 'prop-types';
import { isMobileOnly, isTablet,  isBrowser} from 'react-device-detect';

const store = createStore(new Api());

const options = {
    offset: '5rem',
    position: 'top center',
    timeout: 10000,
    transition: 'scale',
    buttonText: 'OK',
    zIndex: 9999,
};


class AlertTemplate extends Component {
    render () {
        const { message, close, options,  } = this.props;
        return (
            <div className='box is-paddingless' style={{marginTop: '6rem',}}>
                <div style={{padding: '1rem'}}>
                    <div className='is-size-5-mobile' style={{padding: isMobileOnly ? '' : '2rem 2rem 0 2rem'}}>{message}
                        {!options.dualAlert &&
                        <button className='button is-fullwidth' onClick={close}>{options.buttonText == null ? 'OK' : options.buttonText}</button>
                        }
                        {options.dualAlert &&
                        <div className='level'>
                            <button className='button is-text' onClick={close}>No, not now.</button>
                            <button className='button' onClick={options.actionTouched}>{options.actionText}</button>
                        </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}


render((
    <Provider store={store.store}>
        <PersistGate loading={null} persistor={store.persistor}>
            <AlertProvider template={AlertTemplate} {...options}>
                <App/>
            </AlertProvider>
        </PersistGate>
    </Provider>
), document.getElementById('app'));


AlertTemplate.propTypes = { 
    message: PropTypes.string,
    options: PropTypes.object,
    close: PropTypes.func,
};

