import { connect } from 'react-redux';

import Component from './component';
import Commands from 'state/commands';
import fromState from 'state/selectors';

function mapStateToProps(state) {
    return {
        orderHeaders: fromState.Cart.orderHeaders()(state),
 
    };
}

function mapDispatchToProps(dispatch) {
    return {
        loadOrderHeaders: () => dispatch(Commands.Cart.LoadOrderHeaders()), 
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);