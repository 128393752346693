import Admin from './admin/selectors';
import Authentication from './authentication/selectors';
import Cart from './cart/selectors';
import Catalog from './catalog/selectors';
import Profile from './profile/selectors';

export default {
    Admin,
    Authentication,
    Cart,
    Catalog,
    Profile,
};
