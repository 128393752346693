import PropTypes from 'prop-types';
import React from 'react';
import MultiCheckbox from '../../components/multi-checkbox';
import {createPropTypesObject, getFieldProperties} from '../../utils/component';
import get from 'lodash/get';
import mapValues from 'lodash/mapValues';
import groupBy from 'lodash/groupBy';
import omit from 'lodash/omit';
import union from 'lodash/union';
import Select from 'react-select';
import AddRepForm from '../../components/add-rep-form';
import { withRouter } from 'react-router';
import { withAlert } from 'react-alert';
import { isMobileOnly, isTablet,  isBrowser} from 'react-device-detect';


class DistributorProfileReps extends React.Component {

    constructor(props){
        super(props); 
        this.state = {
            counties: [],
            countiesArray: [],
            isAddingRep: false,
            selectedOptions: null,
            statesList: [],
            isEditing: false,
        };   
        this.handleChange = this.handleChange.bind(this);
        this.buildArray = this.buildArray.bind(this);
        this.toggleRep = this.toggleRep.bind(this);
        this.newRep = this.newRep.bind(this);
        this.postRep = this.postRep.bind(this);
    }



    componentDidMount(){
        this.props.getReps();
        this.props.getTerritories();
        if(this.props.location.state != null) {
            this.setState({
                isEditing: this.props.location.state.isEditing,
            });
        }
    }

    componentWillUnmount(){
        this.setState({
            isEditing: false,
        });
    }

    handleChange(selectedOptions){
        this.setState({ selectedOptions: selectedOptions, },
            () => {this.buildArray(); });
    }

    newRep(repObject){
        this.setState({
            repObject: repObject,
        });
    }

    buildArray(){
        const statesArray=[];
        const states = this.state.selectedOptions;
        for(let i=states.length-1; i >= 0; i--) {
            statesArray.push(states[i].value);
        }
        this.setState({
            statesList: statesArray,
        }, () => { this.loadCounties(statesArray);});
    }

    loadCounties(states) {
        const arr = Array.prototype.slice.call(states);
        this.props.loadCounties(arr).then(() =>  {
            this.setState({ counties: this.props.counties } );
        });
    }

    toggleRep(){
        if(!this.state.isAddingRep) {
            this.setState({
                isAddingRep: true,
            });
        } else {
            this.addRep();
        }
    }

    addRep(){
        this.setState({
            isAddingRep: false,
        });
    }

    postRep(){
        const emailRegex = new RegExp(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        if(emailRegex.test(this.state.repObject.contactEmail)) {
            this.props.postRep(this.state.repObject).then(result => {
                if(get(result, 'type') === 'profile-post-rep-succeeded') {
                    this.props.getReps().then(() => {
                        this.addRep();
                    });
                }
            });
        } else {
            this.props.alert.show(
                <div style={{padding: '3rem',}}>Please be sure all fields are filled out and email is valid.</div>,
                {
                    timeout: 4000,  
                    type: 'success',
                    onClose: () => {} 
                });
        }
    }

    setCountiesArray(arr){
        const { countiesArray } = this.state;
        const newArray = union(countiesArray, arr);
        this.setState({
            countiesArray: newArray,
        });
    }

    postCounties(){
        if(this.state.countiesArray.length>0){
            this.props.postTerritories(this.state.countiesArray).then(result => {
                if(get(result, 'type') === 'profile-post-territories-succeeded') {
                    this.props.history.push('/distributor-dashboard');
                }
            });
        } else {
            this.props.history.push('/distributor-dashboard');
        }
    }

    render() {
        const { selectedOptions, isEditing } = this.state;
        const { deleteRep, reps, statesList, territories, } = this.props;
        const fields = get(this.props, 'fields', {});
        const states = getFieldProperties(fields, 'states');
        const counties = getFieldProperties(fields, 'counties');
        const agreed = getFieldProperties(fields, 'agreed');
        const sortedTerritories = mapValues(groupBy(territories, 'State'),
            list => list.map(item => omit(item, 'State')));
        const arr = [];
        const keys = Object.keys(sortedTerritories); 
        for(let i=0; i<keys.length; i++){
            const counties = [];
            for(let j=0; j<sortedTerritories[keys[i]].length; j++){
                counties.push(sortedTerritories[keys[i]][j].Name);
            }
            arr.push({'State' : keys[i], 'Counties' : counties});
        }
        return (
            <div>
                <section className='section profile-form' style={{padding: isBrowser ? '3rem' : '1rem'}}>
                    <div className='columns'>
                        <div className='column is-12'>
                            <div style={{ width: !isMobileOnly ? '65%' : '95%',}}>
                                <div className={isMobileOnly ? 'is-size-5 title' : 'is-size-3 title'}>Additional Distributor Information</div>
                                {reps.length > 0 &&
                                <div className='is-size-5 title'>Sales Reps</div>
                                }
                                {reps.length > 0 && reps.map((item, index) => (
                                    <div className="card" key={index}>
                                        <div className="card-content">
                                            <a 
                                                className="delete is-large is-pulled-right" 
                                                style={{display: 'flex', alignItems: 'center',}}
                                                onClick={() => deleteRep(item.id)}></a>
                                            <p><b>Name: </b>{item.contactName}</p>
                                            <p><b>Phone: </b>{item.contactPhone}</p>
                                            <p><b>Email: </b>{item.contactEmail}</p>
                                        </div>
                                    </div>
                                ))}

                                { 
                                    this.state.isAddingRep && <AddRepForm addRep={this.newRep}/>
                                }
                                {  this.state.isAddingRep &&
                                <a 
                                    onClick={this.postRep}
                                    className="button is-text is-paddingless is-size-5" 
                                    style={{margin: '1rem 1rem 1rem 0'}}>
                                    <a>Add this rep</a>
                                </a>
                                }
                                {  !this.state.isAddingRep &&
                                <a 
                                    onClick={this.toggleRep}
                                    className={ isMobileOnly ? 'button is-text is-paddingless is-size-6' : 'button is-text is-paddingless is-size-4'} 
                                    style={{margin: '1rem 1rem 1rem 0'}}>
                                    <a>Add additional sales representative contacts</a>
                                </a>
                                }
                                { isEditing &&
                                <div className="content" style={{marginTop: '3rem'}}>
                                    <h1 className="is-size-4 title">Current Territory</h1>
                                    <span className='tile is-parent is-horizontal'>
                                        { arr.length>0 && 
                                        arr.map((item, index) => (
                                            <div key={index} className='card' style={{padding: '1rem'}}>
                                                <h3>{item.State}</h3>
                                                { item.Counties.map((item, index) => (
                                                    <p key={index}>{item}</p>
                                                ))
                                                }
                                            </div>
                                        ))
                                        }
                                    </span>
                                </div>
                                }
                                <div className="content" style={{marginTop: '3rem'}}>
                                    {
                                        isEditing ? <h1 className="is-size-4 title">Reset your Territory</h1> :
                                            <h1 className="is-size-4 title">Territory</h1>
                                    }
                                    { !isEditing ?
                                        <p>Select the general delivery territory where you distribute products by selecting the applicable states and counties. 
                                        This will allow product visibility to customers located within the selected counties.</p>
                                    
                                        : <p>If you choose to change or add any territories, any previously saved territories will be overwritten.</p>
                                    }
                                    <p><b>Please choose all of your states first and then select from the listed counties.</b></p>
                                </div>
                                <Select 
                                    {...states}
                                    isMulti={true}
                                    options={statesList}
                                    value={selectedOptions}
                                    onChange={this.handleChange}
                                    placeholder='Select one or more states' />
                            </div>
                            {this.state.statesList.map((item, index) => (
                                <MultiCheckbox 
                                    {...counties}
                                    key={index}
                                    labelText={this.state.statesList[index]} 
                                    list={(this.props.counties.length===0) ? this.state.counties : this.props.counties} 
                                    onChange={(arr) => this.setCountiesArray(arr)} />
                            ))}
                            <div className='columns'>
                                <div className='column is-8' style={{margin: '4rem 0'}}>
                                    <input 
                                        {...agreed}
                                        className='button is-fullwidth'
                                        type='submit'
                                        value='Continue'
                                        onClick={() => this.postCounties()}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='column'></div>
                    </div>
                </section>
                <div id='vertSpacer' style={{height: '10rem'}} />
            </div>
        );
    }

}

DistributorProfileReps.propTypes = {
    fields: PropTypes.shape({
        ...createPropTypesObject([
            'address',
            'businessType',
            'companyName',
            'city',
            'state',
            'zip',
            'website',
            'ein',
            'fullName',
            'phone',
            'states',
            'counties',
            'agreed',
            'state',
            'county',
            'email',
        ], PropTypes.shape({
            error: PropTypes.string,
            value: PropTypes.string,
            onChange: PropTypes.func.isRequired,
        }))
    }),
    alert: PropTypes.object,
    deleteRep: PropTypes.func,
    isSaved: PropTypes.bool,
    location: PropTypes.object.isRequired,
    getReps: PropTypes.func,
    getTerritories: PropTypes.func,
    postTerritories: PropTypes.func,
    handleChange: PropTypes.func,
    loadCounties: PropTypes.func,
    postRep: PropTypes.func,
    reps: PropTypes.array,
    statesList: PropTypes.array,
    territories: PropTypes.array,
    counties: PropTypes.array,
    history: PropTypes.object.isRequired,
    isAddingRep: PropTypes.bool,
};

export default withAlert(withRouter(DistributorProfileReps));
