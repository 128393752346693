import { connect } from 'react-redux';
import Component from './component';
import Commands from 'state/commands';

function mapStateToProps() {
    return {};
}


function mapDispatchToProps(dispatch) {
    return {   
        fireEmail: (email) => dispatch(Commands.Profile.FireEmail(email)),     
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);