import React from 'react';
import Filters from '../../components/filters';
import styles from './styles.scss';
import PropTypes from 'prop-types';
import { Table, Thead, Tbody, Tr, Th } from 'react-super-responsive-table';
import TableRow from '../../components/table-row';
import { withRouter } from 'react-router';
import Pagination from 'react-js-pagination';
import get from 'lodash/get';
import SegmentedControl from '../../components/newest-segmented';
import { isMobileOnly, isTablet,  isBrowser} from 'react-device-detect';
import { BeatLoader } from 'react-spinners';


class ConsumerDashBoard extends React.Component {

    constructor(){
        super();
        this.state = {
            productSelected: false,
            productId: -1,
            mobileFiltersVisible: false,
            resultsFiltered: false,
        };
        this.loadCatalogCategoryProductTypes = this.loadCatalogCategoryProductTypes.bind(this);
        this.loadSubTypes = this.loadSubTypes.bind(this);
        this.loadSubRegions = this.loadSubRegions.bind(this);
        this.loadPackagingTypes = this.loadPackagingTypes.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.searchCatalogProducts = this.searchCatalogProducts.bind(this);
        this.toggleMobileFilters = this.toggleMobileFilters.bind(this);
    }

    componentDidMount() {
        this.props.loadRegions().then((result) => {
            if (get(result, 'payload') === 'Unauthorized.'){
                this.props.onLogout().then(() => this.props.history.push('/'));
            }
        });
        if(isBrowser) {
            this.props.loadDistributors();
            this.props.loadCatalogCategories();
            this.props.loadCatalogBrands();
            this.props.searchCatalogProducts();
            this.props.loadPackagingTypes(-1);
        }
    }

    loadCatalogCategoryProductTypes(id) {
        return this.props.loadCatalogCategoryProductTypes(id);
    }

    loadSubTypes(categoryId, typeId) {
        this.props.loadSubTypes(categoryId, typeId);
    }

    loadSubRegions(id) {
        this.props.loadSubRegions(id);
    }

    loadPackagingTypes(id){
        this.props.loadPackagingTypes(id);
    }

    handleRowClick(productId) {
        this.props.history.push({pathname: '/product-details', state: { productId: productId }, });
    }

    handlePageChange(pageNumber) {
        this.props.setActivePage(pageNumber);
    }

    searchCatalogProducts(distId, productName, brandId, categoryId, typeId, subTypeId, regionId, subRegionId, packageTypeId, priceLow, priceHigh) {
        this.handlePageChange(1);
        this.props.searchCatalogProducts(distId, productName, brandId, categoryId, typeId, subTypeId, regionId, subRegionId, packageTypeId, priceLow, priceHigh);
    }

    toggleMobileFilters(){
        this.props.history.push('/mobile-filters');
    }

    toggleSegment(selection){
        this.props.setShowingNew(selection);
        this.props.searchCatalogProducts(-1, '');
    }


    render() {
        const { mobileFiltersVisible } = this.state;
        const { products, activePage, retailerType, currentUsertype, isSearching } = this.props;
        const itemsPerPage = 8;
        const range = Math.ceil(products.length/itemsPerPage);
        const items = isBrowser ? products.slice((activePage-1)*itemsPerPage, (activePage-1)*itemsPerPage+itemsPerPage)
            : products;
        return (
            <div>
                <section className={styles.wrapper}>
                    <div className='columns'>
                        <div className='column is-3'>
                            <div className='title' style={{position: 'relative'}}>Product Catalog 
                                { !isBrowser &&
                            <div style={{ paddingTop: '3rem', }}>
                                <div style={{position: 'absolute', bottom: '0px', left: '5px', }}>
                                    <SegmentedControl onToggle={(id) => this.toggleSegment(id)} value={this.props.showingNew}/>
                                </div>
                                <div 
                                    onClick={this.toggleMobileFilters}
                                    className='image is-32x32' 
                                    style={{position: 'absolute', top: '10px', right: '10px',}}>
                                    <img src={require('../../images/icon-filters.png')} />
                                </div>

                            </div>
                                }
                            </div>
                            { isBrowser &&
                            <Filters
                                onSearch={this.searchCatalogProducts}
                                brands={this.props.brands}
                                categories={this.props.categories}
                                distributors={this.props.distributors}
                                regions={this.props.regions} 
                                subRegions={this.props.subRegions}
                                productTypes={this.props.productTypes}
                                subTypes={this.props.subTypes}  
                                packagingTypes={this.props.packagingTypes}
                                loadPackagingTypes={this.loadPackagingTypes}
                                loadProductTypes={this.loadCatalogCategoryProductTypes}
                                loadSubTypes={this.loadSubTypes}
                                loadSubRegions={this.loadSubRegions} />
                            }
                        </div>
                        <div className='column'>
                            <div className='level is-hidden-mobile is-marginless'>
                                <div className='subtitle level-item-left'style={{paddingTop: '10px'}}>
                                    <SegmentedControl onToggle={(id) => this.toggleSegment(id)} value={this.props.showingNew}/>
                                </div>
                                <div className="pagination level-item-right">
                                    <Pagination
                                        activeLinkClass='is-current'
                                        linkClassPrev='pagination-previous'
                                        linkClassNext='pagination-next'
                                        innerClass='pagination-list'
                                        linkClass='pagination-link'
                                        activePage={activePage}
                                        itemsCountPerPage={itemsPerPage}
                                        totalItemsCount={products.length}
                                        pageRangeDisplayed={ isBrowser ? range < 6 ? range : 5 : range < 4 ? range : 3}
                                        onChange={this.handlePageChange}
                                    />
                                </div>
                            </div>
                            <Table className='table' style={{border: !isBrowser ? 0 : '0.5px solid', borderRadius: 10, borderColor: '#ccc', margin: isBrowser ? 0 : '2rem 0 0 0'}}>
                                <Thead className='thead'>
                                    <Tr style={{ borderWidth: '1px', borderColor: '#333', backgroundColor: '#f1e2c3',}}>
                                        <Th style={{padding: '1rem 0 0 2rem',}} colSpan={5}>Product</Th>
                                        <Th style={{padding: '1rem',}}>Price</Th>
                                    </Tr>
                                </Thead>
                            </Table>
                            <div style={{border: !isBrowser || isSearching ? '' : '1px solid #eee'}}>
                                <Table>
                                    <Tbody className='tbody'>
                                        { isSearching &&
                                        <div className='sweet-loading' style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '35%',}}>
                                            <BeatLoader
                                                sizeUnit={'px'}
                                                size={20}
                                                color={'#29393e'}
                                                loading={true}
                                            />
                                        </div> 
                                        }
                                        { !isSearching && items.length === 0 &&
                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center',}}>
                                            <h3 className='subtitle' style={{marginTop: '20%', padding: '0 3rem 20rem 3rem', color: '#bbb'}}>No results match your search critera. Please alter your search and try again...</h3>
                                        </div> 
                                        }
                                        {items.map((item, index) => (
                                            <TableRow
                                                key={index}
                                                onRowClick={this.handleRowClick}
                                                className='trow'
                                                productType={item.category.name} 
                                                productId={item.id}
                                                title={item.brand.name} 
                                                subtitle1={item.name} 
                                                subtitle2={item.category.name + ' ' + item.type.name} 
                                                subtitle3={item.subType.name}
                                                availablePackages={item.availablePackages}
                                                inStock={item.inStock} 
                                                currentUsertype={currentUsertype}
                                                retailerType={retailerType} />
                                        ))}
                                    </Tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </section>
                { mobileFiltersVisible &&
                <div className='is-hidden-desktop is-hidden-tablet' 
                    style={{position: 'absolute', width: '100%', height: '100%', top: 0, left: 0, 
                        backgroundColor: 'rgb(0,0,0,0.9)', zIndex: 999999, padding: '2rem'}}>
                    <Filters
                        onSearch={this.searchCatalogProducts}
                        brands={this.props.brands}
                        categories={this.props.categories}
                        distributors={this.props.distributors}
                        regions={this.props.regions} 
                        subRegions={this.props.subRegions}
                        productTypes={this.props.productTypes}
                        subTypes={this.props.subTypes}  
                        packagingTypes={this.props.packagingTypes}
                        loadPackagingTypes={this.loadPackagingTypes}
                        loadProductTypes={this.loadCatalogCategoryProductTypes}
                        loadSubTypes={this.loadSubTypes}
                        loadSubRegions={this.loadSubRegions} />
                </div>
                }
            </div>
        );
    }
}

export default withRouter(ConsumerDashBoard);

ConsumerDashBoard.propTypes = { 
    currentUsertype: PropTypes.number,
    isSearching: PropTypes.bool,
    retailerType: PropTypes.number,
    activePage: PropTypes.number,
    setActivePage: PropTypes.func,
    history: PropTypes.object,
    loadDistributors: PropTypes.func,
    loadRegions: PropTypes.func,
    loadCatalogCategories: PropTypes.func,
    loadCatalogCategoryProductTypes: PropTypes.func,
    loadSubTypes: PropTypes.func,
    loadSubRegions: PropTypes.func,
    loadPackagingTypes: PropTypes.func,
    loadCatalogBrands: PropTypes.func,
    categories: PropTypes.array,
    distributors: PropTypes.array,
    onLogout: PropTypes.func,
    regions: PropTypes.array,
    productTypes: PropTypes.array,
    products: PropTypes.array,
    searchCatalogProducts: PropTypes.func,
    setShowingNew: PropTypes.func,
    showingNew: PropTypes.number,
    subTypes: PropTypes.array,
    subRegions: PropTypes.array,
    packagingTypes: PropTypes.array,
    id: PropTypes.number,
    brands: PropTypes.array,
};

ConsumerDashBoard.defaultProps = {
    activePage: 1,
};



