import React from 'react';
import styles from './styles.scss';
import { Table, Thead, Tbody, Tr, Th } from 'react-super-responsive-table';
import OrderRow from '../../components/order-row';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import { withRouter } from 'react-router';
import { isMobileOnly, isTablet,  isBrowser} from 'react-device-detect';

class ConsumerOrderDetails extends React.Component {

    constructor(props){
        super(props);
        this.state ={};
    }

    componentDidMount() {
        const { orderId } = this.props.location.state;
        this.props.loadOrderDetails(orderId).then((result)=> {
            if (get(result, 'type') === 'cart-load-order-details-succeeded'){
                const orders = get(result, 'payload.orders', []);
                const orderDetails = get(result, 'payload', {});
                const retailerId = get(orderDetails, 'retailer.id');
                const { distributorId } = this.props.location.state;

                this.props.getCustomerNum(distributorId, retailerId).then((result) => {
                    const customerNum = get(result, 'payload.number', 'N/A');
                    const repId = get(result, 'payload.repId', 'N/A');
                    const repName = get(result, 'payload.repName', 'N/A');
                    this.setState({
                        distributorId: distributorId,
                        retailerId: retailerId,
                        orders: orders,
                        orderDetails: orderDetails,
                        orderId: orderId,
                        customerNum: customerNum,
                        repId: repId,
                        repName: repName,
                    });
                });
                
            }
        });
        this.props.loadComments(orderId);
    }

    buildSum(lineItems){
        let total = 0;
        for(let i=lineItems.length-1; i>=0; i--) {
            total += lineItems[i].price * lineItems[i].qty;
        }
        return parseFloat(total).toFixed(2);
    }

    render() {
        const { distributorId } = this.props.location.state;
        const { orderDetails, comments } = this.props;
        const { customerNum, repName } = this.state;
        const orders = get(orderDetails, 'orders', []);
        const order = find(orders, ['distributor.id', distributorId]);
        const lineItems = get(order, 'lineItems', []);
        const createdDate = get(orderDetails, 'submitDate', new Date());
        const orderNum = get(order, 'id', -1);
        const name = get(order, 'distributor.name', '');
        const address1 = get(order, 'distributor.address1', '');
        const address2 = get(order, 'distributor.address2', '');
        const city = get(order, 'distributor.city', '');
        const state = get(order, 'distributor.state', '');
        const postalCode = get(order, 'distributor.postalCode', '');
        const contactName = get(order, 'distributor.contactName', '');
        const contactPhone = get(order, 'distributor.contactPhone', '');
        const contactEmail = get(order, 'distributor.contactEmail', '');
        
        return (
            <div className={styles.wrapper}>
                <div className="columns" style={{position: 'relative'}}>
                    <div className="column is-7">
                        <h1 className="title">{name}</h1>
                        <p className="subtitle">{address1}</p>
                        { !isEmpty(address2)  && <p className="subtitle">{address2}</p> }
                        <p className="subtitle" style={{marginTop: '-1.5rem',}}>{city}, {state} {postalCode}</p>
                        <p className="subtitle" style={{marginTop: '-1.5rem',}}>Attn: {contactName}</p>
                        <p className="subtitle" style={{marginTop: '-1rem',}}><a href={'tel:'+contactPhone}>{contactPhone}</a></p>
                        <p className="is-size-5" style={{marginTop: '-1.5rem',}}><a href={'mailto:'+contactEmail}>{contactEmail}</a></p>

                    </div>
                    { isTablet &&
                        <div style={{backgroundColor: '#f1e2c3', width: '50%', position: 'absolute', top:10, right: 10, padding: '2rem', }}>
                            <div className='level is-mobile'>
                                <div style={{display: 'flex'}} className='level-item-left'>
                                    <h1><b>Date:</b></h1>
                                    <h1 className='is-size-6' style={{marginLeft: '6px'}}>{new Date(createdDate).toLocaleDateString()}</h1>
                                </div>
                                <div style={{display: 'flex'}} className='level-item-right'>
                                    <h1><b>Order#</b></h1>
                                    <h1 className="is-size-6" style={{marginLeft: '6px'}}>{orderNum}</h1>
                                </div>
                            </div>
                            <div className="level is-mobile">
                                <h1 className="level-item"><b>Customer#</b></h1>
                                <h1 className="is-size-6 level-item box has-background-white" 
                                    style={{padding: '.5rem',}}>{customerNum}</h1>
                            </div>
                        </div>
                    }
                    <div className="column has-text-centered">
                        <div className="box is-hidden-mobile" style={{backgroundColor: '#f1e2c3'}}>
                            <div className="level">
                                <h1 className="subtitle is-marginless level-item-left">Date</h1>
                                <h1 className="is-size-5 level-item-left is-marginless">{new Date(createdDate).toLocaleDateString()}</h1>
                            </div>
                            <div className="level">
                                <h1 className="subtitle is-marginless level-item-left">Order #</h1>
                                <h1 className="is-size-5 level-item-left is-marginless">{orderNum}</h1>
                            </div>
                            <div className="level box has-background-white" style={{padding: '0.75rem'}}>
                                <span className="subtitle is-marginless level-item-left" style={{ display: 'flex', }}>
                                    <h3 style={{fontSize: '15px'}}>Customer#</h3>
                                    <span className="is-size-6 is-marginless" 
                                        style={{padding: '.2rem 1rem', marginLeft: '5px',}}>{customerNum}</span>
                                </span>
                                <span className="subtitle is-marginless level-item-right" style={{ display: 'flex', }}>
                                    <h3 style={{fontSize: '15px'}}>Rep:</h3>
                                    <span className="is-size-6 is-marginless" 
                                        style={{padding: '.2rem 1rem', marginLeft: '5px',}}>{repName}</span>
                                </span>
                            </div>
                        </div>
                        { !isTablet &&
                        <div className="box is-hidden-desktop is-hidden-tablet" style={{backgroundColor: '#f1e2c3'}}>
                            <div className='level is-mobile'>
                                <div style={{display: 'flex'}} className='level-item-left'>
                                    <h1><b>Date:</b></h1>
                                    <h1 className='is-size-6' style={{marginLeft: '6px'}}>{new Date(createdDate).toLocaleDateString()}</h1>
                                </div>
                                <div style={{display: 'flex'}} className='level-item-right'>
                                    <h1><b>Order#</b></h1>
                                    <h1 className="is-size-6" style={{marginLeft: '6px'}}>{orderNum}</h1>
                                </div>
                            </div>
                            <div className="level is-mobile">
                                <h1 className="level-item"><b>Customer#</b></h1>
                                <h1 className="is-size-6 level-item box has-background-white" 
                                    style={{padding: '.5rem',}}>{customerNum}</h1>
                            </div>
                            <div className="level is-mobile">
                                <h1 className="level-item"><b>Rep:</b></h1>
                                <h1 className="is-size-6 level-item box has-background-white" 
                                    style={{padding: '.5rem',}}>{repName}</h1>
                            </div>
                        </div>
                        }
                    </div>
                </div>
                <Table className='table' style={{border: '0.5px solid', borderRadius: 10, borderColor: '#ccc', marginTop: isMobileOnly ? '': '4rem',}}>
                    <Thead className='thead'>
                        <Tr style={{ borderWidth: '1px', borderColor: '#333', backgroundColor: '#f1e2c3',}}>
                            <Th/>
                            <Th colSpan={2}>SKU</Th>
                            <Th colSpan={32}>Description</Th>
                            <Th /><Th />
                            <Th colSpan={2}>Packaging</Th>
                            <Th colSpan={2}>Qty</Th>
                            <Th colSpan={2}>Price</Th>
                            <Th colSpan={2}>Sub-Total</Th>
                        </Tr>
                    </Thead>
                    <Tbody className='tbody'>
                        { lineItems.map((item, index) => (
                            <OrderRow
                                key={index}
                                onRowClick={this.handleRowClick}
                                className='trow'
                                sku={item.product.sku}
                                name={item.product.name}
                                packageType={ item.productVariant.qty + '/' +   item.productVariant.packageTypeName + ' ' + item.productVariant.masterType}
                                price={item.price}
                                qty={item.qty} />
                        ))}
                    </Tbody>
                </Table>
                <div className="columns">
                    <div className="column is-7" style={{padding: isMobileOnly ? '0' : '2rem 5rem 1rem 1rem',}}>
                        { comments.length>0 &&
                        <div>
                            <p className="subtitle">Comments</p>
                            { comments.map((item, index) => (
                                <div className="box" key={index} style={{position: 'relative',}}>
                                    <p className={ (item.retailerId) ? 'has-text-left' : 'has-text-right'}>{(item.retailerId) ? 'you:  ' + item.commentText : `${name}: ` + item.commentText}</p>
                                    <a className="delete is-medium" 
                                        style={(item.retailerId) ? 
                                            {position: 'absolute', right:'1rem', top: '1rem',} 
                                            : {position: 'absolute', left:'1rem', top: '1rem',}}
                                        onClick={() => this.props.deleteComment(orderNum, item.id)}></a>
                                </div>
                            ))}
                        </div>
                        }
                    </div>
                    {!isMobileOnly &&
                    <div className="column" />
                    }
                    <div className="column is-4" style={{position: 'relative',}}>
                        <div style={{padding: '1rem',}}>
                            <div className="level">
                                <h1 className="subtitle is-marginless level-item-left">Subtotal</h1>
                                <h1 className="is-size-5 level-item-left is-marginless">${this.buildSum(lineItems)}</h1>
                            </div>
                            <div className="level">
                                <h1 className="subtitle is-marginless level-item-left">Total</h1>
                                <h1 className="is-size-5 level-item-left">${this.buildSum(lineItems)}</h1>
                            </div>
                        </div>
                        <p style={{padding: '1rem',}}>Note: Any applicable deposits, taxes and delivery costs will be invoiced directly by the supplier.</p>
                    </div>
                </div>
            </div>

        );
    }
}

export default withRouter(ConsumerOrderDetails);

ConsumerOrderDetails.propTypes = { 
    buildSum: PropTypes.func,
    deleteComment: PropTypes.func,
    getCustomerNum: PropTypes.func,
    loadOrderDetails: PropTypes.func,
    loadComments: PropTypes.func,
    location: PropTypes.object,
    orderDetails: PropTypes.object,
    comments: PropTypes.array,
    verifyOrder: PropTypes.func,

};
 