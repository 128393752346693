import request from './common/request';
import set from 'lodash/set';

const catalogUrl = 'https://api.buyfluidly.com/v1/catalog';
const distributorsUrl = 'https://api.buyfluidly.com/v1/distributors';
const productsUrl = `${catalogUrl}/products`;
const brandsUrl = `${catalogUrl}/brands`;
const categoriesUrl = `${catalogUrl}/categories`;
const regionsUrl = `${catalogUrl}/regions`;
const packagingTypesUrl = `${catalogUrl}/packagingTypes`;


export default {

    cloneImage: (userToken, productId, toProductID, impersonationString) => {
        return request({
            method: 'POST',
            url: `${productsUrl}/${productId}/image/cloneTo/${toProductID}`,
            userToken,
            impersonationString,
        });
    },

    uploadImage: (userToken, productId, file) => {
        const imgUrl = `${productsUrl}/${productId}/image`;
        return fetch(imgUrl, { 
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + userToken,
            },
            body: file 
        }).then(async response => set(await response.json(), 'statusCode', response.status));
    },

    addUpdateProduct: (userToken, productObj, impersonationString) => {
        return request({
            method: 'POST',
            url: `${productsUrl}`,
            payload: productObj,
            userToken,
            impersonationString,
        });
    },

    deleteProduct: (userToken, productId, impersonationString) => {
        return request({
            method: 'DELETE',
            url: `${productsUrl}/${productId}`,
            userToken,
            impersonationString,
        });
    },

    updateProduct: (userToken, productObj, productId, impersonationString) => {
        return request({
            method: 'POST',
            url: `${productsUrl}/${productId}`,
            payload: productObj,
            userToken,
            impersonationString,
        });
    },

    addProductVariant: (userToken, productId, storeItemObj, impersonationString) => {
        return request({
            method: 'POST',
            url: `${productsUrl}/${productId}/storeItems`,
            payload: storeItemObj,
            userToken,
            impersonationString,
        });
    },

    deleteProductVariant: (userToken, productId, storeItemId, impersonationString) => {
        return request({
            method: 'DELETE',
            url: `${productsUrl}/${productId}/storeItems/${storeItemId}`,
            userToken,
            impersonationString,
        });
    },

    getProductVariants: (userToken, productId, impersonationString) => {
        return request({
            method: 'GET',
            url: `${productsUrl}/${productId}/storeItems`,
            userToken,
            impersonationString,
        });
    },

    getProductSuggestions: (userToken, searchString, impersonationString) => {
        return request({
            method: 'GET',
            url: `${productsUrl}/autocomplete`,
            query: { name: searchString },
            userToken,
            impersonationString,
        });
    },

    addProductLink: (userToken, productId, linkObj, impersonationString) => {
        return request({
            method: 'POST',
            url: `${productsUrl}/${productId}/links/`,
            payload: linkObj,
            userToken,
            impersonationString,
        });
    },

    deleteProductLink: (userToken, productId, linkId, impersonationString) => {
        return request({
            method: 'DELETE',
            url: `${productsUrl}/${productId}/links/${linkId}`,
            userToken,
            impersonationString,
        });
    },
    getCatalogBrands: (userToken, impersonationString) => {
        return request({
            method: 'GET',
            url: brandsUrl,
            userToken,
            impersonationString,
        });
    },
    getCatalogCategories: (userToken, impersonationString) => {
        return request({
            method: 'GET',
            url: categoriesUrl,
            userToken,
            impersonationString,
        });
    },
    getProductTypesForCatalogCategory: (userToken, category, impersonationString) => {
        return request({
            method: 'GET',
            url: `${categoriesUrl}/${category}/types`,
            userToken,
            impersonationString,
        });
    },
    getSubTypes: (userToken, category, type, impersonationString) => {
        return request({
            method: 'GET',
            url: `${categoriesUrl}/${category}/types/${type}/subTypes`,
            userToken,
            impersonationString,
        });
    },
    getRegions: (userToken, impersonationString) => {
        return request({
            method: 'GET',
            url: regionsUrl,
            userToken,
            impersonationString,
        });
    },
    getSubRegions: (userToken, id, impersonationString) => {
        return request({
            method: 'GET',
            url: `${regionsUrl}/${id}/subRegions`,
            userToken,
            impersonationString,
        });
    },
    getProductDetails: (userToken, productId, impersonationString) => {
        return request({
            method: 'GET',
            url: `${productsUrl}/${productId}`,
            userToken,
            impersonationString,
        });
    },
    getProductLinks: (userToken, productId, impersonationString) => {
        return request({
            method: 'GET',
            url: `${productsUrl}/${productId}/links`,
            userToken,
            impersonationString,
        });
    },

    getPackagingTypes: (userToken, id, impersonationString) => {
        return request({
            method: 'GET',
            url: `${packagingTypesUrl}/${id}`,
            userToken,
            impersonationString,
        });
    },
    loadDistributors: (userToken, impersonationString) => {
        return request({
            method: 'GET',
            url: distributorsUrl,
            userToken,
            impersonationString,
        });
    },
    searchCatalogProducts: (userToken, distId, productName, brandId, categoryId, typeId, subTypeId, regionId, subRegionId, packagingTypeId, minPrice, maxPrice, showNew, impersonationString) => {
        return request({
            method: 'GET',
            url: productsUrl,
            query: {
                distributorId: parseInt(distId),
                name:productName,
                brandId:parseInt(brandId),
                categoryId:parseInt(categoryId),
                typeId:parseInt(typeId),
                subTypeId:parseInt(subTypeId),
                regionId:parseInt(regionId),
                subRegionId:parseInt(subRegionId) ,
                packageTypeId: parseInt(packagingTypeId),
                priceLow: parseInt(minPrice),
                priceHigh: parseInt(maxPrice),
                showNew: parseInt(showNew),
            },
            userToken,
            impersonationString,
        });
    },
};