import {buildEvents, noParameters, singleParameter, namedParams} from '../../common/events';

const definition = {

    AddCommentFailed: noParameters,
    AddCommentSucceeded: singleParameter,

    AddLineItemStarted: noParameters,
    AddLineItemFailed: noParameters,
    AddLineItemSucceeded: singleParameter,

    CartSummaryFailed: noParameters,
    CartSummarySucceeded: singleParameter,

    DeleteLineItemStarted: noParameters,
    DeleteLineItemFailed: noParameters,
    DeleteLineItemSucceeded: singleParameter,

    DeleteCommentFailed: noParameters,
    DeleteCommentSucceeded: singleParameter,

    LoadCartStarted: noParameters,
    LoadCartFailed: noParameters,
    LoadCartSucceeded: singleParameter,

    LoadCommentsStarted: noParameters,
    LoadCommentsFailed: noParameters,
    LoadCommentsSucceeded: singleParameter,

    OrderDetailsLoading: noParameters,
    LoadOrderDetailsFailed: singleParameter,
    LoadOrderDetailsSucceeded: singleParameter,

    OrderHeadersLoading: noParameters,
    LoadOrderHeadersFailed: singleParameter,
    LoadOrderHeadersSucceeded: singleParameter,

    SubmitCartStarted: noParameters,
    SubmitCartFailed: noParameters,
    SubmitCartSucceeded: singleParameter,

    VerifyOrderStarted: noParameters,
    VerifyOrderFailed: noParameters,
    VerifyOrderSucceeded: singleParameter,

    QtyUpdated: namedParams('index','row', 'value'),
    CommentUpdated: namedParams('index', 'value'),

};

const {types, events} = buildEvents('cart', definition);

export {types};

export default events;