import selectorsCommon from 'state/common/selectors';
import get from 'lodash/get';


export default selectorsCommon.bindToReducer('profile', {
    fieldError: selectorsCommon.lookup('fieldErrors'),
    fieldValue: selectorsCommon.lookup('fieldValues'),
    companyName: () => (state) => get(state, 'profile.name', ''),
    customerNum: () => (state) => get(state, 'customerNum', []),
    getStates: () => (state) => get(state, 'states', []),
    getCounties: () => (state) => get(state, 'counties', []),
    getReps: () => (state) => get(state, 'reps', []),
    repsForDist: () => (state) => get(state, 'reps', []),
    retailerId: () => (state) => get(state, 'profile.id', ''),
    hasAttemptedToSave: () => (state) => get(state, 'hasAttemptedToSave', false),
    isSaved: () => (state) => get(state, 'isSaved', false),
    postRep: () => (state) => get(state),
    profile: () => (state) => get(state, 'profile', {}),
    retailerType: () => (state) => get(state, 'retailerType', {}),
    territories: () => (state) => get(state, 'territories', {}),
});
 