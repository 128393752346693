import {combineReducers} from 'redux';

import addLineItem from './add-line-item';
import isLoading from './is-loading';
import cart from './cart';
import orderHeaders from './order-headers';
import orderDetails from './order-details';
import comments from './comments';

export default combineReducers({
    addLineItem,
    isLoading,
    comments,
    orderDetails,
    orderHeaders,
    cart,
});
