import {types as AuthEventTypes} from '../../authentication/events';
import {types as EventTypes} from '../events';
import basicReducer from '../../common/reducers/basic-reducer';

import constant from 'lodash/constant';

const initialState = false;

const reductionLookup = {
    [AuthEventTypes.LoggedOut]: constant(initialState),
    [EventTypes.StopImpersonating]: constant(initialState),
    [EventTypes.StartImpersonating]: constant(true),
};

export default basicReducer(initialState, reductionLookup);