exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-pages-consumer-cart-styles--wrapper {\n  padding: 3rem; }\n  @media (min-width: 481px) and (max-width: 1023px) {\n    .src-pages-consumer-cart-styles--wrapper {\n      padding: 1rem; } }\n  @media (min-width: 320px) and (max-width: 480px) {\n    .src-pages-consumer-cart-styles--wrapper {\n      padding: 0; } }\n\n.src-pages-consumer-cart-styles--overlay {\n  position: absolute;\n  background: #000;\n  opacity: .3;\n  left: 0;\n  right: 0;\n  top: 0;\n  bottom: 0;\n  z-index: 1; }\n", ""]);

// exports
exports.locals = {
	"wrapper": "src-pages-consumer-cart-styles--wrapper",
	"overlay": "src-pages-consumer-cart-styles--overlay"
};