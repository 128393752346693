import React, {Component} from 'react';
import styles from './styles.scss';
import { withRouter } from 'react-router';
import TextInput from '../../components/text-input';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { withAlert } from 'react-alert';

class ConfirmPassword extends Component {

    constructor(props){
        super(props);
        this.state = {
            email: this.props.location.state.email,
            verificationCode: '',
            password: '',
            passwordMatch: '',
        };
    }

    validate() {
        const { verificationCode, password, passwordMatch } = this.state;
        const passwordRegex = new RegExp(/^(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,}$/);
        const codeRegex = new RegExp(/\d{6}/i);
        if (!codeRegex.test(verificationCode)) { 
            return 'Confirmation code must be a 6 digit number'; 
        }   
        if (!passwordRegex.test(password)) { 
            return 'Password must be at least eight characters including one uppercase letter, one number, and one special character';  
        }
        if (passwordMatch !== password) {
            return 'Passwords do not match.';
        }
        return 'valid';
    }

    onContinue() {
        if(this.validate() === 'valid') {
            this.resetPassword();
        } else {
            this.props.alert.show(
                <div style={{padding: '3rem',}}>{this.validate()}</div>,
                {
                    timeout: 4000,  
                    type: 'success',
                    onClose: () => {} 
                });
        }
    }

    resetPassword(){
        const { email, verificationCode, password } = this.state;
        this.props.resetPassword(email, verificationCode, password).then((result) => {
            if(get(result, 'type') === 'profile-reset-password-succeeded'){
                this.props.alert.show(
                    <div style={{padding: '3rem',}}>Password reset, please login to continue.</div>,
                    {
                        timeout: 4000,  
                        type: 'success',
                        onClose: () => { this.props.history.push('/');
                        } 
                    });
            } else {
                this.props.alert.show(
                    <div style={{padding: '3rem',}}>Error resetting password. Please aquire a new code and try again.</div>,
                    {
                        timeout: 4000,  
                        type: 'success',
                        onClose: () => { this.props.history.push('/');
                        } 
                    });
            }
        });
    }

    render(){
        const { verificationCode, password, passwordMatch } = this.state;
        return (
            <div className={styles.wrapper}>
                <div className='columns'>
                    <div className={classnames('column', 'is-half', styles.wrapper)}>
                        <h3 className='title'>Check your email! We sent you a confirmation code.</h3>
                    </div>
                    <div className={classnames('column', 'is-half', styles.wrapper)}>
                        <TextInput 
                            labelText="Enter Confirmation Code" 
                            value={verificationCode}
                            childChange={(e) => this.setState({ verificationCode: e.target.value })}
                        />
                        <TextInput 
                            type='password'
                            labelText="Enter New Password" 
                            value={password}
                            childChange={(e) => this.setState({ password: e.target.value })}
                        />
                        <TextInput 
                            type='password'
                            labelText="Confirm New Password" 
                            value={passwordMatch}
                            childChange={(e) => this.setState({ passwordMatch: e.target.value })}
                        />
                        <button 
                            className="button is-fullwidth" 
                            style={{marginTop: '3rem'}}
                            onClick={this.onContinue.bind(this)}
                        >Continue</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default withAlert(withRouter(ConfirmPassword));

ConfirmPassword.propTypes = {
    alert: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    resetPassword: PropTypes.func,
};