import request from './common/request';

const geoUrl = 'https://api.buyfluidly.com/v1/geo';
const usersUrl = 'https://api.buyfluidly.com/v1/users/me';
const distUrl = 'https://api.buyfluidly.com/v1/distributors/me';
const distURL = 'https://api.buyfluidly.com/v1/distributors/distributor';
const territoriesUrl = `${distUrl}/territories`;
const statesUrl = `${geoUrl}/states`;
const countiesUrl = `${geoUrl}/counties`;
const profileUrl = `${usersUrl}/companyProfile`;

export default {

    updateRepInfo: (userToken, distributorId, retailerId, newRepId, newCustomerNum, impersonationString) => {
        return request({
            method: 'POST',
            url: `${distURL}/${distributorId}/accounts/${retailerId}`,
            payload: { 'repId': newRepId, 'accountNumber': newCustomerNum },
            userToken,
            impersonationString,
        });
    },

    resetPassword: (email, verificationCode, password) => {
        return request({
            method: 'POST',
            url: `${usersUrl}/resetPassword`,
            payload: { username: email, verification_code: verificationCode, password: password },
        });
    },

    fireEmail: (email) => {
        return request({
            method: 'POST',
            url: `${usersUrl}/forgotPassword`,
            payload: { username: email },
        });
    },

    getProfile: (userToken, impersonationString) => {
        return request({
            method: 'GET',
            url: profileUrl,
            userToken,
            impersonationString,
        });
    },

    saveProfile: (profile, userToken, impersonationString) => {
        debugger;
        return request({
            method: 'POST',
            url: profileUrl,
            payload: profile,
            userToken,
            impersonationString,
        });
    },

    getStates: (userToken, impersonationString) => {
        return request({
            method: 'GET',
            url: statesUrl,
            userToken,
            impersonationString,
        });
    },

    getCounties: (states, userToken, impersonationString) => {
        return request({
            method: 'GET',
            url: countiesUrl,
            query: {states: states.join(',')},
            userToken,
            impersonationString,
        });
    },

    getTerritories: (userToken, impersonationString) => {
        return request({
            method: 'GET',
            url: territoriesUrl,
            userToken,
            impersonationString,
        });
    },

    postTerritories: (territories, userToken, impersonationString) => {
        return request({
            method: 'POST',
            url: `${distUrl}/territories`,
            payload: {counties: territories.join(',')},
            userToken,
            impersonationString,
        });
    },

    getReps: (userToken, impersonationString) => {
        return request({
            method: 'GET',
            url: `${distUrl}/reps`,
            userToken,
            impersonationString,
        });
    },

    hasAccount: (userToken, distributorId, retailerId, impersonationString) => {
        return request({
            method: 'GET',
            url: `${distURL}/${distributorId}/accounts/${retailerId}`,
            userToken,
            impersonationString,
        });
    },

    getRepsForDist: (distributorId, userToken, impersonationString) => {
        return request({
            method: 'GET',
            url: `${distURL}/${distributorId}/reps`,
            userToken,
            impersonationString,
        });
    },

    getCustomerNum: (distributorId, retailerId, userToken, impersonationString) => {
        return request({
            method: 'GET',
            url: `${distURL}/${distributorId}/accounts/${retailerId}`,
            userToken,
            impersonationString,
        });
    },

    deleteRep: (id, userToken, impersonationString) => {
        return request({
            method: 'DELETE',
            url: `${distUrl}/reps/${id}`,
            userToken,
            impersonationString,
        });
    },

    postRep: (repObject, userToken, impersonationString) => {
        return request({
            method: 'POST',
            url: `${distUrl}/reps`,
            payload: repObject,
            userToken,
            impersonationString,
        });
    },

    submitAccountInfo: (distributorId, retailerId, repId, accountNumber, userToken, impersonationString) => {
        return request({
            method: 'POST',
            url: `${distURL}/${distributorId}/accounts/${retailerId}`,
            payload: (repId !== null) ? { repId: repId, accountNumber: accountNumber } : { accountNumber: accountNumber },
            userToken,
            impersonationString,
        });
    },

};