import {buildEvents, noParameters, singleParameter, namedParams} from '../../common/events';

const definition = {

    GetRetailersFailed: noParameters,
    GetRetailersSucceeded: singleParameter,

    GetDistributorsFailed: noParameters,
    GetDistributorsSucceeded: singleParameter,

    StopImpersonating: singleParameter,
    StartImpersonating: singleParameter,

    Impersonating: namedParams('impersonationString', 'impersonationName', 'company_id', 'company_type'),

};

const {types, events} = buildEvents('admin', definition);

export {types};

export default events;