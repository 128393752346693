import Events from '../events';
import fromState from 'state/selectors';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import isNil from 'lodash/isNil';


const LoadComments = (orderId) => (dispatch, getState, api) => {
    dispatch(Events.LoadCommentsStarted());

    const userToken = fromState.Authentication.currentToken()(getState());
    const impersonationString = fromState.Admin.impersonationString()(getState());     
    return api.loadComments(userToken, orderId, impersonationString).then(result => {
        const commentsResult = get(result, 'with');
        if (commentsResult === 401) {
            return dispatch(Events.LoadCommentsFailed('Unauthorized.'));
        } else if (commentsResult === 404) {
            return dispatch(Events.LoadCommentsFailed('Comments Not Found.'));
        } else if (commentsResult === 500) {
            return dispatch(Events.LoadCommentsFailed('An unknown error occurred when attempting to get comments.'));
        }
        return dispatch(Events.LoadCommentsSucceeded(commentsResult));
    }, 
    error => dispatch(Events.LoadCommentsFailed(error)));
};

const AddComment = (orderId, commentString) => (dispatch, getState, api) => {
    const userToken = fromState.Authentication.currentToken()(getState());
    const impersonationString = fromState.Admin.impersonationString()(getState());    
    const refreshTokenTimeoutId = fromState.Authentication.refreshTokenTimeoutId()(getState());
    if(!isEmpty(refreshTokenTimeoutId)) clearTimeout(refreshTokenTimeoutId);   

    return api.addComment(userToken, orderId, commentString, impersonationString).then(cartResult => {
        const result = get(cartResult, 'with');
        
        if (result === 401) {
            return dispatch(Events.AddCommentSucceeded('failed to post comment.'));
        } else if (result === 500) {
            return dispatch(Events.AddCommentFailed('An error occurred when attempting to post comment.'));
        }

        dispatch(SetRefreshTokenTimeout(get(result, 'expires_in')));
        
        return dispatch(Events.AddCommentSucceeded(result));

    }, 
    error => dispatch(Events.AddCommentFailed(error)));
};

const DeleteComment = (orderId, commentId) => (dispatch, getState, api) => {
    const userToken = fromState.Authentication.currentToken()(getState());
    const impersonationString = fromState.Admin.impersonationString()(getState());        
    return api.deleteComment(userToken, orderId, commentId, impersonationString).then(() => {
        dispatch(LoadComments(orderId));
    }, 
    error => dispatch(Events.DeleteCommentFailed(error)));
};

const LoadCart = () => (dispatch, getState, api) => {
    dispatch(Events.LoadCartStarted());
    const userToken = fromState.Authentication.currentToken()(getState());
    const impersonationString = fromState.Admin.impersonationString()(getState());      
    const refreshTokenTimeoutId = fromState.Authentication.refreshTokenTimeoutId()(getState());
    if(!isEmpty(refreshTokenTimeoutId)) clearTimeout(refreshTokenTimeoutId);   

    return api.loadCart(userToken, impersonationString).then(cartResult => {
        const result = get(cartResult, 'with');
        
        if (result === 401) {
            return dispatch(Events.LoadCartFailed('failed to load cart.'));
        } else if (result === 500) {
            return dispatch(Events.LoadCartFailed('An error occurred when attempting to load cart.'));
        }
        dispatch(Events.LoadCartSucceeded(result));
        dispatch(CartSummary());
        return dispatch(SetRefreshTokenTimeout(get(result, 'expires_in')));
    }, 
    error => dispatch(Events.LoadCartFailed(error)));
};

const SubmitCart = () => (dispatch, getState, api) => {
    dispatch(Events.SubmitCartStarted());
    const userToken = fromState.Authentication.currentToken()(getState());
    const impersonationString = fromState.Admin.impersonationString()(getState());
    const refreshTokenTimeoutId = fromState.Authentication.refreshTokenTimeoutId()(getState());
    if(!isEmpty(refreshTokenTimeoutId)) clearTimeout(refreshTokenTimeoutId);   

    return api.submitCart(userToken, impersonationString).then(submitResult => {
        const result = get(submitResult, 'with');
        
        if (result === 401) {
            return dispatch(Events.SubmitCartFailed('failed to submit cart.'));
        } else if (result === 500) {
            return dispatch(Events.SubmitCartFailed('An error occurred when attempting to submit cart.'));
        }
        dispatch(CartSummary());
        dispatch(SetRefreshTokenTimeout(get(result, 'expires_in')));
        return dispatch(Events.SubmitCartSucceeded(result));
    }, 
    error => dispatch(Events.SubmitCartFailed(error)));
};

const VerifyOrder = (orderId) => (dispatch, getState, api) => {
    dispatch(Events.VerifyOrderStarted());
    const userToken = fromState.Authentication.currentToken()(getState());
    const impersonationString = fromState.Admin.impersonationString()(getState());
    const refreshTokenTimeoutId = fromState.Authentication.refreshTokenTimeoutId()(getState());
    if(!isEmpty(refreshTokenTimeoutId)) clearTimeout(refreshTokenTimeoutId);   

    return api.verifyOrder(userToken, orderId, impersonationString).then(verifyResult => {
  
        const result = verifyResult.with;
        if (result === 401) {
            return dispatch(Events.VerifyOrderFailed('failed to verify order.'));
        } else if (result === 500) {
            return dispatch(Events.VerifyOrderFailed('An error occurred when attempting to verify order.'));
        }
        
        dispatch(SetRefreshTokenTimeout(get(result, 'expires_in')));
        return dispatch(Events.VerifyOrderSucceeded(result));
    }, 
    error => dispatch(Events.VerifyOrderFailed(error)));
};

const CartSummary = () => (dispatch, getState, api) => {
    const userToken = fromState.Authentication.currentToken()(getState());
    const impersonationString = fromState.Admin.impersonationString()(getState());
    const refreshTokenTimeoutId = fromState.Authentication.refreshTokenTimeoutId()(getState());
    if(!isEmpty(refreshTokenTimeoutId)) clearTimeout(refreshTokenTimeoutId);   

    return api.cartSummary(userToken, impersonationString).then(cartResult => {
        const result = get(cartResult, 'with');
        
        dispatch(Events.CartSummarySucceeded(result));

        return dispatch(SetRefreshTokenTimeout(get(result, 'expires_in')));
    }, 
    error => dispatch(Events.CartSummaryFailed(error)));
};

const LoadOrderHeaders = () => (dispatch, getState, api) => {
    dispatch(Events.OrderHeadersLoading());
    const userToken = fromState.Authentication.currentToken()(getState()); 
    const impersonationString = fromState.Admin.impersonationString()(getState());   
    return api.loadOrderHeaders(userToken, impersonationString).then(result => {
        const ordersResult = get(result, 'with');

        if (ordersResult === 401) {
            return dispatch(Events.LoadOrderHeadersFailed('Unauthorized.'));
        } else if (ordersResult === 404) {
            return dispatch(Events.LoadOrderHeadersFailed('Orders Not Found.'));
        } else if (ordersResult === 500) {
            return dispatch(Events.LoadOrderHeadersFailed('An unknown error occurred when attempting to get product orders.'));
        }
        return dispatch(Events.LoadOrderHeadersSucceeded(ordersResult));
    }, 
    error => dispatch(Events.LoadOrderHeadersFailed(error)));
};

const LoadOrderDetails = (orderId) => (dispatch, getState, api) => {
    dispatch(Events.OrderDetailsLoading());

    const userToken = fromState.Authentication.currentToken()(getState()); 
    const impersonationString = fromState.Admin.impersonationString()(getState());   
    return api.loadOrderDetails(userToken, orderId, impersonationString).then(result => {
        const orderDetailsResult = get(result, 'with');
        if (orderDetailsResult === 401) {
            return dispatch(Events.LoadOrderDetailsFailed('Unauthorized.'));
        } else if (orderDetailsResult === 404) {
            return dispatch(Events.LoadOrderDetailsFailed('Order Details Not Found.'));
        } else if (orderDetailsResult === 500) {
            return dispatch(Events.LoadOrderDetailsFailed('An unknown error occurred when attempting to get order details.'));
        }
        return dispatch(Events.LoadOrderDetailsSucceeded(orderDetailsResult));
    }, 
    error => dispatch(Events.LoadOrderDetailsFailed(error)));
};


const AddLineItem = (productId, productVariantId, price, qty) => (dispatch, getState, api) => {
    dispatch(Events.AddLineItemStarted());

    const userToken = fromState.Authentication.currentToken()(getState());
    const impersonationString = fromState.Admin.impersonationString()(getState());   
    const refreshTokenTimeoutId = fromState.Authentication.refreshTokenTimeoutId()(getState());
    if(!isEmpty(refreshTokenTimeoutId)) clearTimeout(refreshTokenTimeoutId);   

    const itemObj = { productId: productId, productVariantId: productVariantId, price: price, qty: qty};
    return api.addLineItem(userToken, itemObj, impersonationString).then(addResult => {
        const result = get(addResult, 'with');
        
        if (result === 401) {
            return dispatch(Events.AddLineItemFailed('failed to add item to cart.'));
        } else if (result === 500) {
            return dispatch(Events.AddLineItemFailed('An error occurred when attempting to add item to cart.'));
        }

        dispatch(Events.AddLineItemSucceeded(result));
        dispatch(LoadCart());

        return dispatch(SetRefreshTokenTimeout(get(result, 'expires_in')));
    }, 
    error => dispatch(Events.AddLineItemFailed(error)));
};

const DeleteLineItem = (id) => (dispatch, getState, api) => {
    dispatch(Events.AddLineItemStarted());

    const userToken = fromState.Authentication.currentToken()(getState());
    const impersonationString = fromState.Admin.impersonationString()(getState());
    const refreshTokenTimeoutId = fromState.Authentication.refreshTokenTimeoutId()(getState());
    if(!isEmpty(refreshTokenTimeoutId)) clearTimeout(refreshTokenTimeoutId);   

    return api.deleteLineItem(userToken, id, impersonationString).then(() => {
        
        dispatch(LoadCart());
    }, 
    error => dispatch(Events.DeleteLineItemFailed(error)));
};

const SetRefreshTokenTimeout = (timeoutInSeconds) => (dispatch) => {    
    if( !isNil(timeoutInSeconds) && isNumber(timeoutInSeconds) ) {
        const timeoutInMilliseconds = timeoutInSeconds * 1000;
        if( timeoutInMilliseconds > 0 ){
            const refreshLogoutTimeoutId = setTimeout(() => dispatch(Events.RefreshTokenTimeoutTriggered()), timeoutInMilliseconds);

            dispatch(Events.RefreshTokenTimoutIdCreated(refreshLogoutTimeoutId));
        }
    }
};

const UpdateCartItem = (id, qty) => (dispatch, getState, api) => {
    const userToken = fromState.Authentication.currentToken()(getState());
    const impersonationString = fromState.Admin.impersonationString()(getState());
    const refreshTokenTimeoutId = fromState.Authentication.refreshTokenTimeoutId()(getState());
    if(!isEmpty(refreshTokenTimeoutId)) clearTimeout(refreshTokenTimeoutId);   

    return api.updateCartItem(userToken, id, qty, impersonationString).then(() => {
    }, 
    error => dispatch(Events.UpdateCartItemFailed(error)));
};

const UpdateQty = (index, row, value, id) => (dispatch) => {
    dispatch(UpdateCartItem(id, value)).then(() => {
        dispatch(Events.QtyUpdated(index, row, value, id));
    });
    
};

const UpdateComment = (index, value) => (dispatch) => dispatch(Events.CommentUpdated(index, value));


export default {
    AddComment,
    AddLineItem,
    CartSummary,
    DeleteComment,
    DeleteLineItem,
    LoadCart,
    LoadOrderDetails,
    LoadOrderHeaders,
    LoadComments,
    SubmitCart,
    UpdateCartItem,
    UpdateQty,
    UpdateComment,
    VerifyOrder,
};