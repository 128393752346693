import Events from '../events';
import fromState from 'state/selectors';
import ProfileCommands from '../../profile/commands';
import CartCommands from '../../cart/commands';
import CatalogCommands from '../../catalog/commands';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import isNil from 'lodash/isNil';

const Login = (username, password) => (dispatch, getState, api) => {
    dispatch(Events.AuthenticationRequested());

    const refreshTokenTimeoutId = fromState.Authentication.refreshTokenTimeoutId()(getState());
    if(!isEmpty(refreshTokenTimeoutId)) clearTimeout(refreshTokenTimeoutId);   

    return api.loginUser(username, password).then(authResult => {
        const result = get(authResult, 'with');
        
        if (result === 401) {
            return dispatch(Events.AuthenticationFailed('Invalid username or password.'));
        } else if (result === 500) {
            return dispatch(Events.AuthenticationFailed('An error occurred when attempting to login.'));
        }
        const type = get(result, 'company_type');
        if(type === '1') {
            dispatch(CartCommands.LoadCart());
        }
        dispatch(Events.AuthenticationSucceeded(username, result));

        dispatch(CatalogCommands.SearchCatalogProducts(-1, ''));
        dispatch(ProfileCommands.GetProfile());

        return dispatch(SetRefreshTokenTimeout(get(result, 'expires_in')));

    }, 
    error => dispatch(Events.AuthenticationFailed(error)));
};


const Logout = () => (dispatch, getState, api) => {
    const userToken = fromState.Authentication.currentToken()(getState());
    const refreshTokenTimeoutId = fromState.Authentication.refreshTokenTimeoutId()(getState());

    if(!isEmpty(refreshTokenTimeoutId)) clearTimeout(refreshTokenTimeoutId);   

    return api.logoutUser(userToken).then(() => dispatch(Events.LoggedOut()));
};

const Signup = (username, password, companyType = '1') => (dispatch, getState, api) => {
    dispatch(Events.SignupRequested());

    const refreshTokenTimeoutId = fromState.Authentication.refreshTokenTimeoutId()(getState());
    if(!isEmpty(refreshTokenTimeoutId)) clearTimeout(refreshTokenTimeoutId);   

    return api.signupUser(username, password, companyType).then(authResult => {
        const result = get(authResult, 'with');
        
        if (result === 400) {
            const because = get(authResult, 'because');
            if( because === 'UserAlreadyExists' ) {
                return dispatch(Events.SignupFailed('User already exists with the same username.'));
            } else if( because === 'InvalidUsername' ) {
                return dispatch(Events.SignupFailed('Invalid username.'));
            } else if( because === 'InvalidPassword' ) {
                return dispatch(Events.SignupFailed('Invalid password.'));
            } else {
                return dispatch(Events.SignupFailed('An error occurred when attempting to signup.'));
            }
        } else if (result === 500) {
            return dispatch(Events.SignupFailed('An error occurred when attempting to signup.'));
        }

        dispatch(Events.SignupSucceeded());
        //TODO: Probably only need to do this if it's a distributor
        dispatch(ProfileCommands.LoadStates());
        return dispatch(Login(username, password));
    }, 
    error => dispatch(Events.SignupFailed(error)));
};

const RefreshToken = () => (dispatch, getState, api) => {
    const userToken = fromState.Authentication.currentToken()(getState());
    const refreshToken = fromState.Authentication.refreshToken()(getState());    
    
    const refreshTokenTimeoutId = fromState.Authentication.refreshTokenTimeoutId()(getState());
    if(!isEmpty(refreshTokenTimeoutId)) clearTimeout(refreshTokenTimeoutId);   

    return api.refreshToken(userToken, refreshToken).then(authResult => {
        const result = get(authResult, 'with');
        
        if (result === 401 || result === 500 ) {
            dispatch(Events.TokenRefreshFailed());
            return dispatch(Logout());
        } else {
            dispatch(Events.TokenRefreshSucceeded(result));            
            return dispatch(SetRefreshTokenTimeout(get(result, 'expires_in')));
        }
    }, 
    error => {
        dispatch(Events.TokenRefreshFailed(error));
        return dispatch(Logout());
    });
};

const SetRefreshTokenTimeout = (timeoutInSeconds) => (dispatch) => {    
    if( !isNil(timeoutInSeconds) && isNumber(timeoutInSeconds) ) {
        const timeoutInMilliseconds = timeoutInSeconds * 1000;
        if( timeoutInMilliseconds > 0 ){
            const refreshLogoutTimeoutId = setTimeout(() => dispatch(Events.RefreshTokenTimeoutTriggered()), timeoutInMilliseconds);

            dispatch(Events.RefreshTokenTimoutIdCreated(refreshLogoutTimeoutId));
        }
    }
};

export default {
    Login,
    Logout,
    RefreshToken,
    Signup,
};