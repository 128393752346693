import { connect } from 'react-redux';

import Component from './component';
import Commands from 'state/commands';
import fromState from 'state/selectors';

function mapStateToProps(state) {
    return {
        cart: fromState.Cart.cart()(state),
        currentUsertype: fromState.Authentication.currentUsertype()(state), 
        isLoading: fromState.Cart.isLoading()(state),
        orders: fromState.Cart.orders()(state),
        retailerType: fromState.Authentication.retailerType()(state),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        deleteLineItem: (id) => dispatch(Commands.Cart.DeleteLineItem(id)),
        getCustomerNum: (distributorId, retailerId) => dispatch(Commands.Profile.GetCustomerNum(distributorId, retailerId)),
        loadCart: () => dispatch(Commands.Cart.LoadCart()),
        submitCart: () => dispatch(Commands.Cart.SubmitCart()),
        addComment: (orderId, commentString) => dispatch(Commands.Cart.AddComment(orderId, commentString)),
        updateQty: (index, row, value, id) => dispatch(Commands.Cart.UpdateQty(index, row, value, id)),
        updateComment: (index, value) => dispatch(Commands.Cart.UpdateComment(index, value)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);