import fromPairs from 'lodash/fromPairs';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import stubObject from 'lodash/stubObject';
import get from 'lodash/get';
import constant from 'lodash/constant';
import has from 'lodash/has';
import omit from 'lodash/omit';


export const createPropTypesObject = (names, propType) => {
    if (isNil(names) || isEmpty(names) || isNil(propType)) return stubObject();
    return fromPairs(names.map(field => [field, propType]));
};

export const getFieldProperties = (fields, name, defaultValue = '') => {
    const field = get(fields, name);
    const ret = {
        ...(isObject(field) ? field : {}),
        value: get(fields, `${name}.value`, defaultValue),
        error: get(fields, `${name}.error`, ''),
        hasError: !isEmpty(get(fields, `${name}.error`, '')),
        onChange: get(fields, `${name}.onChange`, constant),
        disabled: get(fields, `${name}.isDisabled`, false),
        ...omit(field, [
            'value',
            'error',
            'onChange',
            'isDisabled',
            'options',
            'isVisible',
        ]),
    };
    if (has(fields, `${name}.options`)) ret.options = get(fields, `${name}.options`, []);
    if (has(fields, `${name}.isVisible`)) ret.isVisible = get(fields, `${name}.isVisible`, true);
    
    return ret;
};

export const getSingleFieldProperties = (field, defaultValue = '') => getFieldProperties({field}, 'field', defaultValue);