import React, {Component} from 'react';
import Filters from '../../components/filters';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import get from 'lodash/get';



class MobileFilters extends Component {

    constructor(){
        super();
        this.state = {
            productSelected: false,
            productId: -1,
            mobileFiltersVisible: false,
            resultsFiltered: false,
        };
        this.loadCatalogCategoryProductTypes = this.loadCatalogCategoryProductTypes.bind(this);
        this.loadSubTypes = this.loadSubTypes.bind(this);
        this.loadSubRegions = this.loadSubRegions.bind(this);
        this.loadPackagingTypes = this.loadPackagingTypes.bind(this);
        this.searchCatalogProducts = this.searchCatalogProducts.bind(this);
        
    }

    componentDidMount() {
        this.props.loadRegions().then((result) => {
            if (get(result, 'payload') === 'Unauthorized.'){
                this.props.onLogout().then(() => this.props.history.push('/'));
            }
        });
        this.props.loadDistributors();
        this.props.loadCatalogCategories();
        this.props.loadCatalogBrands();
        this.props.searchCatalogProducts();
        this.props.loadPackagingTypes(-1);

    }

    loadCatalogCategoryProductTypes(id) {
        return this.props.loadCatalogCategoryProductTypes(id);
    }

    loadSubTypes(categoryId, typeId) {
        this.props.loadSubTypes(categoryId, typeId);
    }

    loadSubRegions(id) {
        this.props.loadSubRegions(id);
    }

    loadPackagingTypes(id){
        this.props.loadPackagingTypes(id);
    }

    searchCatalogProducts(distId, productName, brandId, categoryId, typeId, subTypeId, regionId, subRegionId, packageTypeId, priceLow, priceHigh) {
        this.props.searchCatalogProducts(distId, productName, brandId, categoryId, typeId, subTypeId, regionId, subRegionId, packageTypeId, priceLow, priceHigh).then(() => {
            this.props.history.goBack();
        });
    }

    render () {
        return (
            <div style={{ padding: '2rem', }}>
                <Filters
                    hideDistFilter={this.props.currentUsertype == 2}
                    onSearch={this.searchCatalogProducts}
                    brands={this.props.brands}
                    categories={this.props.categories}
                    distributors={this.props.distributors}
                    regions={this.props.regions} 
                    subRegions={this.props.subRegions}
                    productTypes={this.props.productTypes}
                    subTypes={this.props.subTypes}  
                    packagingTypes={this.props.packagingTypes}
                    loadPackagingTypes={this.loadPackagingTypes}
                    loadProductTypes={this.loadCatalogCategoryProductTypes}
                    loadSubTypes={this.loadSubTypes}
                    loadSubRegions={this.loadSubRegions} />
            </div>
        );
    }
}
     
export default withRouter(MobileFilters);

MobileFilters.propTypes = { 
    currentUsertype: PropTypes.number,
    isSearching: PropTypes.bool,
    retailerType: PropTypes.number,
    activePage: PropTypes.number,
    setActivePage: PropTypes.func,
    history: PropTypes.object,
    loadDistributors: PropTypes.func,
    loadRegions: PropTypes.func,
    loadCatalogCategories: PropTypes.func,
    loadCatalogCategoryProductTypes: PropTypes.func,
    loadSubTypes: PropTypes.func,
    loadSubRegions: PropTypes.func,
    loadPackagingTypes: PropTypes.func,
    loadCatalogBrands: PropTypes.func,
    categories: PropTypes.array,
    distributors: PropTypes.array,
    onLogout: PropTypes.func,
    regions: PropTypes.array,
    productTypes: PropTypes.array,
    products: PropTypes.array,
    searchCatalogProducts: PropTypes.func,
    subTypes: PropTypes.array,
    subRegions: PropTypes.array,
    packagingTypes: PropTypes.array,
    id: PropTypes.number,
    brands: PropTypes.array,
};
