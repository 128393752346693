import {types as EventTypes} from '../events';
import {types as AuthEventTypes} from '../../authentication/events';
import basicReducer from '../../common/reducers/basic-reducer';

import constant from 'lodash/constant';
import get from 'lodash/get';
import set from 'lodash/set';

const initialState = {
    comment: '',
    rows: 0,
};

const updateQty = (state, {index, row, value}) => {
    const orders = get(state, 'orders', []);
    return {
        ...state,
        orders: set(orders, [index, 'lineItems', row, 'qty'], value),
    };
};

const updateComment = (state, {index, value}) => {
    const orders = get(state, 'orders', []);
    return {
        ...state,
        orders: set(orders, [index, 'comment'], value)
    };
};

const cartSummary = (state, result) => ({
    ...state,
    rows: get(result, 'rows', 0)
});

const reductionLookup = {
    [AuthEventTypes.LoggedOut]: constant(initialState),
    [EventTypes.LoadCartStarted]: constant(initialState),
    [EventTypes.LoadCartFailed]: constant(initialState),
    [EventTypes.LoadCartSucceeded]: (_, result) => result,

    [EventTypes.SubmitCartStarted]: constant(initialState),
    [EventTypes.SubmitCartFailed]: constant(initialState),
    [EventTypes.SubmitCartSucceeded]: (_, result) => result,

    [EventTypes.VerifyOrderStarted]: constant(initialState),
    [EventTypes.VerifyOrderFailed]: constant(initialState),
    [EventTypes.VerifyOrderSucceeded]: (_, result) => result,

    [EventTypes.QtyUpdated]: updateQty,
    [EventTypes.CommentUpdated]: updateComment,

    [EventTypes.CartSummarySucceeded]: cartSummary,

};

export default basicReducer(initialState, reductionLookup);