exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-pages-product-add-styles--wrapper {\n  padding: 2rem 4rem 2rem 4rem; }\n  @media (min-width: 320px) and (max-width: 480px) {\n    .src-pages-product-add-styles--wrapper {\n      padding: .5rem; } }\n\n.src-pages-product-add-styles--subheader {\n  padding: 2rem 4rem 2rem .5rem;\n  width: 100%; }\n\n.src-pages-product-add-styles--horizSpace {\n  width: 5rem; }\n\n.src-pages-product-add-styles--plusCircle {\n  margin-top: .5rem;\n  margin: 0px auto;\n  color: #FFC600;\n  background-color: rgba(255, 255, 255, 0.4);\n  border-radius: 1.5rem; }\n\n.src-pages-product-add-styles--modal {\n  padding: 5rem; }\n", ""]);

// exports
exports.locals = {
	"wrapper": "src-pages-product-add-styles--wrapper",
	"subheader": "src-pages-product-add-styles--subheader",
	"horizSpace": "src-pages-product-add-styles--horizSpace",
	"plusCircle": "src-pages-product-add-styles--plusCircle",
	"modal": "src-pages-product-add-styles--modal"
};