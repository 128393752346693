import React from 'react';
import { Tr, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import PropTypes from 'prop-types';
import { isMobileOnly, isTablet,  isBrowser} from 'react-device-detect';

class OrderRow extends React.Component {

    constructor(){
        super();
        this.onRowClick = this.onRowClick.bind(this);
    }

    onRowClick() {
        this.props.onRowClick(this.props.productId);
    }

    render() {
        const { sku, name, brand, category, type, subType, packageType, price, qty} = this.props;
        return (
            !isMobileOnly ?
                <Tr onClick={() => this.onRowClick()} colSpan={28}>
                    <Td />
                    <Td colSpan={2}>
                        <h1 className='is-size-6 has-text-weight-bold'>{sku}</h1>
                    </Td>
                    <Td colSpan={32}>
                        <figure>
                            <h1 className='is-size-6 has-text-weight-bold'>{name}</h1>
                            <h3 className='is-size-6'>{brand}</h3>
                            <h3 className='is-size-6'>{category} - {type}</h3>
                            <h3 className='is-size-6' style={{paddingTop: '1rem',}}>{subType}</h3>
                        </figure>
                    </Td>
                    <Td /><Td />
                    <Td colSpan={2}>{packageType}</Td>
                    <Td colSpan={2}>{qty}</Td>
                    <Td colSpan={2}>${price.toFixed(2)}</Td>
                    <Td colSpan={2}>${(qty * price).toFixed(2)}</Td>
                </Tr>
                :
                <Tr onClick={() => this.onRowClick()}>
                    <h1 className='is-size-6 has-text-weight-bold'><b>SKU: </b>{sku}</h1>
                    <figure>
                        <h1 className='is-size-4 has-text-weight-bold'>{name}</h1>
                        <h3 className='is-size-6'>{brand}</h3>
                    </figure>
                    <div className='level is-mobile' style={{margin: '1rem'}}>
                        <p className='level-item-left'><b>QTY:</b> {qty}</p>
                        <p className='level-item-right'>{packageType}</p>
                    </div>
                    <div className='level is-mobile'>
                        <p className='level-item-left'><b>Price:</b>${price.toFixed(2)}</p>
                        <p className='level-item-right'><b>Subtotal:</b>${(qty * price).toFixed(2)}</p>
                    </div>
                </Tr>
        );
    }
}
export default OrderRow;

OrderRow.propTypes = {  
    productId: PropTypes.number,
    sku: PropTypes.string,
    name: PropTypes.string,
    brand: PropTypes.string,
    category: PropTypes.string, 
    type: PropTypes.string, 
    subType: PropTypes.string,
    packageType: PropTypes.string, 
    price: PropTypes.number, 
    qty: PropTypes.number,
    onRowClick: PropTypes.func,                   
};

