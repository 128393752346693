import {types as EventTypes} from '../events';
import {types as AuthEventTypes} from '../../authentication/events';
import basicReducer from '../../common/reducers/basic-reducer';
import constant from 'lodash/constant';
import get from 'lodash/get';

const initialState = {
    agreed: false,
    businessType: '',
    companyName: '',
    address: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    counties: '',
    county: '',
    website: '',
    ein: '',
    fullName: '',
    phone: '',
};

const fieldUpdated = (state, {name, value}) => ({
    ...state,
    [name]: value,
});

const getProfile = (state, result) => {
    return {
        ...state,
        agreed: true,
        businessType: get(result, 'companySubType'),
        phone: get(result, 'contactPhone', ''),
        companyName: get(result, 'name', ''),
        address: get(result, 'address1', ''),
        address2: get(result, 'address2', ''),
        city: get(result, 'city', ''),
        state: get(result, 'state', ''),
        zip: get(result, 'postalCode', ''),
        counties: get(result, 'county', ''),
        county: get(result, 'county', ''),
        website: get(result, 'website', ''),
        ein: get(result, 'ein', ''),
        fullName: get(result, 'contactName', ''),

    };
};

const reductionLookup = {
    [AuthEventTypes.LoggedOut]: constant(initialState),
    [EventTypes.FieldUpdated]: fieldUpdated,
    [EventTypes.GetProfileFailed]: constant(initialState),
    [EventTypes.GetProfileSucceeded]: getProfile,
};

export default basicReducer(initialState, reductionLookup);
