import {types as EventTypes} from '../events';
import basicReducer from '../../common/reducers/basic-reducer';
import {types as AuthEventTypes} from '../../authentication/events';
import constant from 'lodash/constant';

const initialState = [];

const reductionLookup = {
    [AuthEventTypes.LoggedOut]: constant(initialState),
    [EventTypes.SubRegionsLoading]: constant(initialState),
    [EventTypes.LoadSubRegionsFailed]: constant(initialState),
    [EventTypes.LoadSubRegionsSucceeded]: (_, result) => result,
};

export default basicReducer(initialState, reductionLookup);