exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-components-header-styles--wrap {\n  padding: 1rem;\n  background-color: #1b1725;\n  position: fixed;\n  width: 100%;\n  z-index: 999;\n  opacity: .97; }\n\n.src-components-header-styles--badge {\n  background-color: red;\n  position: absolute;\n  top: 0;\n  right: 0;\n  height: 22px;\n  width: 22px;\n  border-radius: 11px;\n  color: white;\n  text-align: center; }\n", ""]);

// exports
exports.locals = {
	"wrap": "src-components-header-styles--wrap",
	"badge": "src-components-header-styles--badge"
};