exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-pages-reset-password-styles--wrapper {\n  padding: 2rem 4rem 2rem 4rem; }\n\n.src-pages-reset-password-styles--subtext {\n  margin-top: 2rem !important; }\n\n.src-pages-reset-password-styles--has-error {\n  background-color: #ff0000; }\n", ""]);

// exports
exports.locals = {
	"wrapper": "src-pages-reset-password-styles--wrapper",
	"subtext": "src-pages-reset-password-styles--subtext",
	"has-error": "src-pages-reset-password-styles--has-error"
};