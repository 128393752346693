import {types as EventTypes} from '../events';
import basicReducer from '../../common/reducers/basic-reducer';
import constant from 'lodash/constant';

const initialState = '';

const reductionLookup = {
    [EventTypes.LoggedOut]: constant(initialState),
    
    [EventTypes.AuthenticationRequested]: constant(initialState),
    [EventTypes.AuthenticationSucceeded]: constant(initialState),    
    [EventTypes.AuthenticationFailed]: (_, error) => error,
    
    [EventTypes.SignupRequested]: constant(initialState),
    [EventTypes.SignupSucceeded]: constant(initialState),    
    [EventTypes.SignupFailed]: (_, error) => error,
};

export default basicReducer(initialState, reductionLookup);
