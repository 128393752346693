import isNil from 'lodash/isNil';
import admin from './admin';
import authentication from './authentication';
import cart from './cart';
import catalog from './catalog';
import profile from './profile';

const apis = {
    ...admin,
    ...authentication,
    ...cart,
    ...catalog,
    ...profile,
};

const isPromise = possiblePromise => !isNil(possiblePromise) && !isNil(possiblePromise.then);

const wrapApi = (api) => (...args) => {
    const result = api(...args);
    return isPromise(result) ? result.then(r => r).catch(e => Promise.reject(e)) : result;
};

class API {
    constructor() {
        for (const apiName in apis) {
            this[apiName] = wrapApi(apis[apiName]).bind(this);
        }
    }
}

export default API;
