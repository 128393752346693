exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-components-text-input-styles--error {\n  border-width: 1px;\n  border-color: red; }\n", ""]);

// exports
exports.locals = {
	"error": "src-components-text-input-styles--error"
};