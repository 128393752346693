import React from 'react';
import classNames from 'classnames'; 
import PropTypes from 'prop-types';

export default class SegmentedControl extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            selectedButtonId: 0
        };
    }
 

    setSelectedButton(id){
        this.setState({selectedButtonId: id});
        this.props.onToggle(id);
    }

    render(){
        const classes0 = classNames({
            'button': true,
            'is-xlarge': true, 
            'is-marginless': true,
            'has-text-white' : this.state.selectedButtonId == 0,
            'has-text-black': this.state.selectedButtonId == 1,
            'is-dark' : this.state.selectedButtonId == 0, 
            'is-light': this.state.selectedButtonId == 1,
            'is-active': this.state.selectedButtonId == 0,
        });
        const classes1 = classNames({
            'button': true, 
            'is-xlarge': true,
            'is-marginless': true,
            'has-text-white' : this.state.selectedButtonId == 1,
            'has-text-black': this.state.selectedButtonId == 0,
            'is-dark' : this.state.selectedButtonId == 1,
            'is-light': this.state.selectedButtonId == 0,
            'is-active': this.state.selectedButtonId == 1,
        }); 
        return (
            
            <div className="level is-mobile">
                <div className="level-item">
                    <div className="buttons has-addons">
                        <div className="box is-paddingless">
                            <span className={classes0}  onClick={() => this.setSelectedButton(0)}>
                                <div>
                                    <h1 className="is-size-3 is-size-5-mobile">For Retailers</h1>
                                    <p className="is-size-4 is-hidden-mobile">Restaurants, Bars, and Merchants</p>
                                </div>
                            </span>
                            <span className={classes1} onClick={() => this.setSelectedButton(1)}>
                                <div>
                                    <h1 className="is-size-3 is-size-5-mobile">For Distributors</h1>
                                    <p className="is-size-4 is-hidden-mobile">Importers, Producers, and Suppliers</p>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
                <style dangerouslySetInnerHTML={{__html: `
              .button.is-xlarge { padding: 3rem;}
              @media screen and (max-width: 600px) {
                  .button.is-xlarge {
                    padding: 1rem;
                  }
                }
            `}} />
            </div>
        );
    }
}

SegmentedControl.propTypes = {
    onToggle: PropTypes.func,
};